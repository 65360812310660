<template>
  <div class="overflow-visible px-5">
    <h3 class="py-2">Summary</h3>
    <TSpinner
      v-if="patientLoading"
      name="patient-summary"
      :partial-page="true"
    />

    <div v-else-if="patientDisplay">
      <TMAutosaveInput
        :auto-resize="false"
        :data="patientDisplay.summary"
        :api-call="submit"
        :warn-unsaved-changes="true"
        :save-state="saveState"
        :type="InputType.TEXTAREA"
      />
      <div class="flex items-end">
        <PatientCarePodLabel
          v-if="patientDisplay.carePodId"
          :care-pod-id="patientDisplay.carePodId"
          class="mr-2"
        />
        <TLabel
          v-for="label in additionalLabels"
          :key="label"
          class="bg-opacity-100 mr-2"
          :label="label"
        />
        <PatientLabels :patient-id="patientDisplay.entityId" />
      </div>
    </div>
    <div v-else class="p-5">Error loading patient summary details.</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PatientCarePodLabel from '@/legacy/components/patient/PatientCarePodLabel.vue'
import PatientLabels from '@/legacy/components/patient/PatientLabels.vue'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TLabel from '@/legacy/nashville/TLabel.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TLabel,
    TSpinner,
    PatientCarePodLabel,
    PatientLabels,
    TMAutosaveInput,
  },
  setup,
})
</script>
