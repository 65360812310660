<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Physical Function Assessment"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div class="">
          <TMDropdownField
            name="ECOGStatus"
            label="ECOG Status"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :model-value="undefined"
            :multiple="true"
            :options="[]"
          />
          <TMDropdownField
            name="unableToPerformTheFollowingADLs"
            class="w-full my-2"
            label="Unable to Perform the Following ADLs"
            :model-value="undefined"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="ADLsInabilityOptions"
          />
          <TMDropdownField
            name="DME"
            label="DME"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="DMEOptions"
          />
          <TMDropdownField
            name="walksTwoBlocksOrOneFlightOfStairs"
            label="Walks 2 Blocks or 1 Flight of Stairs"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
        </div>
        <div>
          <TMDropdownField
            name="unableToPerformTheFollowingIADLs"
            label="Unable to Perform the Following IADLs"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="IADLsInabilityOptions"
          />
          <TMDropdownField
            name="homebound"
            label="Homebound"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
          <TMDropdownField
            name="proceduralSupport"
            label="Procedural Support"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="proceduralSupportOptions"
          />
        </div>
      </div>

      <label class="font-bold mb-2">Additional Information</label>
      <TTextarea name="additionalInformation" :model-value="''" />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="fall-risk-cancel"
          @click="close"
        />
        <TMPrimaryButton name="fall-risk-save" label="Save" @click="close" />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editPhysicalFunctionAssessmentProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TMSecondaryButton,
  },
  props: editPhysicalFunctionAssessmentProps,
  emits: ['close'],
  setup,
})
</script>
