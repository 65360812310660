import { LegacyDropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { AllIcons } from '@thyme/nashville/src/types/icons'
import { SelectOptions } from '@thyme/nashville/src/types/select'
import { compareNullableDatetimes, stringToDateTime } from '@/legacy/libs/date'
import { humanizeRole } from '@/legacy/libs/entity'
import {
  formatDateTime,
  formatDateTimeWithTime,
  formatNameFromEntity,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { IdMap } from '@/legacy/types/api/store'
import { Entity, EntityRole } from '@/legacy/types/entities/entities'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import {
  closedSubtaskStatuses,
  openSubtaskStatuses,
  Subtask,
  SubtaskPriority,
  SubtaskStatus,
  SubtaskStatusOption,
} from '@/legacy/types/pathways/subtasks'

export const reattemptReasonPlaceholder = 'Reason for reattempting subtask...'
export const datepickerPlaceholder = 'mm/dd/yyyy'

export const unsetPriority = {
  val: null,
  label: 'Set priority for all',
  icon: getPriorityIcon(null),
}

export const unsetAssignee = {
  val: null,
  label: 'Set assignee for all',
  icon: null,
}

export const unsetStatus = {
  val: null,
  label: 'Set status for all',
  icon: null,
}

export const unsetSubStatus = {
  value: null,
  label: 'Select a reason',
}

export const allTasksBulkEditSubtaskStatusOptions = [
  {
    val: SubtaskStatus.OPEN_UNASSIGNED,
    label: SubtaskStatuses[SubtaskStatus.OPEN_UNASSIGNED].text,
    icon: SubtaskStatuses[SubtaskStatus.OPEN_UNASSIGNED].icon,
    fillType: SubtaskStatuses[SubtaskStatus.OPEN_UNASSIGNED].fillType,
  },
  {
    val: SubtaskStatus.CANCELLED,
    label: SubtaskStatuses[SubtaskStatus.CANCELLED].text,
    icon: SubtaskStatuses[SubtaskStatus.CANCELLED].icon,
    fillType: SubtaskStatuses[SubtaskStatus.CANCELLED].fillType,
  },
  {
    val: SubtaskStatus.REATTEMPT,
    label: SubtaskStatuses[SubtaskStatus.REATTEMPT].text,
    icon: SubtaskStatuses[SubtaskStatus.REATTEMPT].icon,
    fillType: SubtaskStatuses[SubtaskStatus.REATTEMPT].fillType,
  },
]

/**
 *
 * @param priority
 */
export function getPriorityIcon(priority: string | null) {
  if (priority === null) {
    return AllIcons.EmptyPriority
  }

  const priorityValue =
    SubtaskPriority[priority as keyof typeof SubtaskPriority]

  if (priorityValue === SubtaskPriority.URGENT) {
    return AllIcons.UrgentPriority
  }
  if (priorityValue === SubtaskPriority.HIGH) {
    return AllIcons.HighPriority
  }
  if (priorityValue === SubtaskPriority.ROUTINE) {
    return AllIcons.RoutinePriority
  }
  return AllIcons.OptionalPriority
}

export const subtaskPriorityOptions: LegacyDropdownKV[] = Object.entries(
  SubtaskPriority
).map(([enumKey, enumValue]) => [
  enumKey,
  {
    icon: getPriorityIcon(enumKey),
    text: enumValue,
    fillType: null,
  },
])

export const subtaskPriorityOptionsV2 = Object.entries(SubtaskPriority).map(
  ([enumKey, enumValue]) => ({
    val: enumKey,
    label: enumValue,
    icon: getPriorityIcon(enumKey),
  })
)

export const subtaskStatusOptions = (
  statusOptionsEnumsArr: SubtaskStatus[]
): LegacyDropdownKV[] => {
  const subtaskStatusObjArr: SubtaskStatusOption[] =
    Object.values(SubtaskStatuses)
  return subtaskStatusObjArr
    .map(
      ({
        icon,
        text,
        value,
        fillType,
      }: {
        icon: string
        text: string
        value: SubtaskStatus
        fillType: string | null
      }): LegacyDropdownKV => [value as string, { icon, text, fillType }]
    )
    .filter((statusOption: Array<any>) =>
      statusOptionsEnumsArr.includes(statusOption[0] as SubtaskStatus)
    )
}

/**
 *
 * Helper function to render staff name from ID
 * @param staffId
 * @param staff
 */
export function staffName(staffId: string, staff: IdMap<Entity>): string {
  const entity = safeLookup(staffId, staff) as Entity
  return formatNameFromEntity(entity)
    ? formatNameFromEntity(entity)
    : '<Unknown User>'
}

/**
 *
 * Helper function to render role or staff name string
 * @param selectedSubtask
 * @param staff
 */
export function renderRoleOrStaff(
  selectedSubtask: Subtask,
  staff: IdMap<Entity>
) {
  if (selectedSubtask.responsibleStaffId) {
    return staffName(selectedSubtask.responsibleStaffId, staff)
  }
  return selectedSubtask.responsibleRole
    ? humanizeRole(selectedSubtask.responsibleRole as EntityRole)
    : 'N/A'
}

/**
 *
 * @param currentStatus
 * @param hasAdminPrivileges
 */
export function getAvailableStatusOptions(
  currentStatus: string | SubtaskStatus,
  hasAdminPrivileges = false
): SelectOptions {
  let statusOptions =
    SubtaskStatuses[currentStatus as SubtaskStatus].statusOptions

  if (hasAdminPrivileges && currentStatus === SubtaskStatus.BLOCKED) {
    statusOptions = SubtaskStatuses[SubtaskStatus.OPEN_UNASSIGNED].statusOptions
  }
  return subtaskStatusOptions(statusOptions)
}

export const getSubtaskIconName = (subtaskStatus: SubtaskStatus) => {
  if (subtaskStatus === SubtaskStatus.CANCELLED) {
    return SubtaskStatuses.CANCELLED.icon
  }
  return SubtaskStatuses[subtaskStatus as keyof typeof SubtaskStatuses].icon
}

export const getSubtaskIconFill = (subtaskStatus: SubtaskStatus) => {
  if (subtaskStatus === SubtaskStatus.CANCELLED) {
    return SubtaskStatuses.CANCELLED.fillType
  }
  return SubtaskStatuses[subtaskStatus as keyof typeof SubtaskStatuses].fillType
}
/**
 *
 * @param isOverdue
 * @param status
 */
export function renderDatePretext(isOverdue: boolean, status: string) {
  return isOverdue
    ? 'Overdue'
    : status === SubtaskStatuses.COMPLETED.value
    ? 'Completed'
    : status === SubtaskStatuses.CANCELLED.value
    ? 'Canceled'
    : status === SubtaskStatuses.CLOSED_IN_ERROR.value
    ? 'Closed in error'
    : 'Due'
}

/**
 *
 * @param subtask
 */
export function isSubtaskOverdue(subtask: Subtask) {
  const subtaskDueDate = new Date(subtask.dueDatetime)
  const today = new Date()
  return !!subtask.dueDatetime && subtaskDueDate < today
}

/**
 *
 * @param root0
 * @param root0.status
 * @param root0.statusUpdatedAt
 * @param root0.dueDatetime
 */
export function renderDateString({
  status,
  statusUpdatedAt,
  dueDatetime,
}: Subtask) {
  return closedSubtaskStatuses.indexOf(status as SubtaskStatus) >= 0
    ? formatDateTimeWithTime(stringToDateTime(statusUpdatedAt ?? ''))
    : dueDatetime
    ? formatDateTime(stringToDateTime(dueDatetime ?? ''))
    : 'N/A'
}

/**
 *
 * @param s
 */
export function isClosedSubtask(s: Subtask) {
  const status = s.status
  return closedSubtaskStatuses.indexOf(status as SubtaskStatus) >= 0
}

/**
 *
 * @param s
 * @param selfEntity
 */
export function isAssignedToSelf(
  s: Subtask | null,
  selfEntity: Entity | null
): boolean {
  if (s && selfEntity) {
    return s.responsibleStaffId === selfEntity.entityId
  }
  return false
}

/**
 *
 * @param subtasks
 */
export function sortSubtasks(subtasks: Subtask[]) {
  return subtasks.sort((a: Subtask, b: Subtask) => {
    const statuses = Object.values(SubtaskStatus)

    const isConsideredOpenStatus =
      openSubtaskStatuses.includes(a.status as SubtaskStatus) &&
      openSubtaskStatuses.includes(b.status as SubtaskStatus)

    const statusSort = isConsideredOpenStatus
      ? false
      : statuses.findIndex((status) => a.status === status) -
        statuses.findIndex((status) => b.status === status)

    if (statusSort) {
      return statusSort
    }
    if (!isClosedSubtask(a)) {
      const dueDatetimeSort = compareNullableDatetimes(
        stringToDateTime(a.dueDatetime),
        stringToDateTime(b.dueDatetime)
      )
      if (dueDatetimeSort) {
        return dueDatetimeSort
      }
      return 0
    }

    return compareNullableDatetimes(
      stringToDateTime(a.statusUpdatedAt),
      stringToDateTime(b.statusUpdatedAt)
    )
  })
}

/**
 *
 * @param subtasks
 */
export function filterReattemptedSubtasks(subtasks: Subtask[]) {
  const today = new Date()
  return subtasks.filter((subtask: Subtask) => {
    if (
      subtask.hideDatetime &&
      subtask.status === SubtaskStatus.OPEN_UNASSIGNED
    ) {
      return subtask.hideDatetime < today.toISOString()
    } else if (
      subtask.hideDatetime &&
      subtask.status !== SubtaskStatus.OPEN_UNASSIGNED
    ) {
      return true
    }
    return !subtask.hideDatetime
  })
}

/**
 *
 * @param a
 */
export function effectiveSubtaskDate(a: Subtask) {
  let date: string | undefined
  if (a.status === SubtaskStatuses.COMPLETED.value) {
    date = a.statusUpdatedAt
  } else {
    date = a.dueDatetime
  }
  return date
}
