import { computed, Ref } from 'vue'

// Defined only for convenience and use in testing.
export type CancerViewModel = {
  cancerDisplay: Ref<string>
  spreadDisplay: Ref<string>
  diagnosisDateDisplay: Ref<string>
  lastOncoApptDisplay: Ref<string>
  nextOncoApptDisplay: Ref<string>
  oncoNameDisplay: Ref<string>
  surgeonNameDisplay: Ref<string>
  radiationOncoNameDisplay: Ref<string>
  palliativeMedicineNameDisplay: Ref<string>
  primaryCareNameDisplay: Ref<string>
  additionalInformationDisplay: Ref<string>
}

/**
 *
 *
 */
export function setup(): CancerViewModel {
  const cancerDisplay = computed(() => {
    return '-'
  })

  const spreadDisplay = computed(() => {
    return '-'
  })

  const diagnosisDateDisplay = computed(() => {
    return '-'
  })

  const lastOncoApptDisplay = computed(() => {
    return '-'
  })

  const nextOncoApptDisplay = computed(() => {
    return '-'
  })

  const oncoNameDisplay = computed(() => {
    return '-'
  })

  const surgeonNameDisplay = computed(() => {
    return '-'
  })

  const radiationOncoNameDisplay = computed(() => {
    return '-'
  })

  const palliativeMedicineNameDisplay = computed(() => {
    return '-'
  })

  const primaryCareNameDisplay = computed(() => {
    return '-'
  })

  const additionalInformationDisplay = computed(() => {
    return 'N/A'
  })

  return {
    // display values
    cancerDisplay,
    spreadDisplay,
    diagnosisDateDisplay,
    lastOncoApptDisplay,
    nextOncoApptDisplay,
    oncoNameDisplay,
    surgeonNameDisplay,
    radiationOncoNameDisplay,
    palliativeMedicineNameDisplay,
    primaryCareNameDisplay,
    additionalInformationDisplay,
  }
}
