import { jsDateTimeToString } from '@/legacy/libs/date'
import {
  useCommunicationApi,
  refetchCommsThymeline,
  useCommunicationsStore,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  CommunicationSubtype,
  CommunicationDisplayType,
  BaseCommunicationPayload,
  DEFAULT_NOTES,
  ALL_COMMUNICATION_PARTS,
  Communication,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import {
  getCommunicationDisplayType,
  getCurrentPatientId,
  getCurrentStaffId,
} from './sharedCommunicationParts'

/**
 *
 * Function to create communication using apiBuilder().create().
 * Returns created communication.
 *
 * Refetches thymeline to ensure created communication is adeded.
 * @param commType
 * @param payload
 */
export async function createCommunication(
  commType: CommunicationDisplayType,
  payload?: BaseCommunicationPayload
) {
  const patientId = getCurrentPatientId()
  if (!patientId) {
    useNotificationStore().setNotification({
      message:
        'Cannot create communication without patient ID. Please try logging out and back in.',
      type: NotificationType.DANGER,
    })
    return
  }

  let completedDatetime = null
  const responsibleStaffId = payload?.responsibleStaffId ?? getCurrentStaffId()
  if (!responsibleStaffId) {
    useNotificationStore().setNotification({
      message:
        'Cannot create communication without staff ID. Please try logging out and back in.',
      type: NotificationType.DANGER,
    })
    return
  }
  if (commType.subtype !== CommunicationSubtype.Planned) {
    completedDatetime = jsDateTimeToString(new Date())
  }
  try {
    const res = await useCommunicationApi().create({
      body: {
        patientIds: [patientId],
        type: commType.type,
        completedDatetime,
        notes: DEFAULT_NOTES,
        responsibleStaffId,
        ...(payload ?? {}),
      },
      metaOptions: { bubbleErrorThrow: true },
    })
    refetchCommsThymeline()

    useCommunicationsStore().isCreating = false

    return res
  } catch (error) {
    console.error('Error creating communication: ', {
      patientIds: [patientId],
      type: commType.type,
      completedDatetime,
      notes: DEFAULT_NOTES,
      responsibleStaffId,
      ...(payload ?? {}),
    })
    return
  }
}

/**
 *
 * Function to get a singular communication by ID using apiBuilder().list().
 * FYI: The retrieve endpoint does not accept all communication parts
 *      so using list over retrieve.
 *
 * Sets datum on singular returned communication.
 * Returns fetched communication via datum.
 * @param commId
 */
export async function retrieveCommunicationById(commId: string | null) {
  if (!commId) {
    useNotificationStore().setNotification({
      message: 'Cannot retrieve communication without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }
  try {
    const res = await useCommunicationApi().list({
      params: {
        parts: ALL_COMMUNICATION_PARTS,
        filter_communication_ids: [commId],
      },
      metaOptions: { bubbleErrorThrow: true },
    })

    const resCommData: Communication[] = Object.values(res.data ?? {})

    if (resCommData.length) {
      useCommunicationApi().datum = resCommData[0]
      useCommunicationsStore().communicationType =
        getCommunicationDisplayType(resCommData[0]) ?? null

      return resCommData[0]
    }
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Error fetching communication: ${commId}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to update communication using apiBuilder().partialUpdate().
 * Returns updated communication.
 *
 * Fetches updated communication to attach communication parts.
 * Refetches thymeline to ensure created communication is updated.
 * @param commId
 * @param payload
 * @param updatedField
 */
export async function updateCommunication(
  commId: string | null | undefined,
  payload: BaseCommunicationPayload,
  updatedField?: string
) {
  if (!commId) {
    useNotificationStore().setNotification({
      message: 'Cannot update without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    const res = await useCommunicationApi().partialUpdate({
      ids: [commId],
      body: payload,
      metaOptions: { bubbleErrorThrow: true },
    })

    if (res) {
      await retrieveCommunicationById(res.communicationId)
    }

    refetchCommsThymeline()

    const message = updatedField
      ? `Successfully updated ${updatedField} for communication.`
      : 'Successfully updated communication.'

    useNotificationStore().setNotification({
      message,
      type: NotificationType.SUCCESS,
    })
    return res
  } catch (error) {
    console.error('Error updating communication: ', {
      commId,
      payload,
    })
    useNotificationStore().setNotification({
      message: `Error updating communication: ${commId}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to delete communication using apiBuilder().delete().
 * Refetches thymeline to ensure deleted communication is removed.
 * Sets the communicationType to null in communications store.
 * @param commId
 */
export async function deleteCommunication(commId: string | null) {
  console.log('CALLed')
  if (!commId) {
    useNotificationStore().setNotification({
      message: 'Cannot delete without a communication ID.',
      type: NotificationType.DANGER,
    })
    return false
  }

  try {
    await useCommunicationApi().delete({
      ids: [commId],
    })
    refetchCommsThymeline()
    useCommunicationsStore().communicationType = null
    return
  } catch (error) {
    console.error('Error deleting communication: ', {
      commId,
    })
    useNotificationStore().setNotification({
      message: `Error deleting communication: ${commId}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}
