export interface AonPracticesToProviders {
  practice: string
  providers: string[]
}

export const AonPracticesToProviders: AonPracticesToProviders[] = [
  {
    practice: 'AON Cancer & Blood Specialists of Arizona',
    providers: [
      'Amber Flaherty, MD',
      'Carlos Arce-Lara, MD',
      'Charinale Elorta, NP',
      'Harshita Paripati, MD',
      'Jordan Vandenberg',
      'Mazen Khattab, MD',
      'Peter Mathern, MD',
      'Ramon Mourelo, MD',
      'Samrat Sanghvi, MD',
      'Sarah Conlon, MD',
      'Shana Wingo, MD',
      'Snehal Bhoola, MD',
      'Tania Cortas, MD',
    ],
  },
  {
    practice: 'AON Cancer and Blood Specialists of Georgia',
    providers: ['Sreekanth Reddy, MD', 'Meredith Kirk, APRN'],
  },
  {
    practice: 'AON Cancer and Blood Specialists of Nova',
    providers: ['Farn Chan, MD', 'Jeffrey White, MD'],
  },
  {
    practice: 'AON Desert Hematology Oncology',
    providers: ['David Kahn, MD'],
  },
  {
    practice: 'AON Gem State Cancer & Blood Specialists',
    providers: ['Stephanie Hodson, MD'],
  },
  {
    practice: 'AON Genesis Cancer Center',
    providers: [
      'Amy Lynn Cleveland, MD',
      'Kristen Sager, MD',
      'Lingyi Chen, MD',
      'Robert Muldoon, MD',
      'Roy Timothy Webb, MD',
      'Stephen Divers, MD',
      'Sunil Kakadia, MD',
      'Nathan Littlejohn, MD',
      'Seth Hollenbach, MD',
      'Renee Warford, MD',
    ],
  },
  {
    practice: 'AON Georgia Oncology and Hematology Consultants',
    providers: ['Antonio Moran, MD'],
  },
  {
    practice: 'AON Heartland Oncology & Hematology',
    providers: ['Sakeer Hussain, MD'],
  },
  {
    practice: 'AON Hematology Oncology Center',
    providers: [
      'Amy Ruiz, APRN',
      'Belagodu Kantharaj, MD',
      'Jay Sidloski, DO',
      'Patrick Litam, MD',
      'Ruben Escuro, MD',
      'Sienna Collins, APRN',
    ],
  },
  {
    practice: 'AON Hematology Oncology Clinic',
    providers: [
      'Christopher McCanless, MD',
      'Gerald Miletello, MD',
      'Michael J Castine, MD',
      'Pavani Ellipeddi, MD',
    ],
  },
  {
    practice: 'AON Hematology Oncology of Indiana',
    providers: [
      'Ashwin Kolala Vasudevamurthy, MD',
      'Brian Mulherin, MD',
      'Daniel Milton, MD',
      'Deng Zhang, MD, PhD',
      'Jenelle Miller, MD',
      'Michelle Wright-Mast, APRN',
      'Muhammad Sarfraz Nawaz, MD',
      'Ruemu Birhiray, MD',
      'Sandra Garofalo, APRN',
    ],
  },
  {
    practice: 'AON Hope Cancer Center of Nevada',
    providers: ['Bethany Baird, APRN', 'Raja Mehdi, MD'],
  },
  {
    practice: 'AON Low Country Cancer Care',
    providers: [
      'Asit K Jha, MD',
      'Haven Caldwell-Sachar, MD',
      'James Payne, PA',
      'Jennifer Yannucci, MD',
      'O. George Negrea, MD',
      'Rohit Bishnoi, MD',
      'Sreekanth Reddy, MD',
    ],
  },
  {
    practice: 'AON Messino Cancer Centers',
    providers: [
      'Andrew Beardsley, MD',
      'Brent Skiver, DO',
      'Carolyn Teeple-Pauly, RN, NP',
      'Charles Bryan, MD',
      'Christopher Chay, MD',
      'Donna Gibson, MSNED, FNP-C',
      'Emily Miller, MD',
      'Eric Luk MD',
      'Joshua Baru, MD',
      'Judy Phillips, DNP, FNP-BC, AOCN',
      'Katherine Perdoni, NP',
      'Martin Palmeri, MD',
      'Mohan Chand Thakuri, MD',
      'Mridu S Chand, MD',
      'Rachel Raab, MD',
      'Scott M Slusser, APRN',
      'Sean Michael Warsch, MD',
      'Shantae Ladon Lucas, MD',
      'Sherrie Lalande, MSN, AOCNP, AGNP-C',
      'Trevor Clark Austin, MD',
      'Wieslawa Pekal, MD',
    ],
  },
  {
    practice: 'AON Michigan Cancer Specialists',
    providers: [
      'Adli Yakan, MD',
      'Eugene Agnone, MD',
      'Lokesh Nagori, MD',
      'Vera Maranci, MD',
    ],
  },
  {
    practice: 'AON Oncology Hematology Associates',
    providers: [
      'Brooke Gillett, DO',
      'Dushyant Verma, MD',
      'Jiantao Ding, MD',
      'Robert J Ellis MD, FACP',
      'Viran Holden, MD',
      'William F Cunningham, MD',
    ],
  },
  {
    practice: 'AON Oncology Hematology of Loudoun and Reston',
    providers: ['Pranitha Naini MD', 'Rangappa Rajendra, MD'],
  },
  {
    practice: 'AON Panacea Oncology',
    providers: [
      'Loan Retchless, APRN, MSN',
      'Weigang Tong, MD',
      'Xinda David Wang, MD',
    ],
  },
  {
    practice: 'AON Summit Cancer Center',
    providers: [
      'Aaron Saunders, MD',
      'Andrew Kominsky, MD',
      'Annalee Wilson, NP',
      'Arvind Chaudhry, MD PhD.',
      'Jing Li Huang, MD',
      'Mackenzi Heaton, APRN',
      'Makena Turner, PA-C',
      'Max Seaton, MD',
      'Mirela Andrei, MD',
    ],
  },
  {
    practice: 'AON Vista Oncology',
    providers: [
      'Ahmad Fora, MD',
      'Devinderpal Randhawa, MD',
      'Joseph Z Ye, MD',
      'Linli Xuan, MD',
      'Min Kang, MD',
    ],
  },
  {
    practice: 'AON Zangmeister Cancer Center',
    providers: [
      'Anna Billy, MS, APRN-CNP, OCN',
      'Emily Whitman-Purves, MD',
      'Jeanna Knoble, MD',
      'Jorge Rios-Perez, MD',
      'Luis Vaccarello, MD',
      'Mark Knapp, MD',
      'Mark Segal, MD',
      'P. Kothai K Sundaram, MD',
      'Sam Mikhail, MD',
      'Sarah Weekly, ARNP',
      'Taral Patel, MD',
      'Tarek Chidiac, MD',
      'Timothy Moore, MD',
    ],
  },
  {
    practice: 'AON Center for Cancer and Blood Disorders',
    providers: [
      'Bruce Cheson, MD',
      'Mark Goldstein, MD',
      'Ralph Boccia, MD',
      'Victor Priego, MD',
    ],
  },
  {
    practice: 'AON Florida Oncology and Hematology',
    providers: ['Jose Alemar, MD'],
  },
  {
    practice: 'AON Greater Washington Oncology Associates',
    providers: ['Daya Sharma, MD', 'Ram Trehan, MD'],
  },
  {
    practice: 'AON Lone Star Oncology',
    providers: ['Brian Shimkus, MD'],
  },
  {
    practice: 'AON Triple Crown Urology',
    providers: [
      'Nathan Littlejohn, MD',
      'Seth Hollenbach, MD',
      'Renee Warford, MD',
    ],
  },
]
