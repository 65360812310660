import { ref, onMounted } from 'vue'
import { fromISOStringWithoutTimeZone } from '@/legacy/libs/date'
import { useHolidaysApi } from '@/legacy/store/modules/holidays'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const holidays = ref<Date[]>([])

  /**
   *
   */
  async function fetchHolidays() {
    try {
      const response = await useHolidaysApi().list({})
      holidays.value = response.data.map((holiday: { date: string }) => {
        return fromISOStringWithoutTimeZone(holiday.date)
      })
    } catch (error) {
      console.error('Error fetching holidays:', error)
    }
  }

  onMounted(fetchHolidays)

  /**
   *
   * @param value
   */
  function setDateValue(value: string | string[] | Date | Date[] | undefined) {
    if (value instanceof Date) {
      context.emit('update:modelValue', value)
    }
  }

  return {
    holidays,
    setDateValue,
  }
}
