<template>
  <TCalendar
    :model-value="
      dirtyPlannedCall.dueDatetime
        ? new Date(maybeAddTimezone(dirtyPlannedCall.dueDatetime))
        : null
    "
    placeholder="Date"
    class="w-full"
    @update:model-value="setDueDatetime($event, DueDateTimePart.DATE)"
  />
  <TDropdown
    v-if="showTimeBlocks"
    :model-value="dirtyPlannedCall.timeBlock"
    :options="timeBlockDropdownOptions"
    option-label="label"
    option-value="value"
    placeholder="Time (in your timezone)"
    class="w-full"
    @update:model-value="setTimeBlock"
  >
    <template #option="{ option }">
      <div>{{ option.label }}</div>
      <label v-if="option.description" class="text-sm">{{
        option.description
      }}</label>
    </template>
  </TDropdown>
  <TCalendar
    v-if="dirtyPlannedCall.timeBlock === ScheduledTimeBlock.CUSTOM"
    :model-value="
      dirtyPlannedCall.dueDatetime
        ? new Date(maybeAddTimezone(dirtyPlannedCall.dueDatetime))
        : null
    "
    time-only
    inline
    :show-icon="false"
    :step-minute="15"
    hour-format="12"
    class="w-full"
    @update:model-value="setDueDatetime($event, DueDateTimePart.TIME)"
  />
  <label id="timezone-display" class="italic font-normal">
    <div id="member-timezone-display" class="mt-2">
      {{ displayMemberTimezoneString }}
    </div>
    <div id="user-timezone-display">{{ displayUserTimezoneString }}</div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import setup from './controller'

export default defineComponent({
  components: { TCalendar, TDropdown },
  props: {
    dueDatetime: {
      type: String,
      default: null,
    },
  },
  emits: ['update'],
  setup,
})
</script>
