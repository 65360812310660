import { computed, Ref } from 'vue'

// Defined only for convenience and use in testing.
export type PatientClinicalSummaryViewModel = {
  cancerDisplay: Ref<string>
  spreadDisplay: Ref<string>
  treatmentDisplay: Ref<string>
  medicalConditionsDisplay: Ref<string>
  ecogDisplay: Ref<string>
  tocDisplay: Ref<string>
}

/**
 *
 */
export function setup(): PatientClinicalSummaryViewModel {
  const cancerDisplay = computed(() => {
    return '-'
  })

  const spreadDisplay = computed(() => {
    return '-'
  })

  const treatmentDisplay = computed(() => {
    return '-'
  })

  const medicalConditionsDisplay = computed(() => {
    return '-'
  })
  const ecogDisplay = computed(() => {
    return '-'
  })
  const tocDisplay = computed(() => {
    return '-'
  })

  return {
    cancerDisplay,
    spreadDisplay,
    treatmentDisplay,
    medicalConditionsDisplay,
    ecogDisplay,
    tocDisplay,
  }
}
