import { defineStore } from 'pinia'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { LabelReference } from '@/legacy/types/entities/entities'

export const useLabelRefStore = defineStore('labelReferences', {
  state: () => ({}),
  actions: {
    async fetchLabelRefs(filter_label_ref_ids: string[] = []) {
      await useLabelRefApi().listAll({
        params: { filter_label_ref_ids: filter_label_ref_ids },
      })
    },
  },
})

export const useLabelRefApi = apiStore<LabelReference, IdMap<LabelReference>>(
  'labelReferenceApi',
  '/api/patients/labels/reference',
  {
    transformData: (d: { data: LabelReference[] }) =>
      idMapTransform({}, 'data', 'labelRefId', d.data),
  }
)
