<template>
  <FullCalendar :options="calendarOptions">
    <template #eventContent="slotProps">
      <slot name="eventContent" v-bind="slotProps">
        <div v-tooltip="slotProps.event.title">
          <TIcon
            v-if="slotProps.event?.extendedProps?.icon"
            :icon="slotProps.event.extendedProps.icon"
          />
          <span>{{ slotProps.timeText }}</span>
          <span class="pl-1 font-bold"> {{ slotProps.event.title }}</span>
        </div>
      </slot>
    </template>
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
  </FullCalendar>
</template>

<script lang="ts">
import {
  CalendarOptions,
  EventInput,
  EventClickArg,
  EventSourceFuncArg,
} from '@fullcalendar/core'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/vue3'
import TIcon from '@nashville/icon/TIcon.vue'
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import { nashColors } from 'tailwind.config.ts'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  components: { FullCalendar, TIcon },
  props: {
    eventsFn: {
      type: Function as PropType<
        (fetchInfo: EventSourceFuncArg) => Promise<EventInput[]>
      >,
      required: true,
    },
    eventClick: {
      type: Function as PropType<(arg0: EventClickArg) => void>,
      default: null,
    },
  },
  setup(props, context) {
    const calendarOptions: CalendarOptions = {
      plugins: [timeGridPlugin, listPlugin],
      initialView: 'timeGridDay',
      slotDuration: '00:15:00',
      slotLabelInterval: '01:00',
      scrollTime: '09:00:00',
      nowIndicator: true,
      eventColor: nashColors.purple500,
      headerToolbar: {
        start: 'today',
        center: 'prev,next',
        end: 'timeGridDay listDay',
      },
      dayHeaderFormat: {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        omitCommas: true,
      },
      buttonText: {
        today: 'Today',
        day: 'Calendar',
        list: 'Agenda',
      },
      // ensure passed attrs are not kebab case
      ...mapKeys(context.attrs, (_, key: string) => camelCase(key)),
      events: props.eventsFn,
      eventClick: props.eventClick,
    }

    return {
      calendarOptions,
    }
  },
})
</script>
