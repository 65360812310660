import { DateTime } from 'luxon'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientContractsApi } from '@/legacy/store/modules/patientContracts'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { usePersonApi } from '@/legacy/store/modules/person'
import { Person } from '@/legacy/types/entities/people'
import { NotificationType } from '@/legacy/types/notifications'
import { PatientContractStatus } from '@/legacy/types/patients/contracts'
import { EditPatientDetailsForm } from './types'

/**
 * Update the Patient and any related models (Person, PatientContract)
 * with details changed in the modal. Returns whether the operation
 * was successful. Catches errors from the API operations and shows
 * toasts if needed.
 *
 * IMPROVEME MT-3185: parallelize multiple awaits using Promise.all
 * @param patientId
 * @param patientContractId
 * @param enablePatientContracts
 * @param initialData
 * @param dirtyData
 */
export async function updatePatientDetails(
  patientId: string,
  patientContractId: string | undefined,
  enablePatientContracts: boolean,
  initialData: Partial<EditPatientDetailsForm>,
  dirtyData: EditPatientDetailsForm
): Promise<void> {
  // Helper fns

  /**
   * Update patient (dod and carePodId only)
   */
  async function updatePatientDetails() {
    try {
      const body: any = {
        deceasedDate: dirtyData.dod
          ? DateTime.fromJSDate(dirtyData.dod).toISODate()
          : null,
        carePodId: dirtyData.carePodId ?? null,
      }

      if (dirtyData.dod) {
        body.programStatus = 'disenrolled'
        body.programSubstatus = 'deceased'
      }

      await usePatientsApi().partialUpdate({
        ids: [patientId],
        body,
        metaOptions: {
          bubbleErrorThrow: true,
        },
      })
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update date of deceased.',
        type: NotificationType.DANGER,
      })
      return
    }
    void usePatientStore().patientApiCall(patientId)
  }

  /**
   * Helper to get request body fields common to both create and update patient contract
   *
   * Response body fields:
   * contractingEntityId: included if the contracting entity has been changed in the modal
   * contractExternalId: included if the contract external id has been changed in the modal
   */
  const getContractingBodyParams = () => {
    const shouldIncludeContractingEntityParam =
      dirtyData.contractingEntityId &&
      dirtyData.contractingEntityId !== initialData.contractingEntityId

    const shouldIncludeContractExternalIdParam =
      dirtyData.contractExternalId &&
      dirtyData.contractExternalId !== initialData.contractExternalId

    return {
      ...(shouldIncludeContractingEntityParam
        ? {
            contractingEntityId: dirtyData.contractingEntityId,
          }
        : {}),
      ...(shouldIncludeContractExternalIdParam
        ? { contractExternalId: dirtyData.contractExternalId }
        : {}),
    }
  }

  /**
   * Creates a new contract for the current patient
   * with the specified contracting entity and contract external id
   */
  async function createPatientContract() {
    const params = getContractingBodyParams()

    if (!Object.keys(params).length) {
      return
    }
    const body = {
      ...params,
      patientId: patientId,
      status: PatientContractStatus.Active,
    }
    try {
      await usePatientContractsApi().create({
        body: body,
      })
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to create patient contract.',
        type: NotificationType.DANGER,
      })
      return
    }
  }

  /**
   * Updates the current patient's contract
   * with the contracting entity and/or contract external id
   * specified in the modal
   */
  async function updatePatientContract() {
    const body = getContractingBodyParams()
    if (!patientContractId || !Object.keys(body).length) {
      return
    }
    try {
      await usePatientContractsApi().partialUpdate({
        ids: [patientContractId],
        body: body,
      })
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update patient contract.',
        type: NotificationType.DANGER,
      })
      return
    }
  }

  /**
   * Create or update the patient's contract
   * with the contracting entity and contract id specified in the modal
   */
  async function upsertPatientContract() {
    if (!patientContractId) {
      await createPatientContract()
    } else {
      await updatePatientContract()
    }
  }

  // IMPLEMENTATION

  try {
    const personBody = {
      preferredName: dirtyData.preferredName,
      preferredLanguage: dirtyData.language,
      gender: dirtyData.gender,
      carePodId: dirtyData.carePodId,
      ...(dirtyData.dob
        ? {
            dateOfBirth: DateTime.fromJSDate(dirtyData.dob).toISODate(),
          }
        : {}),
    }
    const person = (await usePersonApi().partialUpdate({
      ids: [patientId],
      body: personBody,
      metaOptions: {
        bubbleErrorThrow: true,
      },
    })) as Person

    usePatientStore().updatePerson(person)

    if (
      (dirtyData.dod &&
        dirtyData.dod.toISOString() !== initialData.dod?.toISOString()) ||
      (dirtyData.carePodId && dirtyData.carePodId !== initialData.carePodId)
    ) {
      await updatePatientDetails()
    }

    // Only update patient's contract if the feature flag is on
    // and the contracting entity has been changed in the modal

    if (enablePatientContracts) {
      await upsertPatientContract()
    }
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update member details.',
      type: NotificationType.DANGER,
      error: err as string,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated member details.',
    type: NotificationType.SUCCESS,
  })
}
