import { defineStore, storeToRefs } from 'pinia'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { CarePlanUpdate } from '@/legacy/types/patients/carePlanUpdates'
import { usePatientStore } from './patient'

export const useCarePlanUpdateStore = defineStore('carePlanUpdate', {
  state: () => ({}),
  getters: {},
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} care plan update(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} care plan update: ${error}`,
      })
    },

    async setSuccess(action: string) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        await this.getCarePlanUpdate({ filter_patient_ids: [patientId.value] })
      }
      useNotificationStore().setNotification({
        message: `Success ${action} care plan update(s)`,
        type: NotificationType.SUCCESS,
      })
    },

    async getCarePlanUpdate({
      filter_patient_ids = null,
    }: {
      filter_patient_ids?: string[] | null
    }) {
      const carePlanUpdate = useCarePlanUpdateApi()
      const data = await carePlanUpdate.list({
        params: {
          ...(filter_patient_ids ? { filter_patient_ids } : {}),
        },
      })

      if (carePlanUpdate.error) {
        this.setError(carePlanUpdate.error, 'fetching')
      }
      return data
    },
  },
})

// endpoint is a RETRIEVE not a LIST
export const useCarePlanUpdateApi = apiStore<CarePlanUpdate>(
  'carePlanUpdateApi',
  '/api/care_plan/last_update',
  { transformData: (d: CarePlanUpdate) => ({ datum: d }) }
)
