import { ModalSize } from '@thyme/nashville/src/types/modals'
import { capitalize } from 'lodash'
import { ExtractPropTypes } from 'vue'
import { booleanOptions } from '../../shared/types'
import { Anticoagulation, SedatingMedications } from '../shared/types'
import { editFallRiskProps } from './types'

type PropsType = ExtractPropTypes<typeof editFallRiskProps>

/**
 * Set up the EditPatientDetails component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const anticoagulationOptions = Object.values(Anticoagulation)
    .map((val) => {
      return { value: val, label: capitalize(val) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  const sedatingMedicationsOptions = Object.values(SedatingMedications)
    .map((val) => {
      return { value: val, label: capitalize(val) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  return {
    close,
    ModalSize,
    // dropdown options
    anticoagulationOptions,
    sedatingMedicationsOptions,
    booleanOptions,
  }
}
