<template>
  <TModal
    :is-visible="isOpen"
    :title="`${!!editingRow ? 'Edit' : ''} ${name}`"
    :size="ModalSize.MD"
    @close="closeModal"
  >
    <div v-if="isOpen">
      <div v-for="column in columns" :key="column.field">
        <div v-if="column.type === 'nested'">
          <TAdminAddField
            v-for="nested in column.nestedFields"
            :key="nested.field"
            :parent-name="`${name}-${column.field}`"
            :field="nested.field"
            :label="nested.label"
            :data="editingRow ? editingRow[column.field][nested.field] : []"
            :type="nested.type"
            :options="nested.options"
            :options-fn="nested.optionsFn"
            :model-link="nested.modelLink"
            :input-attrs="column.inputAttrs"
            @update-dirty="
              updateNestedDirty(column.field, nested.field, $event)
            "
          />
        </div>
        <TAdminAddField
          v-else
          :parent-name="name"
          :field="column.field"
          :label="column.label"
          :data="editingRow ? editingRow[column.field] : column.data ?? []"
          :type="column.type"
          :options="column.options"
          :options-fn="column.optionsFn"
          :model-link="column.modelLink"
          :input-attrs="column.inputAttrs"
          @update-dirty="updateDirty(column.field, $event, column)"
        />
      </div>
    </div>
    <template #actions>
      <LegacyTButton value="Save" :name="`${name}-save`" @click="onSave" />
    </template>
  </TModal>
</template>

<script lang="ts">
import { ModalSize } from '@thyme/nashville/src/types/modals'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import { defineComponent, PropType } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TAdminAddField from '@/legacy/nashville/TAdminAddField.vue'
import TModal from '@/legacy/nashville/TModal.vue'

export default defineComponent({
  components: {
    TAdminAddField,
    LegacyTButton,
    TModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    editingRow: {
      type: Object as PropType<any>,
      default: null,
    },
    columns: {
      type: Array as PropType<{ [key: string]: any }[]>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup(props, context) {
    let dirtyModel: { [key: string]: any } = {}
    const updateDirty = (field: string, data: any, column: any) => {
      if (column.type === 'multiselect' && column.dataLocations) {
        column.dataLocations.forEach((loc: string) => (dirtyModel[loc] = []))
        data.forEach((v: any[]) =>
          column.dataLocations.forEach((loc: string, i: number) =>
            v[i] ? dirtyModel[loc].push(v[i]) : null
          )
        )
      } else if (column.dataLocations) {
        const formattedData = isArray(data)
          ? data
          : typeof data === 'string'
          ? data.split(',')
          : []
        formattedData.forEach(
          (d, i) => (dirtyModel[column.dataLocations[i]] = d)
        )
      }
      dirtyModel[field] = data
    }
    const updateNestedDirty = (
      parentField: string,
      field: string,
      data: any
    ) => {
      if (!dirtyModel[parentField]) {
        dirtyModel[parentField] = {}
      }
      dirtyModel[parentField][field] = data
    }

    const closeModal = () => {
      context.emit('close')
      dirtyModel = {}
    }
    const onSave = () => {
      context.emit('save', cloneDeep(dirtyModel))
      closeModal()
    }

    return {
      dirtyModel,
      closeModal,
      onSave,
      updateDirty,
      updateNestedDirty,
      ModalSize,
    }
  },
})
</script>
