import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'

import { useConfigStore } from '@/legacy/store/modules/config'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useWhoAmIApi } from '@/legacy/store/modules/whoAmI'
import { apiMutations, initialApiState } from '@/legacy/types/api/apiBuilder'
import { NotificationType } from '@/legacy/types/notifications'
import {
  WhoAmIInfo,
  ProfileInitOptions,
  ProfileState,
} from '@/legacy/types/profile'
import { usePayerStore } from './payers'
import { useStaffStore } from './staff'

const initialState = (): ProfileState => ({
  ...initialApiState(),
  auth0Profile: null,
  selfEntity: null,
  roles: [],
  permissions: [],
  isInitialized: false,
})

/**
 *
 * @param state
 * @param permission
 */
export function playbookAdminEditingPermission(
  state: ProfileState,
  permission: string
): boolean {
  const { envAllowsDirectPlaybookAdminEditing } = useFlagStore()
  return (
    envAllowsDirectPlaybookAdminEditing &&
    state.permissions.includes(permission)
  )
}

// IMPROVEME(MT-3468): Refactor this store by decomposing it into smaller
// pieces. See the ticket & subtickets for a detailed breakdown.
export const useProfileStore = defineStore('profile', {
  state: () => cloneDeep(initialState()),
  getters: {
    isEnrollmentSpecialist: (state): boolean =>
      state.permissions.includes('legacy-is-enrollment-specialist'),

    hasAdminPrivileges: (state): boolean =>
      state.permissions.includes('legacy-has-admin-privileges'),

    canViewPageUnknownTexts: (state): boolean =>
      state.permissions.includes('ui-view:page-unknown-texts'),

    canViewPageAllTasks: (state): boolean =>
      state.permissions.includes('ui-view:page-all-tasks'),

    canViewPagePodLeadQueue: (state): boolean =>
      state.permissions.includes('ui-view:page-pod-lead-queue'),

    canViewEnrollmentQueue: (state): boolean =>
      state.permissions.includes('ui-view:page-enrollment-queue'),

    canViewPageEnrollmentLeadQueue: (state): boolean =>
      state.permissions.includes(
        'ui-view:page-enrollment-specialist-lead-queue'
      ),

    canViewPageAdminCarePods: (state): boolean =>
      state.permissions.includes('ui-view:page-admin-care-pods'),

    canEditBulkSubtasks: (state): boolean =>
      state.permissions.includes('power:pathways:bulk-edit'),

    canEditPatientDateOfDeath: (state): boolean =>
      state.permissions.includes('edit:patients:date-of-death'),

    canEditPatientTreatmentStatus: (state): boolean =>
      state.permissions.includes('edit:patients:treatment-status'),

    canExtendReattempt: (state): boolean =>
      state.permissions.includes('power:pathways:extended-reattempt'),

    canEditPatientAcuityScore: (state): boolean =>
      state.permissions.includes('edit:patients:acuity-score'),

    canEditPatientDiagnoses: (state): boolean =>
      state.permissions.includes('edit:patients:diagnoses'),

    canEditPatientComorbidities: (state): boolean =>
      state.permissions.includes('edit:patients:comorbidities'),

    canEditPatientContract: (state): boolean =>
      state.permissions.includes('edit:patients:contract'),

    canOverridePathwaysBlockedStatus: (state): boolean =>
      state.permissions.includes('power:pathways:override-blocked-status'),

    canEditPathwaysPriority: (state): boolean =>
      state.permissions.includes('edit:pathways:priority'),

    // pathways admin permissions
    canEditPathwaysAdminSubtaskDetails: (state): boolean =>
      playbookAdminEditingPermission(
        state,
        'edit:pathways:admin-subtask-details'
      ),

    canEditPathwaysAdminSubtaskComposition: (state): boolean =>
      playbookAdminEditingPermission(
        state,
        'edit:pathways:admin-subtask-composition'
      ),

    canEditPathwaysAdminTaskDetails: (state): boolean =>
      playbookAdminEditingPermission(state, 'edit:pathways:admin-task-details'),

    canEditPathwaysAdminTaskComposition: (state): boolean =>
      playbookAdminEditingPermission(
        state,
        'edit:pathways:admin-task-composition'
      ),

    canEditPathwaysAdminPathwayDetails: (state): boolean =>
      playbookAdminEditingPermission(
        state,
        'edit:pathways:admin-pathway-details'
      ),
    canEditPathwaysTemplateStatus: (state): boolean =>
      // NOTE: This does not use playbookAdminEditingPermission wrapper here since pathway template
      // status should be editable in all envs, even if other things aren't
      state.permissions.includes('edit:pathways:admin-pathway-composition'),

    canEditPathwaysAdminPathwayComposition: (state): boolean =>
      playbookAdminEditingPermission(
        state,
        'edit:pathways:admin-pathway-composition'
      ),

    canViewPathwaysAdmin: (state): boolean =>
      state.permissions.includes('ui-view:page-admin-pathways'),

    canImportPathways: (state): boolean =>
      state.permissions.includes('power:pathways:import-pathways'),

    canExportPathways: (state): boolean =>
      state.permissions.includes('power:pathways:export-pathways'),

    canImportAutotriggers: (state): boolean =>
      state.permissions.includes('power:pathways:import-autotriggers'),

    canExportAutotriggers: (state): boolean =>
      state.permissions.includes('power:pathways:export-autotriggers'),

    currentUserEmail: (state): string | null =>
      state.auth0Profile?.email ?? state.selfEntity?.email ?? null,

    canEditDomains: (state): boolean =>
      state.permissions.includes('edit:patients:domains'),

    entryPoint: (): string => '/my-queue',
  },
  actions: {
    ...apiMutations<ProfileState, ProfileState>((data) => data),

    setSelfInfo(selfInfo: WhoAmIInfo) {
      this.selfEntity = selfInfo.entity
      this.roles = selfInfo.roles
      this.permissions = selfInfo.permissions
    },

    /**
     * Handles the set of operations we need to do on app startup, auth
     * state change:
     *
     * Order is important; later steps depend on earlier steps.
     *
     * 1. Blow away and (re)initialize config
     * 2. Update this store with the new profile information & fetch self info
     * 3. Update the user in Datadog, if changed
     * 4. Maybe (re)initialize feature flags
     * 5. Maybe fetch global data
     * @param options
     */
    async updateProfile(options: ProfileInitOptions) {
      // Step 1
      const configStore = useConfigStore()

      if (!configStore.configVals) {
        await configStore.get()
      }

      // Step 2
      const update = options.update
      // User is not necessarily authenticated. This could be a logout or initial
      // state.
      const isChange =
        update?.email !== this.auth0Profile?.email || !this.isInitialized
      const isAuthenticated = update?.email != null
      if (isAuthenticated) {
        this.auth0Profile = update
      } else {
        this.auth0Profile = null
      }

      if (isAuthenticated) {
        await this.fetchSelfInfo()
      } else {
        const { selfEntity, roles, permissions } = initialState()
        this.setSelfInfo({
          entity: selfEntity,
          roles,
          auth0Id: null,
          permissions,
        })
      }
      console.debug(`Current user changed to ${this.selfEntity?.email}`)

      // Step 3 - update Datadog user data
      this.updateDatadogUser({
        isChange,
      })

      // Step 4
      if (isChange) {
        // Avoid re-initializing flags if we do not need to.
        await this.initFeatureFlags()

        // Step 5
        if (isAuthenticated) {
          await useStaffStore().fetchStaff()
          await usePayerStore().fetchPayers()
          this.isInitialized = true
        }
      }
    },

    /**
     * Updates the user metadata sent to Datadog.
     *
     * Does not update the data if it has not changed.
     * @param root0
     * @param root0.isChange
     */
    updateDatadogUser({ isChange }: { isChange: boolean }) {
      if (isChange) {
        const configStore = useConfigStore()

        if (!this.selfEntity) {
          console.debug(`Clearing Datadog user`)
          configStore.rumClient?.clearUser()
          configStore.logsClient?.clearUser()
        } else {
          console.debug(`Set Datadog user to ${this.selfEntity?.email}`)
          const datadogUser = {
            id: this.selfEntity.entityId,
            name: this.auth0Profile?.name,
            email: this.auth0Profile?.email,
          }
          configStore.rumClient?.setUser(datadogUser)
          configStore.logsClient?.setUser(datadogUser)
        }
      }
    },

    async fetchSelfInfo() {
      try {
        let data: WhoAmIInfo = await useWhoAmIApi().list({})
        const { error } = useWhoAmIApi()
        if (
          error instanceof ApiError &&
          error.status === HTTPStatusCode.ERROR_404_NOT_FOUND
        ) {
          data = await useWhoAmIApi().create({})
        }

        this.setSelfInfo(data)
      } catch (err) {
        // reset to initial state on failure
        const { selfEntity, roles, permissions } = initialState()
        this.setSelfInfo({
          entity: selfEntity,
          roles,
          auth0Id: null,
          permissions,
        })
        console.error(err)
        if (err instanceof Error) {
          this.setError(err)
        }
        useNotificationStore().setNotification({
          message: 'Failed to fetch user profile.',
          type: NotificationType.DANGER,
        })
        return
      }
    },

    async initFeatureFlags() {
      const configStore = useConfigStore()
      const flagStore = useFlagStore()
      console.warn(`Fetching feature flags for ${this.currentUserEmail}`)
      if (!this.currentUserEmail) {
        console.error('No user email found when initializing flags')
        return
      }

      if (!configStore.configVals?.splitApiKey) {
        console.error('No Split API key found when initializing flags')
        return
      }

      await flagStore.init(
        this.currentUserEmail,
        configStore.configVals.splitApiKey
      )
    },
  },
})
