<template>
  <div class="mb-10 relative">
    <TSpinner
      v-if="isLoadingComm || isLoadingPlannedCall || isLoadingCallDisposition"
      name="disposition-call-sidebar"
      :partial-page="true"
    />
    <div class="inline-flex space-x-2 items-center mb-5 mt-5 w-full">
      <div
        :class="`inline-flex justify-between content-center items-center ${
          dirtyCallDisposition.direction === CallDirection.inbound
            ? 'w-72'
            : 'w-96'
        }`"
      >
        <h2
          data-cy="disposition-details-header"
          class="border-nash-neutral300 flex items-center content-center"
        >
          {{ `${capitalize(dirtyCallDisposition.direction)} call with` }}
        </h2>
      </div>
      <div
        class="flex justify-between content-center items-center w-full space-x-2"
      >
        <!--
          We intentionally have a different UI for call dispositions vs. planned calls due to the callee type being an important indicator for calls that have a `null` calleeId.
          A planned call can have a null "speakingWith/calleeId"; however, when a call disposition is made, we want users to set or identify at least what type of callee they spoke with, especially if it is unlisted.
        -->
        <div class="w-64 float-left">
          <TDropdown
            :model-value="dirtyCallDisposition.speakingWithType"
            class="w-full"
            input-id="comm-speaking-with-type"
            option-label="label"
            option-value="value"
            :options="speakingWithTypeOptions"
            :show-clear="false"
            @update:model-value="
              (v) => {
                dirtyCallDisposition.speakingWithType = v
                updateCallAndComm(CommSidebarActionEnums.callee)
              }
            "
          />
        </div>
        <div
          v-if="
            dirtyCallDisposition.communicationId &&
            dirtyCallDisposition.direction === CallDirection.inbound
          "
          class="float-right"
        >
          <TMSecondaryButton
            name="delete-inbound-call"
            icon="trash"
            @click="deleteInboundCall"
          />
        </div>
      </div>
    </div>
    <div>
      <table class="is-fullwidth table mb-10">
        <tr
          v-if="
            !isCreating &&
            plannedCalleeId &&
            plannedCalleeId !== dirtyCallDisposition.speakingWithPersonId
          "
        >
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Scheduled call with</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="plannedCalleeId"
              class="w-full"
              input-id="comm-planned-call-with"
              option-label="label"
              option-value="value"
              :options="calleeOptions"
              :show-clear="false"
              @update:model-value="
                () => {
                  updateCallAndComm(CommSidebarActionEnums.scheduledCallee)
                }
              "
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Speaking with</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="dirtyCallDisposition.speakingWithPersonId"
              class="w-full"
              input-id="comm-completed-call-with"
              option-label="label"
              option-value="value"
              :options="calleeOptions"
              :show-clear="false"
              @update:model-value="setSpeakingWithIdAndType"
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Call Direction</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="dirtyCallDisposition.direction"
              class="w-full"
              input-id="comm-direction"
              option-label="label"
              option-value="value"
              :options="callDirectionChoices"
              :show-clear="false"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Call Result</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="dirtyCallDisposition.disposition"
              class="w-full"
              input-id="comm-disposition"
              option-label="label"
              option-value="value"
              :options="dispositionChoices"
              :show-clear="false"
              @update:model-value="
                (v) => {
                  dirtyCallDisposition.disposition = v
                  updateCallAndComm(CommSidebarActionEnums.callResult)
                }
              "
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Verified Identity</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="
                isIdentityVerifiedToString(
                  dirtyCallDisposition.isIdentityVerified ?? false
                )
              "
              class="w-full"
              input-id="comm-is-identity-verified"
              :options="['Yes', 'No']"
              :show-clear="false"
              @update:model-value="
                (v) => {
                  dirtyCallDisposition.isIdentityVerified = v === 'Yes'
                  updateCallAndComm(SidebarDropdownTypeActions.identity)
                }
              "
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Completed By</label
            >
          </td>
          <td>
            <TDropdown
              :model-value="dirtyCallDisposition.completedByStaffId"
              class="w-full"
              input-id="comm-staff"
              option-label="label"
              option-value="value"
              :options="staffIdOptions"
              :show-clear="false"
              @update:model-value="
                (v) => {
                  setStaff(v)
                  updateCallAndComm(SidebarDropdownTypeActions.staff)
                }
              "
            />
          </td>
        </tr>
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Completed On</label
            >
          </td>
          <td>
            <TDatePicker
              name="comm-completed-date"
              is-datetime
              :clearable="false"
              :model-value="
                completedDatetime
                  ? stringTojsDateTime(completedDatetime)
                  : undefined
              "
              show-now-button
              @update:model-value="
                (v) => {
                  dirtyCommunication.completedDatetime = jsDateTimeToString(v)
                  updateCallAndComm(CommSidebarActionEnums.completedDate)
                }
              "
            />
          </td>
        </tr>
      </table>
      <NotesSection
        :dirty-communication="dirtyCommunication"
        :dirty-call-disposition="dirtyCallDisposition"
        :save-state="commSaveState"
        @create-or-update="updateCallAndComm"
        @update-notes-for-dirty-comm="updateNotesForDirtyComm"
      />
      <ZoomCallInfo />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { SidebarDropdownTypeActions } from '@thyme/nashville/src/types/dropdowns'
import { LabelSize } from '@thyme/nashville/src/types/labels'
import capitalize from 'lodash/capitalize'
import { DateTime } from 'luxon'
import { defineComponent, PropType } from 'vue'
import NotesSection from '@/legacy/components/NotesSection.vue'
import ZoomCallInfo from '@/legacy/components/patient/communicationV2/sidebar/calls/ZoomCallInfo/ZoomCallInfo.vue'
import { jsDateTimeToString, stringTojsDateTime } from '@/legacy/libs/date'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

import { SaveState } from '@/legacy/types/api/api'
import { CallDirection } from '@/legacy/types/communications/callDispositions'
import { CommSidebarActionEnums } from '@/legacy/types/communications/communications'
import {
  getCommunicationVars,
  getUtilFuncs,
  getEnumOptions,
} from './controller'

export default defineComponent({
  components: {
    TSpinner,
    NotesSection,
    TDatePicker,
    TMSecondaryButton,
    ZoomCallInfo,
    TDropdown,
  },
  props: {
    commSaveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  setup() {
    const {
      isLoadingComm,
      isLoadingPlannedCall,
      isLoadingCallDisposition,
      plannedCalleeId,
      isCreating,
      completedByStaffId,
      callDisposition,
      isIdentityVerified,
      speakingWithPersonId,
      completedDatetime,
      dirtyCallDisposition,
      dirtyCommunication,
      calleeOptions,
      setStaff,
      setSpeakingWithIdAndType,
      updateCallAndComm,
      updateNotesForDirtyComm,
    } = getCommunicationVars()

    const { isIdentityVerifiedToString, deleteInboundCall } = getUtilFuncs()

    const {
      speakingWithTypeOptions,
      callDirectionChoices,
      dispositionChoices,
      staffIdOptions,
    } = getEnumOptions()

    return {
      isLoadingComm,
      isLoadingPlannedCall,
      isLoadingCallDisposition,
      plannedCalleeId,
      staffIdOptions,
      CallDirection,
      callDirectionChoices,
      calleeOptions,
      capitalize,
      completedByStaffId,
      completedDatetime,
      CommSidebarActionEnums,
      updateCallAndComm,
      deleteInboundCall,
      dirtyCallDisposition,
      dirtyCommunication,
      callDisposition,
      dispositionChoices,
      isCreating,
      isIdentityVerified,
      isIdentityVerifiedToString,
      jsDateTimeToString,
      SidebarDropdownTypeActions,
      setStaff,
      setSpeakingWithIdAndType,
      speakingWithPersonId,
      speakingWithTypeOptions,
      stringTojsDateTime,
      updateNotesForDirtyComm,
      LabelSize,
      DateTime,
    }
  },
})
</script>
<style lang="scss" scoped>
.call-duration-chip {
  line-height: 0.25;
}
</style>
