import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { IdMap } from '@/legacy/types/api/store'
import { EntityRole } from '@/legacy/types/entities/entities'
import { Form } from '@/legacy/types/forms/forms'
import { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { StatusReason, SubStatusReason } from '@/legacy/types/pathways/pathways'
import { Patient } from '@/legacy/types/patients/patients'
import { MemberFacingTheme } from '../admin/pathwaysAdmin'
import { JobStatus } from '../jobs/jobs'
import { CommonTemplateBase, StatusReasonMetadata } from './common'

// TYPES --------------------
export type SubtaskSuggestionParams = {
  memberId: UUID
  communicationId: UUID
  staffId: UUID
  pageLength: number
}

export type SubtaskOutreachParams = {
  subtaskId: UUID
}

export type GetSubtasksParams = {
  filter_patient_ids?: UUID[] | null
  filter_subtask_ids?: UUID[] | null
  page_length?: number | null
  page_number?: number | null
  filter_free_text?: string | null
  filter_subtask_status?: SubtaskStatus[] | null
  filter_subtask_keys?: string[] | null
}

export interface SubtaskThymelineFilters {
  freeTextSearch: string
}

export type SubtaskStatusOption = {
  icon: string
  text: string
  value: SubtaskStatus
  fillType: string | null
  statusOptions: SubtaskStatus[]
}

export type AutogeneratedNote = {
  description: string
  noteId: string
}

export type Subtask = {
  channel: string | null
  communicationMaps?: [{ communicationId: string }]
  description: string | null
  dueDatetime: string
  hideDatetime: string | null
  estimatedMinutesToComplete: number | null
  hyperlinks: string[] | null
  legacyFormTemplateId: string | null
  minStartDatetime: string
  memberId: string | null
  notes: string | null
  pathwayIds: string[]
  patient?: Patient
  priority: SubtaskPriority
  responsibleRole: null | EntityRole | string
  responsibleStaffId: string | null
  showTime: boolean
  status: SubtaskStatus | string
  statusReason: StatusReason | null
  statusReasonJson: StatusReasonMetadata | null
  statusUpdatedAt: string
  customSubject: string | null
  subtaskId: string
  subtaskVariantId: string
  taskIds: string[]
  title: string
  autogeneratedNotes: AutogeneratedNote[]
  generatingTriggerId: UUID | null
  queueStyle: QueueStyle

  existingResponseAnswers?: any
  formResponseId?: string
  formTemplateVersion?: any
  pastResponses?: any
  variantDisplay?: string
  disableDueDate: boolean
  disablePriority: boolean
  pointerToCommId?: string | null
} & Timestamps

export type SubtaskPayload = {
  dueDatetime: string
  notes: string | null
  responsibleRole: null | EntityRole | string
  responsibleStaffId: string | null
  showTime: boolean
  status: SubtaskStatus | string
  subStatusReason?: SubStatusReason | null
  priority: SubtaskPriority
  customSubject?: string | null
  pointerToCommId?: string | null
}

export type BulkEditSubtaskData = {
  subtaskId: string
  priority?: SubtaskPriority | undefined
  status?: SubtaskStatus | string | undefined
  responsibleStaffId?: string | undefined
  hideDatetime?: Date | undefined
  reattemptReason?: string | undefined
  subStatusReason?: SubStatusReason | null
  isBulkEdited?: boolean | null
}

export type BulkEditSubtaskPayload = {
  data: BulkEditSubtaskData[]
  bulkEditNote?: string
}

export type BulkEditSubtaskResponse = {
  runId: string
  status: string | JobStatus
}

export type SubtaskTemplate = {
  channel: null | string
  baseTemplateId: string
  description: string
  estimatedMinutesToComplete: number | null
  staffCompletableForms?: Form[]
  memberCompletableForms?: Form[]
  hyperlinks: null | string[]
  legacyFormTemplateId: string
  isDeleted?: boolean
  memberFacingTheme: MemberFacingTheme
  responsibleRole: null | EntityRole | string
  priority: SubtaskPriority | string
  subtaskKey: string
  subtaskTemplateId: string
  templateStatus: string
  title: string
  variantId: string
  variantKey: string
  variantDisplay: string
  queueStyle: QueueStyle
  outreachMessage?: string
  total?: number
  isMemberFacing?: boolean
  isManuallyAddable: boolean
} & Timestamps

export type SubtaskTemplateBase = {
  subtaskKey: string
  variants?: SubtaskTemplate[]
} & CommonTemplateBase

export type SubtaskOutreach = {
  memberFormLink?: string
  outreachText?: string
}

// INTERFACES --------------------
export interface SubtaskState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Subtask> | null
  isLoaded: boolean
}

export interface SubtaskTemplateDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<SubtaskTemplate> | any
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

export interface SubtaskTemplateBaseDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<SubtaskTemplateBase> | any
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

// ENUMS --------------------
export enum SubtaskOutreachType {
  EMAIL = 'email',
  TEXT = 'text',
}

export enum SubtaskPriority {
  URGENT = 'Urgent',
  HIGH = 'High',
  ROUTINE = 'Routine',
  OPTIONAL = 'Optional',
}

export enum SubtaskVisibility {
  ONLY_HIDDEN = 'ONLY_HIDDEN',
  NO_HIDDEN = 'NO_HIDDEN',
  INCLUDE_HIDDEN = 'INCLUDE_HIDDEN',
}
export enum ScheduledCallSubtasks {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export enum SubtaskStatus {
  OPEN_UNASSIGNED = 'OPEN_UNASSIGNED',
  REATTEMPT = 'REATTEMPT',
  OPEN_ASSIGNED = 'OPEN_ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CLOSED_IN_ERROR = 'CLOSED_IN_ERROR',
  BLOCKED = 'BLOCKED',
}

export enum SubtaskRowDisplayType {
  SUBTASK_DETAIL = 'SUBTASK_DETAIL',
  TASK_ACCORDION = 'TASK_ACCORDION',
  THYMELINE = 'THYMELINE',
  /**
   * STATUS_UPDATE_MODAL: Used for modals that update subtask status.
   * This encapsulates Reattempt and SubtaskSubStatus modals.
   * Use this for any future modals with similar behavior or styling
   * related to updating subtask status.
   */
  STATUS_UPDATE_MODAL = 'STATUS_UPDATE_MODAL',
  MY_QUEUE = 'MY_QUEUE',
  SIDEBAR_QUEUE_STACK = 'SIDEBAR_QUEUE_STACK',
  ENROLLMENT = 'ENROLLMENT',
}

export enum QueueStyle {
  STANDARD = 'STANDARD',
  ENROLLMENT = 'ENROLLMENT',
}

// CONSTANTS --------------------
export const MAX_NUM_SUGGESTIONS = 3
export const MAX_NUM_SUBTASKS = 10
export const MAX_CHAR_SUBTASK_SUBJECT_LINE = 50

export const subtaskVisibilityEnumVal = {
  ONLY_HIDDEN: 'Only hidden',
  NO_HIDDEN: 'No hidden',
  INCLUDE_HIDDEN: 'Include hidden',
}
export const scheduledCallEnumVal = {
  INCLUDE: 'Only',
  EXCLUDE: 'None',
}
export const onlyOpenSubtaskStatuses = [
  SubtaskStatus.OPEN_UNASSIGNED,
  SubtaskStatus.OPEN_ASSIGNED,
]

export const openAssignedSubtaskStatuses = [
  SubtaskStatus.IN_PROGRESS,
  SubtaskStatus.OPEN_ASSIGNED,
]

export const openSubtaskStatuses = [
  ...openAssignedSubtaskStatuses,
  SubtaskStatus.OPEN_UNASSIGNED,
]

export const closedSubtaskStatuses = [
  SubtaskStatus.CANCELLED,
  SubtaskStatus.COMPLETED,
  SubtaskStatus.CLOSED_IN_ERROR,
]

export const statusesWithSubStatusReason: SubtaskStatus[] = [
  SubtaskStatus.CANCELLED,
  SubtaskStatus.ON_HOLD,
]

export const gvpSubtaskKeys = [
  'complete_assmnt_gvp',
  'complete_assmnt_gvp_gen',
  'complete_assmnt_gvp_ob',
]

// Used in subtaskTypeKeyValMap to reduce duplication
export const caseConferenceSubtaskKeys = [
  'complete_cc_ofbdhtr',
  'complete_cc_new',
  'complete_cc_new_gen',
  'complete_cc_essential',
  'complete_cc_fu',
  'complete_cc_rc',
  'complete_cc_new_eom',
]

export const subtaskTypeKelValMap = {
  'multi_vals-subtask_type-educate': ['educate_pcare', 'educate_palcare_gen'],
  'multi_vals-subtask_type-eom_enrollment': [
    'eom_enrollment_call_1',
    'eom_enrollment_call_2',
    'eom_enrollment_call_3',
    'eom_enrollment_call_4',
    'eom_enrollment_call_5',
    'eom_enrollment_call_6',
    'eom_enrollment_call_7',
    'eom_enrollment_call_8',
    'eom_enrollment_call_9',
    'eom_enrollment_call_10',
    'eom_enrollment_call_11',
    'eom_enrollment_call_12',
  ],
  'multi_vals-subtask_type-complete_assmnt': [
    'complete_assmnt_onboard_cp',
    'complete_assmnt_onboard_nonclin',
  ],

  'multi_vals-subtask_type-complete_assmnt_eddc': [
    'complete_assmnt_eddc',
    'complete_assmnt_eddc_ed',
  ],
  'multi_vals-subtask_type-complete_assmnt_ipa': [
    'complete_assmnt_ipa',
    'complete_assmnt_ipa_ia',
  ],
  'multi_vals-subtask_type-complete_assmnt_dc': [
    'complete_assmnt_dc',
    'complete_assmnt_dc_id',
    'complete_assmnt_dc_toc',
  ],
  'multi_vals-subtask_type-complete_assmnt_pacdc': [
    'complete_assmnt_pacdc',
    'complete_assmnt_pacdc_pac',
  ],
  'multi_vals-subtask_type-symptom_id_mobile': [
    'migrated_Symptom_Identification_f21267e4-0245-4bcc-bc9e-b01c0d0539dd',
    'symptom_id_mobile_call',
    'symptom_id_mobile_eom_call',
  ],
  'multi_vals-subtask_type-respond_sms': ['respond_sms_cp', 'respond_sms_es'],
  'multi_vals-subtask_type-case_conference': caseConferenceSubtaskKeys,
}

export const subtaskTypeFilterIndicator = 'subtask_type'

export const incompleteStatuses = [
  ...openSubtaskStatuses,
  SubtaskStatus.ON_HOLD,
  SubtaskStatus.BLOCKED,
]

export const priorityOptions = Object.keys(SubtaskPriority).map((priority) => {
  return {
    value: priority,
    label: SubtaskPriority[priority as keyof typeof SubtaskPriority],
  } as DropdownKV
})

export const queueStyleOptions = Object.keys(QueueStyle).map((style) => {
  return {
    value: style,
    label: QueueStyle[style as keyof typeof QueueStyle],
  } as DropdownKV
})

export const memberFacingThemeOptions = [
  { label: 'None', value: null },
  ...Object.keys(MemberFacingTheme).map((theme) => ({
    label: MemberFacingTheme[theme as keyof typeof MemberFacingTheme],
    value: theme,
  })),
]
