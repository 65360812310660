import { EventInput } from '@fullcalendar/core'
import { IconifyIcons } from '@thyme/nashville/src/types/icons'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import { nashColors } from 'tailwind.config.ts'
import { formatNameFromEntity } from '@/legacy/libs/format'
import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  Communication,
  CommunicationType,
  CommunicationParts,
} from '@/legacy/types/communications/communications'

export const useScheduleApi = apiStore<EventInput>(
  'scheduleApi',
  '/api/communications',
  {
    transformData: (d: { data: Communication[] }) => ({
      ...d,
      data: d.data.map((communication: Communication) => {
        let startTime =
          communication.plannedCall?.dueDatetime ??
          communication.completedDatetime
        // convert to UTC
        if (startTime) {
          startTime += 'Z'
        }
        const DEFAULT_DURATION = 30 // ASSUME 30 minute if not available
        const duration = communication.plannedCall?.duration ?? DEFAULT_DURATION
        const endTime = startTime
          ? DateTime.fromISO(startTime).plus({ minutes: duration }).toISO()
          : null
        const patient = get(communication, 'patients[0]')
        const callWith = patient ? formatNameFromEntity(patient) : 'UNKNOWN'
        let eventColor
        let textColor
        let icon
        if (communication.callDisposition?.disposition === 'ANSWERED') {
          eventColor = nashColors.fern100
          textColor = nashColors.fern700
          icon = IconifyIcons.check
        } else if (communication.callDisposition) {
          eventColor = nashColors.neutral200
          textColor = nashColors.neutral700
          icon = IconifyIcons.close
        }

        return {
          title: `Call with ${callWith}`,
          allDay: !startTime,
          start: startTime,
          end: endTime,
          id: communication.communicationId,
          ...(eventColor
            ? { backgroundColor: eventColor, borderColor: eventColor }
            : {}),
          ...(textColor ? { textColor } : {}),
          extendedProps: {
            ...(icon ? { icon } : {}),
            patientId: patient?.entityId,
          },
        } as EventInput
      }),
    }),
    params: {
      parts: [
        CommunicationParts.planned,
        CommunicationParts.disposition,
        CommunicationParts.patients,
      ],
      filter_types: [CommunicationType.Call],
      filter_unassigned: 'EXCLUDE',
    },
  }
)
