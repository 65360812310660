import { defineStore, storeToRefs } from 'pinia'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { IdMap } from '@/legacy/types/api/store'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import { Goal } from '@/legacy/types/pathways/goals'
import { usePatientStore } from './patient'

const GOALS_PAGE_LENGTH = 20

export const useGoalStore = defineStore('goal', {
  state: () => ({}),
  getters: {},
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} goal(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} goal: ${error}`,
      })
    },

    async setSuccess(action: string) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        await this.getGoals({ filter_member_ids: [patientId.value] })
      }
      useNotificationStore().setNotification({
        message: `Success ${action} goal(s)`,
        type: NotificationType.SUCCESS,
      })
      // refetch values for last updated section under care plans
      // when a goal is updated or created
      thymeDispatch('care-plan-update')
    },

    async getGoals({
      filter_goal_ids = null,
      filter_goal_ref_ids = null,
      filter_member_ids = null,
      filter_goal_statuses,
      page_length = GOALS_PAGE_LENGTH,
    }: {
      filter_goal_ids?: string[] | null
      filter_goal_ref_ids?: string[] | null
      filter_member_ids?: string[] | null
      filter_goal_statuses?: string[] | null
      page_length?: number | null
    }) {
      const goalApi = useGoalApi()
      const data = await goalApi.list({
        params: {
          ...(filter_goal_ids ? { filter_goal_ids } : {}),
          ...(filter_goal_ref_ids ? { filter_goal_ref_ids } : {}),
          ...(filter_member_ids ? { filter_member_ids } : {}),
          ...(filter_goal_statuses ? { filter_goal_statuses } : {}),
          page_length,
        },
      })

      if (goalApi.error) {
        this.setError(goalApi.error, 'fetching')
      }
      return data
    },

    async updateGoal(payload: Partial<Goal>) {
      const data = await useGoalApi().partialUpdate({
        body: payload,
        ids: payload.goalId,
      })
      const error = useGoalApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE)
        return
      }
      if (data) {
        await this.setSuccess(NotificationActions.UPDATE)
      }
    },

    async createGoal(payload: { [key: string]: string }) {
      const goalApi = useGoalApi()
      await goalApi.create({
        body: {
          ...payload,
        },
      })
      if (goalApi.error) {
        this.setError(goalApi.error, NotificationActions.CREATE)
        return
      }
      await this.setSuccess(NotificationActions.CREATE)
    },
  },
})

export const useGoalApi = apiStore<Goal, IdMap<Goal>>('goalApi', '/api/goals', {
  transformData: (d: { data: Goal[] }) =>
    idMapTransform({}, 'data', 'goalId', d.data),
})
