import values from 'lodash/values'
import { defineStore, storeToRefs } from 'pinia'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatNameFromEntity,
  formatDateTimeWithTime,
  formatDateTime,
  formatDateTimeUTC,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { IdMap } from '@/legacy/types/api/store'
import { Entity } from '@/legacy/types/entities/entities'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import { Reattempt, ReattemptPayload } from '@/legacy/types/pathways/reattempts'
import { usePatientStore } from './patient'
import { useTasksStore } from './tasks'

// CONST
const REATTEMPTS_PAGE_LENGTH = 20

export type StaffMap = IdMap<Entity>
/**
 *
 * @param subtaskId
 * @param reattemptDate
 * @param reattemptData
 */
export function renderReattemptTooltip(
  subtaskId: string,
  reattemptDate: string,
  reattemptData: Reattempt[] | null = null
) {
  const { data: staff } = storeToRefs(useStaffApi())

  const reattempts = reattemptData ?? values(useReattemptApi().data)

  const matchedReattempt = reattempts.find(
    (reattempt: Reattempt) =>
      reattempt.subtaskId === subtaskId &&
      formatDateTimeUTC(stringToDateTime(reattempt.reattemptDatetime)) ===
        formatDateTimeUTC(stringToDateTime(reattemptDate))
  )

  if (matchedReattempt) {
    const staffName = formatNameFromEntity(
      safeLookup(matchedReattempt.createdBy, staff.value as StaffMap)
    )
    return `Reattempt set by ${staffName} on ${formatDateTimeWithTime(
      stringToDateTime(matchedReattempt.createdAt)
    )} to ${formatDateTime(
      stringToDateTime(matchedReattempt.reattemptDatetime)
    )}`
  }
  return 'Reattempt information not found.'
}

export const useReattemptStore = defineStore('reattempt', {
  state: () => ({}),
  getters: {},
  actions: {
    async setSuccess(action: string) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        // Ensure reattempts trigger watches to update sidebar components
        await Promise.all([
          useTasksStore().getTasks({ filter_member_ids: [patientId.value] }),
        ])
      }
      useNotificationStore().setNotification({
        message: `Success ${action} reattempt`,
        type: NotificationType.SUCCESS,
      })
    },
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} reattempt`,
        type: NotificationType.DANGER,
        error: `Error ${action} reattempt: ${error}`,
      })
    },
    async getReattempts({
      filter_subtask_ids = null,
      filter_reattempt_ids = null,
      filter_member_ids = null,
      page_length = REATTEMPTS_PAGE_LENGTH,
    }: {
      filter_subtask_ids?: string[] | null
      filter_reattempt_ids?: string[] | null
      filter_member_ids?: string[] | null
      page_length?: number | null
    }) {
      const reattemptApi = useReattemptApi()
      const data = await reattemptApi.list({
        params: {
          ...(filter_reattempt_ids ? { filter_reattempt_ids } : {}),
          ...(filter_subtask_ids ? { filter_subtask_ids } : {}),
          ...(filter_member_ids ? { filter_member_ids } : {}),
          page_length,
        },
      })
      if (reattemptApi.error) {
        this.setError(reattemptApi.error, 'fetching')
      }
      return data
    },
    async createReattempt(payload: ReattemptPayload) {
      const reattemptApi = useReattemptApi()
      await reattemptApi.create({
        body: {
          ...payload,
        },
      })
      if (reattemptApi.error) {
        this.setError(reattemptApi.error, NotificationActions.CREATE)
        return
      }
      await this.setSuccess(NotificationActions.CREATE)
    },
  },
})

export const useReattemptApi = apiStore<Reattempt, IdMap<Reattempt>>(
  'reattemptApi',
  '/api/subtask/reattempts',
  {
    transformData: (d: { data: Reattempt[] }) =>
      idMapTransform({}, 'data', 'reattemptId', d.data),
  }
)
