<template>
  <CascadeSelect
    v-if="cascade"
    :model-value="modelValue"
    :data-cy="name && `${kebabCase(name)}-dropdown`"
    :pt="pt"
    v-bind="$attrs"
    @update:model-value="update"
  >
    <!-- @vue-skip s as string -->
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
  </CascadeSelect>
  <MultiSelect
    v-else-if="multiple"
    :model-value="modelValue"
    :data-cy="name && `${kebabCase(name)}-dropdown`"
    v-bind="$attrs"
    :pt="{
      closeButton: { class: 'hidden' },
      ...pt,
    }"
    @update:model-value="update"
  >
    <!-- @vue-skip s as string -->
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
    <template #header></template>
  </MultiSelect>
  <Dropdown
    v-else
    :model-value="modelValue"
    :data-cy="name && `${kebabCase(name)}-dropdown`"
    :pt="pt"
    show-clear
    v-bind="$attrs"
    @update:model-value="update"
  >
    <!-- @vue-skip s as string -->
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
  </Dropdown>
</template>

<script lang="ts">
import kebabCase from 'lodash/kebabCase'
import CascadeSelect from 'primevue/cascadeselect'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {
    CascadeSelect,
    Dropdown,
    MultiSelect,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    initialValue: {
      type: [String, Number, Array, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    pt: {
      type: Object,
      default: () => ({}),
    },
    cascade: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    if (props.multiple && props.cascade) {
      console.error(
        'cannot set multiple and cascade at the same time on TDropdown'
      )
    }

    const modelValue = ref<any>(props.initialValue)
    watch(
      () => props.initialValue,
      (initialValue: any) => (modelValue.value = initialValue)
    )
    const update = (newValue: any) => {
      modelValue.value = newValue
      context.emit('update:modelValue', newValue)
    }
    return {
      modelValue,
      kebabCase,
      update,
    }
  },
})
</script>
