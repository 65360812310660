<template>
  <div class="flex flex-row space-x-2">
    <TLabel :label="carePodName" />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted } from 'vue'
import TLabel from '@/legacy/nashville/TLabel.vue'

import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import { CarePod } from '@/legacy/types/carePods'

export default defineComponent({
  components: { TLabel },
  props: {
    carePodId: { type: String, required: true },
  },
  setup(props) {
    const { data: carePods } = storeToRefs(useAdminCarePodApi())

    const carePodName = computed(() => {
      let matchedCarePod
      if (props.carePodId && carePods.value?.length) {
        matchedCarePod = carePods.value.find(
          ({ carePodId }: CarePod) => carePodId === props.carePodId
        )
      }
      return matchedCarePod ? matchedCarePod.name : 'Unknown Care Pod'
    })

    const fetchAllCarePods = async () => {
      await useAdminCarePodApi().listAll({})
    }

    onMounted(async () => {
      if (!carePods.value) {
        await fetchAllCarePods()
      }
    })

    return {
      carePodName,
    }
  },
})
</script>
