import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createUniqueSubtaskApiStore } from '@/legacy/store/modules/subtasks'
import { Subtask, SubtaskStatus } from '@/legacy/types/pathways/subtasks'

/**
 *
 * @param props
 */
export default function (props: any) {
  const router = useRouter()
  const route = useRoute()

  const useCarePlanSubtaskApi = createUniqueSubtaskApiStore(props.name)

  const { data: subtasks } = storeToRefs(useCarePlanSubtaskApi())

  const computedTask = computed(() => props.task)

  /**
   * Function to fetch all subtasks for task prop
   */
  async function getSubtasksForTask() {
    return await useCarePlanSubtaskApi().listAll({
      params: {
        filter_member_ids: [route.params.patientId as string],
        filter_subtask_ids: computedTask.value.subtaskIds,
      },
    })
  }

  const completedSubtasksNum = computed(() => {
    if (!subtasks.value) {
      return
    }
    return (
      subtasks.value.filter(
        (subtask: Subtask) => subtask.status === SubtaskStatus.COMPLETED
      ).length ?? 0
    )
  })

  const subtaskIdsNum = computed(() => computedTask.value.subtaskIds.length)

  const goToPathway = (pathwayId: string) => {
    void router.push({
      query: {
        pathwayId: pathwayId,
        taskId: computedTask.value.taskId,
      },
    })
  }

  onMounted(async () => {
    await getSubtasksForTask()
  })

  return {
    subtaskIdsNum,
    completedSubtasksNum,
    useCarePlanSubtaskApi,
    goToPathway,
    router,
    getSubtasksForTask,
    subtasks,
  }
}
