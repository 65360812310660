<template>
  <TMFilterDropdown
    :model-value="modelValue"
    :options="staffOptions"
    option-group-label="label"
    option-group-children="items"
    option-label="label"
    option-value="value"
    input-id="assignee-input"
    :placeholder="placeHolderValue"
    :virtual-scroller-options="virtualScrollOptions"
    v-bind="$attrs"
    :show-clear="false"
    @update:model-value="update"
    @filter="updateFilter"
  >
    <template #option="slotProps">
      <TTextMatch
        :search-text="filterValue"
        :option-text="slotProps.option.label"
      />
    </template>
  </TMFilterDropdown>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, watch } from 'vue'
import {
  additionalAssignableRoles,
  humanizeRole,
  subtaskOwnerRoleOptions,
} from '@/legacy/libs/entity'
import { formatNameFromEntity } from '@/legacy/libs/format'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { createPaginatedStaffApi } from '@/legacy/store/modules/staff'
import {
  EntityRoleSelectType,
  Entity,
  EntityRole,
} from '@/legacy/types/entities/entities'
import TMFilterDropdown from './TMFilterDropdown.vue'
import TTextMatch from './TTextMatch.vue'
import { getVirtualScrollOptions } from './virtualScroll'

const itemHeight = 35

const clinicalRoles = subtaskOwnerRoleOptions(EntityRoleSelectType.PVDropdown)

export default defineComponent({
  components: { TMFilterDropdown, TTextMatch },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    staffOnly: {
      type: Boolean,
      default: false,
    },
    showSpecializationRoles: {
      type: Boolean,
      default: false,
    },
    canUnassign: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  setup(props, context) {
    const specializationRolesFilter = computed(() =>
      props.showSpecializationRoles
        ? additionalAssignableRoles.map((obj) => obj.value)
        : []
    )
    const useStaffApi = createPaginatedStaffApi(
      props.name,
      specializationRolesFilter.value
    )
    const { selfEntity } = storeToRefs(useProfileStore())
    const { computedData: staff, datum: selectedStaff } = storeToRefs(
      useStaffApi()
    )

    const virtualScrollOptions = getVirtualScrollOptions(
      useStaffApi,
      itemHeight
    )

    const updateFilter = virtualScrollOptions.value.filter
    const filterValue = computed(() => virtualScrollOptions.value.filterValue)

    const placeHolderValue = computed(() =>
      props.modelValue === EntityRole.PATIENT
        ? humanizeRole(EntityRole.PATIENT)
        : ''
    )

    onMounted(() => {
      if (props.modelValue && isUUID(props.modelValue)) {
        virtualScrollOptions.value.getModelLabel(props.modelValue)
      }
    })
    watch(
      () => props.modelValue,
      (newValue: string) => {
        if (isUUID(newValue)) {
          virtualScrollOptions.value.getModelLabel(newValue)
        }
      }
    )

    const staffOptions = computed(() => {
      const roles = []
      if (props.showSpecializationRoles) {
        roles.push(...clinicalRoles, ...additionalAssignableRoles)
      }
      roles.push(...clinicalRoles)
      let staffList = selectedStaff.value
        ? [
            {
              label: formatNameFromEntity(selectedStaff.value),
              value: selectedStaff.value.entityId,
            },
          ]
        : []

      if (staff.value) {
        staffList = [
          ...staffList,
          ...Object.values(staff.value).map((entity: Entity) => ({
            label: formatNameFromEntity(entity),
            value: entity.entityId,
          })),
        ]
      }

      if (!staffList.length) {
        staffList.push({ label: 'No Options Available', value: '' })
      }
      const assignLabel = props.canUnassign
        ? 'Unassign/Assign to me'
        : 'Assign to me'
      const assignToMeOptions = [
        {
          label: 'Me',
          value: selfEntity.value?.entityId,
        },
      ]

      if (props.canUnassign) {
        assignToMeOptions.unshift({
          label: 'Unassigned',
          value: 'NULL',
        })
      }

      if (props.staffOnly) {
        return [
          {
            label: assignLabel,
            items: assignToMeOptions,
          },
          {
            label: 'Staff',
            items: staffList,
          },
        ]
      }
      return [
        {
          label: assignLabel,
          items: assignToMeOptions,
        },
        {
          label: 'Roles',
          items: roles,
        },
        {
          label: 'Staff',
          items: staffList,
        },
      ]
    })

    /**
     * check if the string is a uuid or a role
     * @param maybeUUID
     */
    function isUUID(maybeUUID: string) {
      return maybeUUID.match(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      )
    }

    /**
     * Determine if selected string is a UUID (EntityId) or Role
     * Save and emit
     * @param assignedEntityIdOrRole
     */
    function update(assignedEntityIdOrRole: string) {
      if (isUUID(assignedEntityIdOrRole)) {
        context.emit('update', {
          responsibleStaffId: assignedEntityIdOrRole,
          responsibleRole: null,
        })
      } else {
        context.emit('update', {
          responsibleStaffId: null,
          responsibleRole: assignedEntityIdOrRole,
        })
      }
    }

    return {
      staffOptions,
      update,
      virtualScrollOptions,
      updateFilter,
      filterValue,
      EntityRole,
      humanizeRole,
      placeHolderValue,
    }
  },
})
</script>
