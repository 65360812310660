<template>
  <TModal
    :is-visible="isOpen"
    :size="ModalSize.SM"
    class="text-nash-neutral900"
    :allow-overflow="true"
    @close="close"
  >
    <div class="flex justify-between">
      <div class="flex">
        <LegacyTButton
          v-if="showSelectedSubtasksView"
          class="mr-2"
          name="back-add-task-subtasks"
          icon="chevronLeft"
          type="blackAndWhite"
          :size="ButtonSize.MD"
          @click="toggleSelectedSubtasksView"
        />
        <h2>
          {{
            showSelectedSubtasksView
              ? 'Selected Subtasks'
              : reattempt && reschedule
              ? 'Reattempt and Reschedule'
              : reattempt
              ? 'Reattempt All'
              : 'Reschedule'
          }}
        </h2>
      </div>
      <LegacyTButton
        name="close-reschedule-modal"
        icon="close"
        inline
        type="blackAndWhite"
        class="text-nash-neutral600"
        :size="ButtonSize.MD"
        @click="close"
      />
    </div>
    <div v-if="!showSelectedSubtasksView" class="space-y-4 min-h-450">
      <TMTertiaryButton
        v-if="linkedSubtasks?.length"
        class="pt-2"
        icon-pos="right"
        icon="chevronRight"
        :label="
          linkedSubtasks?.length > 1
            ? `View selected subtasks to reattempt (${linkedSubtasks?.length})`
            : `View selected subtask to reattempt (1)`
        "
        @click="toggleSelectedSubtasksView"
      />
      <div v-if="linkedSubtasks?.length" class="mb-4 text-nash-neutral700">
        Choose a reattempt day for the subtasks above. Please note, you can only
        select a due date within 60 days.
      </div>
      <TOStaffAssignDropdown
        v-if="reschedule"
        v-model="responsible"
        name="ReattemptAssign"
        class="w-full"
        @update="updateAssigned"
      />
      <ScheduleDueDatetimeDropdowns
        v-if="reschedule"
        @update="updateDateTime"
      />
      <TDatePicker
        v-if="!reschedule"
        v-model="dirtyReattempt.reattemptDatetime"
        :min-date="minDateTomorrow"
        :max-date="maxReattemptDays"
        :unselectable-days-of-week="unselectableDaysOfWeek"
        :is-datetime="false"
        :placeholder="datepickerPlaceholder"
        @update:model-value="(v) => setReattemptDate(v)"
      />
      <TDropdown
        v-if="reattempt"
        :options="reasonOptions"
        input-id="reattempt-reason-input"
        class="w-full"
        placeholder="Reattempt Reason"
        @update:model-value="dirtyReattempt.reattemptReason = $event"
      />
      <TTextarea
        v-if="dirtyReattempt.reattemptReason === 'Other'"
        v-model="otherReason"
        name="reattempt-other-reason"
        class="w-full"
        placeholder="Other: reason for reattempting subtasks..."
      />
    </div>
    <template #actions>
      <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
      <TMPrimaryButton
        label="Confirm"
        name="done"
        :disabled="!hasValidData"
        @click="save"
      />
    </template>

    <div v-if="showSelectedSubtasksView" class="py-4">
      <div
        v-for="linkedSubtask in linkedSubtasks"
        :key="linkedSubtask?.subtaskId"
        class="selected-subtask-item-wrapper"
      >
        <b>
          {{ linkedSubtask?.title }}
          {{
            linkedSubtask?.variantDisplay
              ? `${linkedSubtask?.variantDisplay}`
              : ''
          }}
        </b>
        <div v-if="linkedSubtask?.description" class="text-sm py-2">
          {{ linkedSubtask?.description }}
        </div>
        <div class="flex gap-3 mt-1 text-nash-neutral700">
          <div class="flex items-center space-x-1 gap-1">
            Est: {{ linkedSubtask?.estimatedMinutesToComplete ?? 0 }} min
          </div>
          <div class="bullet"></div>
          <div
            v-if="staff && linkedSubtask"
            class="flex items-center space-x-1 gap-1"
          >
            <div>
              {{ renderRoleOrStaff(linkedSubtask, staff) }}
            </div>
          </div>
          <div class="bullet"></div>
          <div class="flex items-center space-x-1 gap-1">
            <TIcon
              v-if="linkedSubtask"
              id="subtask-priority-icon"
              :icon="getPriorityIcon(linkedSubtask.priority)"
              :size="Size.MD"
              fill-type="neutral500"
            />
            <div>
              {{ capitalize(linkedSubtask?.priority.toLowerCase()) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { PropType, defineComponent } from 'vue'
import ScheduleDueDatetimeDropdowns from '@/legacy/components/patient/communicationV2/dropdowns/ScheduleDueDatetimeDropdowns/ScheduleDueDatetimeDropdowns.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

import TModal from '@/legacy/nashville/TModal.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TDropdown,
    TModal,
    TOStaffAssignDropdown,
    ScheduleDueDatetimeDropdowns,
    TMPrimaryButton,
    TMSecondaryButton,
    TTextarea,
    TDatePicker,
    TMTertiaryButton,
    LegacyTButton,
    TIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    reschedule: {
      type: Boolean,
      default: false,
    },
    reattempt: {
      type: Boolean,
      default: false,
    },
    subtaskIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    calleeEntityId: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    communicationId: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup,
})
</script>

<style scoped>
.selected-subtask-item-wrapper {
  @apply border border-nash-neutral300 py-2 px-3 w-full rounded-lg py-2 my-4;
}

.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
