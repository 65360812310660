/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum HTTPStatusCode {
  STATUS_200_OK = 200,
  STATUS_201_CREATED = 201,
  STATUS_204_NO_CONTENT = 204,
  ERROR_400_BAD_REQUEST = 400,
  ERROR_401_UNAUTHORIZED = 401,
  ERROR_404_NOT_FOUND = 404,
  ERROR_409_CONFLICT = 409,
  ERROR_422_UNPROCESSABLE_ENTITY = 422,
  ERROR_500_INTERNAL_SERVER_ERROR = 500,
  ERROR_502_BAD_GATEWAY = 502,
  ERROR_503_SERVICE_UNAVAILABLE = 503,
  ERROR__504_GATEWAY_TIMEOUT = 504,
}
