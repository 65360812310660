<template>
  <div class="overflow-visible card-style pt-4 w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <div class="grid-item">
            <label>Cancer</label>
          </div>
          <div class="grid-item">
            {{ cancerDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Spread</label>
          </div>
          <div class="grid-item">
            {{ spreadDisplay }}
          </div>
          <div class="grid-item">
            <label>Diagnosis Date</label>
          </div>
          <div class="grid-item">
            {{ diagnosisDateDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Last Oncology Appointment</label>
          </div>
          <div class="grid-item">
            {{ lastOncoApptDisplay }}
          </div>
          <div class="grid-item">
            <label>Next Oncology Appointment</label>
          </div>
          <div class="grid-item">
            {{ nextOncoApptDisplay }}
          </div>
          <div class="grid-item">
            <label>Oncologist Name</label>
          </div>
          <div class="grid-item">
            {{ oncoNameDisplay }}
          </div>
          <div class="grid-item">
            <label>Surgeon name</label>
          </div>
          <div class="grid-item">
            {{ surgeonNameDisplay }}
          </div>
          <div class="grid-item">
            <label>Radiation Oncologist Name</label>
          </div>
          <div class="grid-item">
            {{ radiationOncoNameDisplay }}
          </div>
          <div class="grid-item">
            <label>Palliative Medicine Name</label>
          </div>
          <div class="grid-item">
            {{ palliativeMedicineNameDisplay }}
          </div>
          <div class="grid-item">
            <label>Primary Care Name</label>
          </div>
          <div class="grid-item">
            {{ primaryCareNameDisplay }}
          </div>
          <div class="grid-item">
            <label>Additional Information</label>
          </div>
          <div class="grid-item">
            {{ additionalInformationDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
