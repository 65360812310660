import { AcuityThreshold, TierLevel } from '@/legacy/types/acuity'

/**
 * To replace acuity
 * @param score
 */
export function formatTierScore(score: number | undefined) {
  if (!score) {
    return '-'
  }
  return score > AcuityThreshold.High
    ? TierLevel.COMPLEX
    : score > AcuityThreshold.Medium
    ? TierLevel.STANDARD
    : TierLevel.ESSENTIAL
}
