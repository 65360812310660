<template>
  <div class="w-full section-divider pb-5">
    <div class="space-y-3">
      <div class="flex justify-between">
        <h5 class="text-nash-neutral600 font-bold">
          Goal {{ goal.sortNumber }}
        </h5>
        <div class="flex items-center">
          <TOIconDropdown
            v-if="!isCompletedOrCancelled"
            class="mr-3.5"
            name="goal-sort"
            icon="chevronUpDown"
            option-label="label"
            option-value="value"
            :options="sortOptions"
            @change="updateSort"
          />
          <TOIconDropdown
            v-if="!isCompletedOrCancelled"
            name="pathway-status"
            icon="meatball"
            :options="goalStatusOptions"
            @change="updateGoalStatus"
          />
        </div>
      </div>
      <h4 class="font-bold">
        <span v-if="goal.freeTextTitle"> {{ goal.freeTextTitle }}</span>
        <span v-else>
          {{
            goal.goalReference?.titleDisplay ?? 'Please select goal title'
          }}</span
        >
      </h4>
      <div class="text-nash-neutral700 mb-1">
        {{ goal.timeline }}
      </div>
      <div class="whitespace-pre-wrap">
        {{ goal.details }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import { Goal } from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'
import setup from './controller'

export default defineComponent({
  components: {
    TOIconDropdown,
  },
  props: {
    goal: {
      type: Object as PropType<Goal>,
      default: () => ({}),
    },
    goalsNum: {
      type: Number,
      default: 0,
    },
    goalPathways: {
      type: Array as PropType<Pathway[]>,
      default: () => [],
    },
  },
  setup,
})
</script>
<style lang="scss">
.section-divider {
  @apply border-b border-nash-neutral300 pb-1 mb-2 mt-5;
}
</style>
