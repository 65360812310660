import { requiredInject } from '@thyme/libs/src/vue/inject'
import { InputType } from '@thyme/nashville/src/types/inputs'
import { storeToRefs } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import { eomStatusMap } from '@/legacy/types/patients/eomStatus'
import { MemberAppAccess } from '@/legacy/types/patients/patients'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

/**
 * Set up the PatientSummary component
 */
export function setup(): {
  InputType: typeof InputType
  patientDisplay: Ref<{
    carePodId?: string | null
    entityId: string
    summary: string
  } | null>
  patientLoading: Ref<boolean>
  submit: (summary: string) => Promise<void>
  saveState: Ref<SaveState>
  additionalLabels: Ref<string[]>
} {
  const patientStore = usePatientStore()
  const { patient, isLoading: patientLoading } = storeToRefs(patientStore)
  const patientId = requiredInject(PATIENT_ID_KEY)

  const patientDisplay = computed(() => {
    if (!patient.value) {
      return null
    }
    return {
      carePodId: patient.value.carePodId,
      entityId: patient.value.entityId,
      summary: patient.value.summaryNote,
    }
  })

  const saveState = ref<SaveState>(newSaveState())
  const { enablePatientContracts } = storeToRefs(useFlagStore())

  /**
   * Update the patient's summary note with new content
   * @param summary new summary note content
   */
  async function submit(summary: string) {
    try {
      await usePatientsApi().partialUpdate({
        ids: [patientId.value],
        body: { summaryNote: summary },
        metaOptions: { saveState: saveState.value, bubbleErrorThrow: true },
      })
      void patientStore.patientApiCall(patientId.value)
    } catch (err) {
      console.error(err)
      return
    }
  }

  // Additional labels that are not part of the labels API and trivial
  // enough not to warrant their own component.
  const additionalLabels = computed(() => {
    const labels = []

    if (
      patient.value?.memberAppAccess &&
      patient.value.memberAppAccess !== MemberAppAccess.DISABLED
    ) {
      labels.push('TC Connect')
    }

    if (patient.value?.eomStatus && !enablePatientContracts.value) {
      labels.push(eomStatusMap[patient.value.eomStatus])
    }

    return labels
  })

  return {
    InputType,
    patientDisplay,
    patientLoading,
    submit,
    saveState,
    additionalLabels,
  }
}
