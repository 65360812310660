<template>
  <div class="relative">
    <TMSecondaryButton
      class="add-comms-btn"
      :disabled="isCreating || !!queryCommId"
      label="+ Communication"
      name="add-communication"
      @click="createCommunication"
    />
    <div class="flex space-x-4">
      <TDropdown
        :options="freeTextOptions"
        :initial-value="freeTextFilterName"
        :show-clear="false"
        class="bg-nash-neutral000 h-1/4 mt-5"
        option-label="label"
        option-value="value"
        @update:model-value="setFreeTextFilterName"
      />
      <TSearchAndFilter
        search-placeholder="Search"
        class="mt-5 w-1/3"
        @update-search="updateSearch"
      />
    </div>
    <div v-if="lastSuccessfulCall && showLastSuccessfulCall" class="space-y-4">
      <h5 data-cy="thymeline-header" class="pb-1 flex items-end">
        Last Successful Call &nbsp;<span
          v-if="lastSuccessfulCall?.completedDatetime"
        >
          ({{ getReadableTimeDiff(lastSuccessfulCall?.completedDatetime) }})
        </span>
      </h5>
      <CommunicationsRow
        class="last-successful-call-container"
        :communication="lastSuccessfulCall"
        name="communications"
      />
    </div>
    <div class="space-y-4">
      <!-- Completed thymeline -->
      <TMThymeline
        v-slot="{ item, getData }"
        :name="`${patientId}-CompletedThymeline`"
        class="history-thymeline"
        title="Completed"
        :filters="filters"
        :rows="perPage"
        :columns="columns"
        :params="completedParams"
        :api-store="useCompletedThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="true"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          name="communications"
        />
        <TransitionOfCareRow
          v-else-if="item.itemType === ThymelineItemType.TRANSITION_OF_CARE"
          :toc="item.item"
        />
      </TMThymeline>
      <!-- Cancelled thymeline -->
      <LegacyTButton
        type="tertiary"
        class="pt-2"
        icon-position="right"
        :icon="showCancelled ? 'chevronUp' : 'chevronDown'"
        @click="showCancelled = !showCancelled"
        >{{
          showCancelled ? 'Hide Cancelled' : 'Show Cancelled'
        }}</LegacyTButton
      >
      <TMThymeline
        v-if="showCancelled"
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-CancelledThymeline`"
        title="Cancelled"
        :rows="perPage"
        :columns="columns"
        :params="cancelledParams"
        :api-store="useCancelledThymelineApi"
        :show-rows-per-page="false"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          name="communications"
        />
      </TMThymeline>
      <SubtaskReattemptModal
        v-if="selectedReattemptSubtask"
        :subtask="selectedReattemptSubtask"
        :is-open="showReattemptModal"
        @close="closeReattemptModal"
      />
      <SubtaskSubstatusModal
        v-if="
          showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
        "
        :subtask="selectedSubStatusSubtask"
        :status="subtaskStatus"
        :is-open="showSubStatusModal"
        @close="showSubStatusModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { FilterType } from '@thyme/nashville/src/types/tables'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'

import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import CommunicationsRow from '@/legacy/components/patient/thymeline/CommunicationsRow/CommunicationsRow.vue'
import TransitionOfCareRow from '@/legacy/components/patient/transitionOfCare/TransitionOfCareRow.vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'

import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import {
  useCommunicationsStore,
  useLastSuccessfulCallApi,
} from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useCancelledThymelineApi,
  useCompletedThymelineApi,
} from '@/legacy/store/modules/thymelines'

import {
  CALL_PARTS,
  Communication,
  CommunicationType,
} from '@/legacy/types/communications/communications'
import {
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'
import {
  ThymelineItemType,
  ThymelineStatus,
} from '@/legacy/types/patients/thymelines'
import {
  columns,
  perPage,
  overloadSearchServiceList,
  getCommunicationEntities,
} from './lib/sharedThymelineParts'

// CONST
const DEBOUNCE_INTERVAL_MS = 300

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TMThymeline,
    SubtaskReattemptModal,
    LegacyTButton,
    SubtaskRow,
    CommunicationsRow,
    TMSecondaryButton,
    TSearchAndFilter,
    TDropdown,
    TransitionOfCareRow,
  },
  setup() {
    overloadSearchServiceList(useCompletedThymelineApi())
    overloadSearchServiceList(useCancelledThymelineApi())

    const { showLastSuccessfulCall } = storeToRefs(useFlagStore())

    const route = useRoute()

    const showCancelled = ref(false)

    const showReattemptModal = ref(false)
    const selectedReattemptSubtask = ref<Subtask | null>(null)
    const lastSuccessfulCall = ref<Communication | null>(null)
    const showSubStatusModal = ref(false)
    const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
    const selectedSubStatusSubtask = ref<Subtask | null>(null)
    const subtaskStatus = ref<SubtaskStatus | null>(null)

    onMounted(async () => {
      if (showLastSuccessfulCall.value) {
        lastSuccessfulCall.value = await fetchLastSuccessfulCall()
      }
    })

    const completedParams: { [key: string]: any } = computed(() => {
      return {
        patient_id: route.params.patientId as string,
        filter_status: ThymelineStatus.COMPLETED,
        filter_free_text_title: '',
        filter_free_text_notes: '',
      }
    })

    const cancelledParams: { [key: string]: any } = computed(() => {
      return {
        patient_id: route.params.patientId as string,
        filter_status: ThymelineStatus.CANCELLED,
        filter_free_text_title: '',
        filter_free_text_notes: '',
      }
    })

    /**
     * Trigger close reattempt modal
     */
    function closeReattemptModal() {
      showReattemptModal.value = false
    }
    /**
     * Trigger open reattempt modal
     * @param subtask
     */
    function openReattemptModal(subtask: Subtask) {
      showReattemptModal.value = true
      selectedReattemptSubtask.value = subtask
    }

    /**
     * Function to show/open SubStatusModal modal
     * @param subtask
     * @param status
     */
    function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
      showSubStatusModal.value = true
      subtaskStatus.value = status
      selectedSubStatusSubtask.value = subtask
    }

    /**
     * Take completed datetime from comm and
     * return time difference from current time to display
     * @param completedDatetime
     */
    function getReadableTimeDiff(completedDatetime: string) {
      const diff = DateTime.now().diff(DateTime.fromISO(completedDatetime), [
        'months',
        'days',
        'hours',
      ])
      if (diff.months > 0) {
        return `${Math.floor(diff.months)} month${
          diff.months > 1 ? 's' : ''
        } ago`
      } else if (diff.weeks > 0) {
        return `${Math.floor(diff.weeks)} week${diff.weeks > 1 ? 's' : ''} ago`
      } else if (diff.days > 0) {
        return `${Math.floor(diff.days)} day${diff.days > 1 ? 's' : ''} ago`
      } else if (diff.hours > 0) {
        return `${Math.floor(diff.hours)} hour${diff.hours > 1 ? 's' : ''} ago`
      } else {
        return ` less than an hour ago `
      }
    }

    const filters = {
      itemTypes: {
        value: null,
        modalOptions: {
          label: 'Item Type',
          type: FilterType.Multiselect,
          options: [
            {
              value: ThymelineItemType.SUBTASK,
              label: 'Subtask',
            },
            {
              value: ThymelineItemType.COMMUNICATION,
              label: 'Communication',
            },
            {
              value: ThymelineItemType.TRANSITION_OF_CARE,
              label: 'Transition Of Care',
            },
          ],
          preEnabledOptionsFn: () => [
            ThymelineItemType.SUBTASK,
            ThymelineItemType.COMMUNICATION,
            ThymelineItemType.TRANSITION_OF_CARE,
          ],
        },
      },
    }

    /** Add Communication Button Code */

    const communicationsStore = useCommunicationsStore()

    const isCreating = computed(() => communicationsStore.isCreating)
    const queryCommId = computed(() => `${route.query.commId ?? ''}`)

    /**
     *
     */
    function createCommunication() {
      void communicationsStore.setIsCreating()
    }

    /** End Add Communication Button Code */

    /** Search Filter Code */

    const currentSearch = ref('')
    const freeTextFilterName = ref('filter_free_text_title')
    const freeTextOptions = [
      { label: 'Subtask Title', value: 'filter_free_text_title' },
      { label: 'Notes', value: 'filter_free_text_notes' },
    ]

    const updateSearch = (newSearch: string) =>
      (currentSearch.value = newSearch ?? '')

    const debouncedSearch = debounce(() => {
      thymeDispatch('thymeline-update')
    }, DEBOUNCE_INTERVAL_MS)

    /**
     * set values for free text filters
     */
    function setFilterValues() {
      completedParams.value[freeTextFilterName.value] = currentSearch.value
      cancelledParams.value[freeTextFilterName.value] = currentSearch.value
    }

    const setFreeTextFilterName = (val: string) => {
      currentSearch.value = ''
      setFilterValues()
      freeTextFilterName.value = val
    }

    watch(currentSearch, async () => {
      setFilterValues()
      debouncedSearch()
    })

    /** End Search Filter Code */

    /**
     * Fetch most recent answered call disposition
     */
    async function fetchLastSuccessfulCall() {
      const comms = await useLastSuccessfulCallApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          filter_types: [CommunicationType.Call],
          filter_is_completed: true,
          filter_call_dispositions: ['ANSWERED'],
          sort_by: 'completedDatetime,desc',
          parts: CALL_PARTS,
        },
      })

      if (comms && comms.data.length > 0) {
        await getCommunicationEntities(comms.data)
        return comms.data[0]
      }
      return null
    }

    return {
      selectedReattemptSubtask,
      showReattemptModal,
      openReattemptModal,
      closeReattemptModal,
      showSurfacedStatusReason,
      subtaskStatus,
      showSubStatusModal,
      selectedSubStatusSubtask,
      openSubStatusModal,
      patientId: route.params.patientId as string,
      perPage,
      columns,
      showCancelled,

      ThymelineItemType,
      SubtaskRowDisplayType,

      useCompletedThymelineApi,
      useCancelledThymelineApi,

      completedParams,
      cancelledParams,

      filters,

      createCommunication,
      isCreating,
      queryCommId,

      updateSearch,
      setFreeTextFilterName,
      freeTextOptions,
      currentSearch,
      freeTextFilterName,

      lastSuccessfulCall,
      getReadableTimeDiff,
      showLastSuccessfulCall,
    }
  },
})
</script>

<style lang="scss" scoped>
.add-comms-btn {
  position: absolute;
  top: -40px;
  right: 0;
}

.last-successful-call-container {
  @apply w-full bg-nash-purple100 border border-nash-purple400 rounded-lg px-4 py-3;
}
</style>
