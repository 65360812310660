import { object, string, InferType } from 'yup'

export const schema = object({
  fallInTheLastTwelveMonths: string().nullable(),
  fearOfFalling: string().nullable(),
  peripheralNeuropathy: string().nullable(),
  sedatingMedications: string().nullable(),
  unsteadyWithStandingOrWalking: string().nullable(),
  usesDMEToAssistWithAmbulation: string().nullable(),
  anticoagulation: string().nullable(),
  additionalContext: string().nullable(),
})

export type EditFallRiskForm = InferType<typeof schema>

export const editFallRiskProps = {
  patientId: {
    type: String,
    required: true,
  },
} as const
