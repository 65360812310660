import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useSocialSummariesApi } from '@/legacy/store/modules/socialSummaries'
import {
  BenefitsProgramsServices,
  CaregiverMapping,
  FinancialBarrier,
  FoodBarrier,
  HousingBarrier,
  InHomeSupport,
  SocialSummaryFields,
  SocialSupport,
  TransportationBarrier,
  benefitsProgramsServicesOptions,
  caregiverMappingOptions,
  financialBarrierOptions,
  foodBarrierOptions,
  housingBarrierOptions,
  inHomeSupportOptions,
  socialSupportOptions,
  transportationBarrierOptions,
} from '@/legacy/types/patients/socialSummaries'

export const getLabels = (
  val: string,
  options: { value: any; label: string }[],
  enumType: SocialSummaryFields
) => {
  const option = options.find(
    (option) => option.value === enumType[val as keyof typeof enumType]
  )
  return option ? option.label : null
}

/**
 *
 */
export default function () {
  const route = useRoute()
  const { data: socialSummary } = storeToRefs(useSocialSummariesApi())
  const { patient } = storeToRefs(usePatientStore())

  const showEditModal = ref<boolean>(false)
  const showOffBoardingSummaryEdit = ref<boolean>(false)

  const patientSocialSummary = computed(() =>
    socialSummary.value?.length ? socialSummary.value[0] : null
  )

  const hasOffboardingSummary = computed(() => {
    return (
      patientSocialSummary.value?.reasonOffboarding?.length ??
      patientSocialSummary.value?.reengagementAttemptsDescription?.length
    )
  })

  const patientId = computed(() => patient.value?.entityId ?? null)

  /**
   * fetch last updated values in care plan section
   */
  async function fetchSocialSummary() {
    await useSocialSummariesApi().list({
      params: {
        filter_patient_ids: [route.params.patientId as string],
      },
    })
  }

  onMounted(fetchSocialSummary)

  const benefitsProgramsServicesDisplay = computed(() => {
    if (patientSocialSummary.value?.benefitsProgramsServices?.length) {
      return patientSocialSummary.value.benefitsProgramsServices
        .map((str) =>
          getLabels(
            str,
            benefitsProgramsServicesOptions,
            BenefitsProgramsServices
          )
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })
  const caregiverMappingDisplay = computed(() => {
    if (patientSocialSummary.value?.caregiverMapping?.length) {
      return patientSocialSummary.value.caregiverMapping
        .map((str) => getLabels(str, caregiverMappingOptions, CaregiverMapping))
        .sort()
        .join(' • ')
    }
    return '-'
  })
  const financialBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.financialBarrier?.length) {
      return patientSocialSummary.value.financialBarrier
        .map((str) => getLabels(str, financialBarrierOptions, FinancialBarrier))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const foodBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.foodBarrier?.length) {
      return patientSocialSummary.value.foodBarrier
        .map((str) => getLabels(str, foodBarrierOptions, FoodBarrier))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const isHealthcareProxyOrPOA = computed(() => {
    if (patientSocialSummary.value?.healthcareProxy === true) {
      return 'Yes'
    } else if (patientSocialSummary.value?.healthcareProxy === false) {
      return 'No'
    }
    return '-'
  })

  const housingBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.housingBarrier?.length) {
      return patientSocialSummary.value.housingBarrier
        .map((str) => getLabels(str, housingBarrierOptions, HousingBarrier))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const inHomeSupportDisplay = computed(() => {
    if (patientSocialSummary.value?.inHomeSupport?.length) {
      return patientSocialSummary.value.inHomeSupport
        .map((str) => getLabels(str, inHomeSupportOptions, InHomeSupport))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const socialSupportDisplay = computed(() => {
    if (patientSocialSummary.value?.socialSupport?.length) {
      return patientSocialSummary.value.socialSupport
        .map((str) => getLabels(str, socialSupportOptions, SocialSupport))
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const transportationBarrierDisplay = computed(() => {
    if (patientSocialSummary.value?.transportationBarrier?.length) {
      return patientSocialSummary.value.transportationBarrier
        .map((str) =>
          getLabels(str, transportationBarrierOptions, TransportationBarrier)
        )
        .sort()
        .join(' • ')
    }
    return '-'
  })

  const additionalContextDisplay = computed(() => {
    if (patientSocialSummary.value?.additionalContext?.length) {
      return patientSocialSummary.value.additionalContext
    }
    return '-'
  })

  /**
   * open/close edit modal
   */
  function toggleSocialSummaryEdit() {
    showEditModal.value = !showEditModal.value
  }
  /**
   * open/close edit modal
   */
  function toggleOffboardingSummaryEdit() {
    showOffBoardingSummaryEdit.value = !showOffBoardingSummaryEdit.value
  }

  return {
    fetchSocialSummary,
    patientId,
    patientSocialSummary,
    hasOffboardingSummary,
    // display values
    benefitsProgramsServicesDisplay,
    caregiverMappingDisplay,
    financialBarrierDisplay,
    foodBarrierDisplay,
    isHealthcareProxyOrPOA,
    housingBarrierDisplay,
    inHomeSupportDisplay,
    socialSupportDisplay,
    transportationBarrierDisplay,
    additionalContextDisplay,
    // social summary edit modal
    toggleSocialSummaryEdit,
    showEditModal,
    // offboarding summary
    showOffBoardingSummaryEdit,
    toggleOffboardingSummaryEdit,
  }
}
