import { camelCase, startCase } from 'lodash'
import { computed, ref } from 'vue'
import { useGoalStore } from '@/legacy/store/modules/goals'

/**
 *
 * @param props
 */
export default function (props: any) {
  const showEditGoalModal = ref(false)
  const showCompletedTasks = ref(false)

  const formattedDomain = computed(() =>
    props.goal.domain ? startCase(camelCase(props.goal.domain.domain)) : 'N/A'
  )

  /**
   *
   */
  function toggleEditGoalModal() {
    showEditGoalModal.value = !showEditGoalModal.value
  }

  /**
   *refetch goals after updating
   */
  async function refetchGoal() {
    await useGoalStore().getGoals({
      filter_member_ids: [props.goal.memberId],
    })
  }

  return {
    toggleEditGoalModal,
    showEditGoalModal,
    formattedDomain,
    refetchGoal,
    showCompletedTasks,
  }
}
