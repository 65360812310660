<template>
  <div v-if="shouldShowLinks" class="pt-2 my-2 space-y-1.5">
    <div v-for="linkObj in patientLinks" :key="linkObj.url">
      <a :href="linkObj.url" target="_blank">
        <TMTertiaryButton
          :label="linkObj.label"
          icon-pos="right"
          icon="heroicons:arrow-top-right-on-square"
        />
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import { patientExternalLinksProps } from './types'
export default defineComponent({
  components: {
    TMTertiaryButton,
  },
  props: patientExternalLinksProps,
  setup,
})
</script>
