import { requiredInject } from '@thyme/libs/src/vue/inject'
import { computed, ref, Ref } from 'vue'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

// Defined only for convenience and use in testing.
export type PhysicalFunctionAssessmentViewModel = {
  unableToPerformTheFollowingIADLsDisplay: Ref<string>
  unableToPerformTheFollowingADLsDisplay: Ref<string>
  homeboundDisplay: Ref<string>
  dmeDisplay: Ref<string>
  proceduralSupportDisplay: Ref<string>
  walksTwoBlocksOrOneFlightOfStairsDisplay: Ref<string>
  additionalInformationDisplay: Ref<string>
  showEditModal: Ref<boolean>
  togglePhysicalFunctionAssessmentEdit: () => void
  patientId: Ref<string>
}

/**
 *
 *
 */
export function setup(): PhysicalFunctionAssessmentViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)

  const unableToPerformTheFollowingIADLsDisplay = computed(() => {
    return '-'
  })

  const unableToPerformTheFollowingADLsDisplay = computed(() => {
    return '-'
  })

  const homeboundDisplay = computed(() => {
    return '-'
  })

  const dmeDisplay = computed(() => {
    return '-'
  })

  const proceduralSupportDisplay = computed(() => {
    return '-'
  })

  const walksTwoBlocksOrOneFlightOfStairsDisplay = computed(() => {
    return '-'
  })

  const additionalInformationDisplay = computed(() => {
    return '-'
  })

  /**
   * open/close edit modal
   */
  function togglePhysicalFunctionAssessmentEdit() {
    showEditModal.value = !showEditModal.value
  }

  return {
    patientId,
    // display values
    unableToPerformTheFollowingIADLsDisplay,
    unableToPerformTheFollowingADLsDisplay,
    homeboundDisplay,
    dmeDisplay,
    proceduralSupportDisplay,
    walksTwoBlocksOrOneFlightOfStairsDisplay,
    additionalInformationDisplay,
    // fall risk edit modal
    togglePhysicalFunctionAssessmentEdit,
    showEditModal,
  }
}
