import capitalize from 'lodash/capitalize'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { getOpenPointerSubtasks } from '@/legacy/components/texting/lib/sharedTextingParts'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePathwayApi } from '@/legacy/store/modules/pathways'
import { useContactsApi, usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { useOpenTextingSubtaskApi } from '@/legacy/store/modules/textingV2'
import { PhoneNumberType } from '@/legacy/types/entities/phoneNumbers'

/**
 *
 */
export default function () {
  const { phoneNumbers, contacts } = storeToRefs(usePatientStore())
  const { showHistoricSymptomId } = storeToRefs(useFlagStore())
  const { data: openPointerSubtasks } = storeToRefs(useOpenTextingSubtaskApi())

  const route = useRoute()
  const queryData = computed(() => route.query)
  const showTextingInbox = computed(() =>
    getValFromQuery(queryData.value, 'type')
  )

  const activeTab = ref(showTextingInbox.value?.length ? 'texting' : 'open')

  // A map of Thymeline tab names to their respective components
  const tabMap = computed((): { [key: string]: string } => ({
    open: 'OpenThymeline',
    history: 'HistoryThymeline',
    // Disabling Outcomes tab for now, not currently being used by Care Team
    // outcomes: 'OutcomesThymeline',
    documents: 'DocumentsThymeline',
    // Only show texting tab if there are phone numbers associated with the patient
    // and/or contact
    ...(hasTextablePhoneNumber.value ? { texting: 'TextingThymeline' } : {}),
    // Only show Symptoms tab if feature flag is enabled
    ...(showHistoricSymptomId.value ? { symptoms: 'SymptomsThymeline' } : {}),
  }))

  const activeTabComponent = computed(() => tabMap.value[activeTab.value])

  const updateActiveTab = (newTab: string) =>
    tabMap.value[newTab] && newTab !== activeTab.value
      ? (activeTab.value = newTab)
      : null

  const tabName = (tab: string) => capitalize(tab)

  const { isLoaded: patientLoaded } = storeToRefs(usePatientsApi())
  const { isLoaded: contactsLoaded } = storeToRefs(useContactsApi())
  const { isLoaded: mapEntityPhoneNumberLoaded } = storeToRefs(
    useMapEntityPhoneNumberApi()
  )
  const { isLoaded: pathwayLoaded } = storeToRefs(usePathwayApi())

  const openPointerSubtasksNum = computed(() => {
    if (openPointerSubtasks.value) {
      return Object.values(openPointerSubtasks.value).length
    }
    return 0
  })

  const hasTextablePhoneNumber = computed(() => {
    const patientHasPhoneNumbers =
      Object.values(phoneNumbers.value ?? {}).length &&
      Object.values(phoneNumbers.value ?? {}).some(
        (phoneNumber) =>
          phoneNumber.phoneNumber.type !== PhoneNumberType.LANDLINE
      )
    const contactsHavePhoneNumbers =
      Object.values(contacts.value ?? {}).length &&
      Object.values(contacts.value ?? {}).some(
        (contact) =>
          contact.contactEntity.phoneNumbers?.length &&
          contact.contactEntity.phoneNumbers[0].phoneNumber.type !==
            PhoneNumberType.LANDLINE &&
          contact.approvedToSpeak
      )
    return patientHasPhoneNumbers || contactsHavePhoneNumbers
  })

  onMounted(async () => {
    await getOpenPointerSubtasks(route.params.patientId as string)
  })

  /**
   * texting badge should only show when ff is on and if there are open pointer subtasks
   * @param tab
   */
  function showTextingBadge(tab: string) {
    return tab === 'texting'
  }
  const dataHasLoaded = computed(() => {
    if (showTextingInbox.value?.length) {
      return (
        patientLoaded.value &&
        contactsLoaded.value &&
        mapEntityPhoneNumberLoaded.value
      )
    }
    return pathwayLoaded.value
  })

  watch(showTextingInbox, () => {
    if (showTextingInbox.value?.length) {
      activeTab.value = 'texting'
    }
  })

  return {
    activeTab,
    activeTabComponent,
    dataHasLoaded,
    tabMap,
    tabName,
    updateActiveTab,
    showTextingBadge,
    showTextingInbox,
    openPointerSubtasksNum,
  }
}
