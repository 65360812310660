import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { startCase, camelCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { usePinnedAcuityApi } from '@/legacy/store/modules/acuity'
import { useDomainApi, useDomainStore } from '@/legacy/store/modules/domains'
import { useGoalApi } from '@/legacy/store/modules/goals'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { AcuityThreshold, TierLevel } from '@/legacy/types/acuity'
import { NotificationType } from '@/legacy/types/notifications'
import { GoalStatus } from '@/legacy/types/pathways/goals'
import { DomainsEnum } from '@/legacy/types/patients/domains'
import {
  IntentMap,
  Patient,
  StatusMap,
  programStatusOptions,
  treatmentIntentOptions,
  treatmentStatusOptions,
} from '@/legacy/types/patients/patients'

// Helper functions to update tier level
export const tierOptions = Object.values(TierLevel).map((level) => ({
  label: level,
  value: level,
}))

export const domainDropdownOptions = Object.values(DomainsEnum)
  .map((title) => {
    return { label: startCase(camelCase(title)), value: title }
  })
  .sort((a, b) => a.label.localeCompare(b.label))

/**
 * get acuity score for selected tier level
 * @param tierLevel
 */
export function getOverallTierScore(tierLevel: string) {
  if (!tierLevel.length) {
    return 0
  }
  return tierLevel === TierLevel.ESSENTIAL
    ? 1
    : tierLevel === TierLevel.STANDARD
    ? /* eslint-disable @typescript-eslint/restrict-plus-operands*/
      AcuityThreshold.Medium + 1
    : AcuityThreshold.High + 1
}

/**
 * create pinned acuity score
 * @param tierLevel
 * @param patientId
 */
async function createPinnedScore(tierLevel: string, patientId: string) {
  await usePinnedAcuityApi().create({
    body: {
      overallScore: getOverallTierScore(tierLevel),
      patientId: patientId,
    },
  })
}

/**
 * update existing pinned acuity score
 * @param tierLevel
 * @param patientId
 * @param pinnedAcuityScoreId
 */
async function updatePinnedScore(
  tierLevel: string,
  patientId: string,
  pinnedAcuityScoreId: string
) {
  await usePinnedAcuityApi().partialUpdate({
    ids: [pinnedAcuityScoreId],
    body: {
      patientId: patientId,
      overallScore: getOverallTierScore(tierLevel),
    },
  })
}

/**
 *
 * @param patientId
 * @param domains
 */
async function updateDomains(patientId: string, domains: string[]) {
  try {
    await useDomainStore().upsertDomain(patientId, domains)
  } catch (err) {
    console.log(err)
    useNotificationStore().setNotification({
      message: 'Failed to update domain.',
      type: NotificationType.DANGER,
    })
  }
  await useDomainStore().getDomains({ filter_patient_ids: [patientId] })
}

// Setup -------------

/**
 *@param props
 * @param context
 */
export default function (props: any, context: any) {
  const { patient } = storeToRefs(usePatientStore())
  const { data: domains } = storeToRefs(useDomainApi())
  const { data: goals } = storeToRefs(useGoalApi())
  const { canEditPatientAcuityScore, canEditDomains } = storeToRefs(
    useProfileStore()
  )
  const currentTierLevel = computed(() =>
    patient.value?.pinnedAcuityScore
      ? formatTierScore(patient.value.pinnedAcuityScore.overallScore)
      : ''
  )
  const currentTreatmentIntent = ref(patient.value?.treatmentIntent)
  const currentDomains = computed(() => {
    return domains.value
      ? Object.values(domains.value).map((domain) => domain.domain)
      : []
  })

  const dirtyDomains = ref(currentDomains.value)
  const dirtyTier = ref<TierLevel | string>(currentTierLevel.value)
  const dirtyTreatmentStatus = ref<Partial<Patient>>({
    treatmentStatus: patient.value?.treatmentStatus,
    treatmentSubstatus: patient.value?.treatmentSubstatus,
  })
  const dirtyTreatmentIntent = ref<Partial<Patient>>({
    treatmentIntent: currentTreatmentIntent.value,
  })
  const dirtyProgramStatus = ref<Partial<Patient>>({
    programStatus: patient.value?.programStatus,
    programSubstatus: patient.value?.programSubstatus,
  })

  /**
   * update program or treatment status and treatment intent
   * @param changes
   */
  async function updatePatient(changes: Partial<Patient>) {
    if (!patient.value) {
      return
    }
    try {
      await usePatientsApi().partialUpdate({
        ids: [patient.value.entityId],
        body: changes,
        metaOptions: { bubbleErrorThrow: true },
      })
      void usePatientStore().patientApiCall(patient.value.entityId)
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update status.',
        type: NotificationType.DANGER,
      })
      return
    }
  }

  /**
   * set new program / treatment status values
   * @param fieldPrefix
   * @param val
   */
  async function handleStatusInput(fieldPrefix: string, val: StatusMap) {
    const newValues = {
      [`${fieldPrefix}Status`]: val.status,
      [`${fieldPrefix}Substatus`]: val.substatus,
    }
    if (fieldPrefix === 'treatment') {
      dirtyTreatmentStatus.value = newValues
    } else if (fieldPrefix === 'program') {
      dirtyProgramStatus.value = newValues
    }
  }

  /**
   * set new TreatmentIntent value
   * @param val
   */
  async function handleIntentInput(val: IntentMap) {
    dirtyTreatmentIntent.value.treatmentIntent = val.key
  }

  /**
   * update existing pinned acuity or create new one
   * @param tierLevel
   */
  async function updateOrCreatePinnedScore(tierLevel: string) {
    if (!patient.value) {
      return
    }
    const tier = tierLevel ?? ''
    try {
      if (patient.value?.pinnedAcuityScore) {
        await updatePinnedScore(
          tier,
          patient.value.entityId,
          patient.value.pinnedAcuityScore.pinnedAcuityScoreId
        )
      } else {
        await createPinnedScore(tier, patient.value.entityId)
      }
    } catch (err) {
      console.log(err)
      useNotificationStore().setNotification({
        message: 'Failed to update tier.',
        type: NotificationType.DANGER,
      })
      return
    }
    patient.value.pinnedAcuityScore = usePinnedAcuityApi().datum
  }

  /**
   * reset dirty values to current values for non-domain dropdowns
   */
  function resetNonDomainDirtyValues() {
    dirtyTier.value = currentTierLevel.value
    dirtyTreatmentStatus.value = {
      treatmentStatus: patient.value?.treatmentStatus,
      treatmentSubstatus: patient.value?.treatmentSubstatus,
    }
    dirtyProgramStatus.value = {
      programStatus: patient.value?.programStatus,
      programSubstatus: patient.value?.programSubstatus,
    }
    dirtyTreatmentIntent.value.treatmentIntent = currentTreatmentIntent.value
  }

  /**
   *
   */
  function verifyNonDomainChanges() {
    const promises: Promise<void>[] = []
    // check for dropdown values that have changed so
    // that we only make necessary updates
    if (currentTierLevel.value !== dirtyTier.value) {
      promises.push(updateOrCreatePinnedScore(dirtyTier.value))
    }
    if (dirtyTreatmentIntent.value !== currentTreatmentIntent.value) {
      promises.push(
        updatePatient({
          ...dirtyTreatmentIntent.value,
        })
      )
    }
    const shouldUpdateTreatmentStatus =
      dirtyTreatmentStatus.value.treatmentStatus !==
        patient.value?.treatmentStatus ||
      dirtyTreatmentStatus.value.treatmentSubstatus !==
        patient.value?.treatmentSubstatus
    const shouldUpdateProgramStatus =
      dirtyProgramStatus.value.programStatus !== patient.value?.programStatus ||
      dirtyProgramStatus.value.programSubstatus !==
        patient.value?.programSubstatus

    if (shouldUpdateTreatmentStatus) {
      promises.push(
        updatePatient({
          ...dirtyTreatmentStatus.value,
        })
      )
    }
    if (shouldUpdateProgramStatus) {
      promises.push(
        updatePatient({
          ...dirtyProgramStatus.value,
        })
      )
    }
    return promises
  }

  /**
   *
   * verify if domain changes are valid and update
   */
  async function verifyAndUpdateDomainChanges() {
    if (currentDomains.value !== dirtyDomains.value) {
      const removedDomains = currentDomains.value.filter(
        (d) => dirtyDomains.value.indexOf(d) === -1
      )
      const domainsToBeRemovedIsMapped = removedDomains.filter((d) =>
        checkForMappedDomains(d)
      )
      if (domainsToBeRemovedIsMapped.length) {
        useNotificationStore().setNotification({
          message: `Cannot remove domain(s) that are associated with active goals: ${domainsToBeRemovedIsMapped}`,
          type: NotificationType.DANGER,
        })
        return
      } else if (patient.value && dirtyDomains.value) {
        await updateDomains(patient.value.entityId, dirtyDomains.value)
      }
    }
    return
  }

  /**
   * function to check if domain is already mapped to a goal
   * @param domainStr
   */
  function checkForMappedDomains(domainStr: string) {
    const currentMappedDomainIds = Object.values(goals.value ?? {})
      .filter((goal) => goal.status === GoalStatus.ACTIVE)
      .map((goal) => goal.domainId)
    const domainId = Object.values(domains.value ?? {}).find(
      (d) => d.domain === domainStr
    )?.domainId
    return currentMappedDomainIds.some(
      (goalDomainId) => goalDomainId === domainId
    )
  }

  /**
   * reset dirty values and close modal
   */
  function close() {
    resetNonDomainDirtyValues()
    context.emit('close')
  }

  /**
   * make updates and close modal
   * first updates non-domain values, then domain values
   */
  async function save() {
    const nonDomainUpdates = verifyNonDomainChanges()
    try {
      await Promise.all(nonDomainUpdates)
    } catch (err) {
      console.log(err)
      return
    }
    useNotificationStore().setNotification({
      message: 'Successfully updated care plan.',
      type: NotificationType.SUCCESS,
    })
    resetNonDomainDirtyValues()

    try {
      await verifyAndUpdateDomainChanges()
    } catch (err) {
      console.log(err)
      return
    }
    // refetch values for last updated section under care plans
    thymeDispatch('care-planV2-update')
    close()
  }

  return {
    resetNonDomainDirtyValues,
    currentTierLevel,
    patient,
    ModalSize,
    ButtonSize,
    canEditPatientAcuityScore,
    canEditDomains,
    updateOrCreatePinnedScore,
    // modal values for dropdowns
    dirtyTreatmentStatus,
    dirtyTreatmentIntent,
    dirtyProgramStatus,
    dirtyTier,
    dirtyDomains,
    // dropdown options
    tierOptions,
    treatmentStatusOptions,
    treatmentIntentOptions,
    programStatusOptions,
    domainDropdownOptions,
    // update dropdowns
    handleStatusInput,
    handleIntentInput,
    // button actions
    close,
    save,
  }
}
