import { computed, Ref } from 'vue'

export type TOCViewModel = {
  admissionDate: Ref<string>
  dischargeDate: Ref<string>
  eventType: Ref<string>
  electiveAdmission: Ref<string>
  dischargeDiagnosis: Ref<string>
  dischargeLocation: Ref<string>
  newDMEOnDischarge: Ref<string>
  servicesOnDischarge: Ref<string>
  readmissionRisk: Ref<string>
  moreThanThreeAdmissionsInSixMonths: Ref<string>
  summaryOfEvents: Ref<string>
}

/**
 *
 */
export function setup(): TOCViewModel {
  const admissionDate = computed(() => {
    return '-'
  })

  const dischargeDate = computed(() => {
    return '-'
  })

  const eventType = computed(() => {
    return '-'
  })

  const electiveAdmission = computed(() => {
    return '-'
  })

  const dischargeDiagnosis = computed(() => {
    return '-'
  })

  const dischargeLocation = computed(() => {
    return '-'
  })

  const newDMEOnDischarge = computed(() => {
    return '-'
  })

  const servicesOnDischarge = computed(() => {
    return '-'
  })

  const readmissionRisk = computed(() => {
    return '-'
  })

  const moreThanThreeAdmissionsInSixMonths = computed(() => {
    return '-'
  })

  const summaryOfEvents = computed(() => {
    return '-'
  })

  return {
    admissionDate,
    dischargeDate,
    eventType,
    electiveAdmission,
    dischargeDiagnosis,
    dischargeLocation,
    newDMEOnDischarge,
    servicesOnDischarge,
    readmissionRisk,
    moreThanThreeAdmissionsInSixMonths,
    summaryOfEvents,
  }
}
