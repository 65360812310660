<template>
  <div
    :data-cy="name && `${name}-thymeline-tr`"
    class="inline-flex flex-col w-full"
  >
    <div class="flex gap-2">
      <TIcon
        class="pl-1"
        :icon="iconDisplay.icon"
        :color="iconDisplay.color ?? ''"
      />
      <div
        data-cy="call-type-and-person-display"
        class="space-x-1 max-w-6/12 cursor-pointer"
        @click="clickCommunication"
      >
        <div v-if="isCall">
          <b>
            {{ callTypeDisplay }}
            {{
              communication?.callDisposition?.direction ===
              CallDirection.inbound
                ? callerDisplay
                : calleeDisplay
            }}
          </b>
        </div>
        <div v-else-if="isEmail">
          <b>Email</b>
        </div>
        <div v-else-if="isText">
          <b v-if="isPlannedText">Scheduled Automated Text </b>
          <b v-else>Text Conversation </b>
          <b v-if="texteeDisplay">{{ texteeDisplay }}</b>
        </div>
        <div v-else-if="isOther">
          <b>Other communication type </b>
          <b v-if="texteeDisplay">{{ texteeDisplay }}</b>
        </div>
        <div v-else-if="isFax">
          <b>Fax</b>
        </div>
      </div>
    </div>
    <div class="flex gap-2 text-sm text-nash-neutral700">
      <div class="w-6"></div>
      <div>{{ displayDate }}</div>
      <div class="bullet">
        {{ displayTime }}
      </div>
      <div class="bullet">{{ commOwner }}</div>
      <div v-if="phoneNumberDisplay" class="bullet">
        {{ phoneNumberDisplay }}
      </div>
      <div v-if="communication?.callDisposition?.disposition" class="bullet">
        {{
          callDispositionDisplayLookup[
            communication.callDisposition.disposition
          ] ?? ''
        }}
      </div>
    </div>

    <div class="inline-flex flex-col justify-between w-full pl-5">
      <TTruncate data-cy="call-notes" class="text-sm ml-2" :label="note" />
      <div class="ml-2">
        <TTruncate
          v-for="subtask in linkedSubtasks"
          :key="subtask?.subtaskId"
          class="border-l-2 border-nash-neutral400 block m-0 pl-2 text-sm"
          :label="subtask.title"
        >
        </TTruncate>
      </div>
    </div>
    <div v-if="isText" class="inline-flex flex-col justify-between w-full pl-5">
      <ThymelineMessagesView
        v-if="
          convos?.length && messages?.length && communication.smsThread?.people
        "
        :messages="messages"
        :current-phone-number-str="currCommPhoneNumberStr"
        :sms-thread-people="communication.smsThread?.people"
      />

      <div
        v-else-if="messagesError || convoError"
        class="text-sm inline-flex flex-col justify-between w-full pl-2 mt-2 text-nash-tigerlily800"
      >
        <div v-if="messagesError">
          {{ messagesError }}
        </div>
        <div v-if="convoError">
          {{ convoError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent, PropType } from 'vue'
import ThymelineMessagesView from '@/legacy/components/patient/thymeline/ThymelineMessagesView/ThymelineMessagesView.vue'
import TTruncate from '@/legacy/nashville/text/TTruncate.vue'
import { Communication } from '@/legacy/types/communications/communications'
import setup from './controller'

export default defineComponent({
  components: {
    ThymelineMessagesView,
    TTruncate,
    TIcon,
  },
  props: {
    communication: {
      type: Object as PropType<Communication>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup,
})
</script>

<style lang="scss" scoped>
.bullet:before {
  @apply text-nash-neutral700 mr-2;
  content: '•';
}
</style>
