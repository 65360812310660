import { DateTime } from 'luxon'
import { lookupEnum } from '@/legacy/libs/enum'
import { onlyDigits } from '@/legacy/libs/format'
import { useAddressesApi } from '@/legacy/store/modules/addresses'
import { useEntitiesApi } from '@/legacy/store/modules/entity'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useContactsApi, usePatientStore } from '@/legacy/store/modules/patient'
import { usePersonApi } from '@/legacy/store/modules/person'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { IdMap } from '@/legacy/types/api/store'
import { Gender, Person } from '@/legacy/types/entities/people'
import { NULL_STRING } from '@/legacy/types/global/strings'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Contact,
  ContactsFormData,
  NewRelationshipToPatient,
} from '@/legacy/types/patients/contacts'
import { NormalizedPatient } from '@/legacy/types/patients/patients'

/**
 * Function to help display patient relationship from enum
 * @param relationshipToPatient
 */
export function displayRelationship(
  relationshipToPatient: string | null | undefined
) {
  if (!relationshipToPatient) {
    return 'Unknown Relationship'
  }
  return lookupEnum(
    NewRelationshipToPatient,
    relationshipToPatient.toLowerCase()
  )
}

/**
 * Function to help grab person object from contact nested objs
 * @param contact
 */
export function contactPersonObj(contact: Contact): Person | undefined {
  return contact.contactEntity.person
}

/**
 *
 * Function to return matched contact from patient store
 * @param contactEntityId
 * @param contactsMap
 */
export function getContactFromStore(
  contactEntityId: string | null,
  contactsMap: IdMap<Contact>
) {
  const contactsList = Object.values(contactsMap ?? {})
  if (contactsList.length && contactEntityId) {
    return contactsList.find(
      (contact: Contact) => contact.contactEntityId === contactEntityId
    )
  }
  return undefined
}

/**
 *
 * @param data
 * @param patient
 * @param contacts
 * @param editingId
 */
export async function upsertContactData(
  data: ContactsFormData,
  patient: NormalizedPatient | null,
  contacts: IdMap<Contact> | null,
  editingId: string | null
) {
  let person, existingEntity, existingEntityId
  if (!patient) return
  if (contacts && editingId) {
    existingEntity = contacts[editingId]
    existingEntityId = existingEntity?.contactEntityId
  }
  try {
    const personBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      preferredLanguage: data.preferredLanguage,
      // extra info
      gender: Gender.Unknown,

      // overrides (transform form data)
      ...(data.dateOfBirth
        ? {
            dateOfBirth: DateTime.fromJSDate(data.dateOfBirth).toISODate(),
          }
        : {}),
    }

    if (existingEntityId) {
      await usePersonApi().partialUpdate({
        ids: [existingEntityId],
        body: personBody,
      })
      person = usePersonApi().datum
      if (!person) {
        throw new Error('Person failed to update')
      }
      usePatientStore().updatePerson(person)
    } else {
      const entity = await useEntitiesApi().create({
        body: { person: personBody },
      })
      person = entity.person
    }

    const contactBody = {
      approvedToSpeak: data.approvedToSpeak === 'true',
      relationshipToPatient: data.relationshipToPatient,
      approvedToAccessMedInfo: data.approvedToAccessMedInfo,
      contactEntityId: person.entityId,
      ...(data.isPrimaryCaregiver != null
        ? {
            isPrimaryCaregiver: data.isPrimaryCaregiver === 'true',
          }
        : {}),
      ...(data.caregiverPoa != null
        ? {
            caregiverPoa: data.caregiverPoa === 'true',
          }
        : {}),
    }

    // toAwait is an array of async functions to execute in a Promise.all()
    // as updating contact information requires updating different sets of
    // endpoints
    const toAwait: Promise<any>[] = [
      editingId
        ? useContactsApi().partialUpdate({
            body: contactBody,
            ids: [editingId],
          })
        : useContactsApi().create({
            body: {
              ...contactBody,
              patientId: patient.entityId,
              contactEntityId: person.entityId,
            },
          }),
    ]
    const existingAddress = existingEntity?.contactEntity.addresses?.length
      ? existingEntity.contactEntity.addresses[0]
      : null
    const addressData = {
      streetAddressLine1: data.streetAddressLine1,
      streetAddressLine2: data.streetAddressLine2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      type: 'home',
    }

    if (existingAddress) {
      const addressId = existingAddress.addressId
      if (!addressData.streetAddressLine1) {
        // Delete any existing addresses for the entity.
        toAwait.push(useAddressesApi().delete({ ids: [addressId] }))
      } else {
        toAwait.push(
          useAddressesApi().partialUpdate({
            ids: [addressId],
            body: addressData,
          })
        )
      }
    } else if (addressData.streetAddressLine1) {
      toAwait.push(
        useAddressesApi().create({
          body: { ...addressData, entityId: person.entityId },
        })
      )
    }

    const existingPhoneNumber = existingEntity?.contactEntity.phoneNumbers
      ?.length
      ? existingEntity.contactEntity.phoneNumbers[0]
      : null
    // Check if there were any phone number changes.
    const oldStatus =
      existingPhoneNumber?.phoneNumber.messagingOptInStatus?.toString() ??
      NULL_STRING
    const newStatus =
      data.messagingOptInStatus === null
        ? NULL_STRING
        : data.messagingOptInStatus.toString()
    if (
      //compare string versions because it returns false when old val = null and new val = false
      //null indicates waiting for response/unknown
      oldStatus !== newStatus ||
      (existingPhoneNumber?.phoneNumber.phoneNumber ?? '') !== data.phoneNumber
    ) {
      if (!data.phoneNumber && existingPhoneNumber) {
        toAwait.push(
          useMapEntityPhoneNumberApi().delete({
            ids: [existingPhoneNumber.mapEntityPhoneNumberId],
          })
        )
      } else {
        toAwait.push(
          Promise.resolve(
            useMapEntityPhoneNumberApi().upsert({
              body: {
                entityId: person.entityId,
                phoneNumberId: existingPhoneNumber?.phoneNumberId,
                isPrimary: true,
                phoneNumber: {
                  phoneNumber: onlyDigits(data.phoneNumber),
                  countryCode: '1',
                  messagingOptInStatus: data.messagingOptInStatus,
                },
              },
            })
          )
        )
      }
    }

    await Promise.all(toAwait)
    void usePatientStore().fetchContacts(patient.entityId)
  } catch (err) {
    console.error(err)
    useNotificationStore().setNotification({
      message: 'Failed to create/update contact.',
      type: NotificationType.DANGER,
    })
    return
  }

  useNotificationStore().setNotification({
    message: 'Successfully created/updated contact.',
    type: NotificationType.SUCCESS,
  })
}
