<template>
  <div v-if="subtask">
    <div class="bg-nash-neutral000 p-5 h-full column overflow-y-auto">
      <div class="flex justify-between items-center content-center">
        <TBreadcrumb :home="home" :model="items" />
        <LegacyTButton
          name="close-pathway-sidebar"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="closeSidebar"
        />
      </div>
      <h2>{{ subtask.title }}</h2>
      <SubtaskDetail
        :key="subtask?.subtaskId"
        class="mt-1.5"
        :subtask="subtask"
        @callback-fn="fetchSubtask"
      />
      <SubtaskStacks />
    </div>
  </div>
</template>

<script lang="ts">
import TBreadcrumb from '@thyme/nashville/src/components/breadcrumb/TBreadcrumb.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SubtaskDetail from '@/legacy/components/patient/pathways/SubtaskDetail.vue'
import SubtaskStacks from '@/legacy/components/patient/pathways/SubtaskStacks.vue'

import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

import { usePathwayApi } from '@/legacy/store/modules/pathways'
import {
  useSubtasksStore,
  useSubtaskTemplatesByIdApi,
} from '@/legacy/store/modules/subtasks'

import { useTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { Subtask } from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  components: { SubtaskStacks, LegacyTButton, SubtaskDetail, TBreadcrumb },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const subtask = ref<Subtask | null>(null)

    const { data: subtaskTemplateById } = storeToRefs(
      useSubtaskTemplatesByIdApi()
    )

    const querySubtaskId = computed(() => `${route.query.subtaskId ?? ''}`)

    const { data: tasks } = storeToRefs(useTaskApi())
    const task = computed(() =>
      subtask.value && tasks.value
        ? tasks.value[subtask.value?.taskIds[0]]
        : null
    )

    const { data: pathways } = storeToRefs(usePathwayApi())
    const pathway = computed(() =>
      task.value && pathways.value
        ? pathways.value[task.value.pathwayIds[0]]
        : null
    )

    const closeSidebar = () => {
      const template =
        subtaskTemplateById.value && subtask.value
          ? subtaskTemplateById.value[subtask.value?.subtaskVariantId]
          : null
      const hasOpenFormsortForms = template
        ? !!template.staffCompletableForms?.length
        : false
      if (
        !hasOpenFormsortForms ||
        window.confirm(
          'There is a form in this subtask. Complete it before closing the subtask, or your changes will be lost!'
        )
      ) {
        void router.push({ query: { subtaskId: undefined } })
      }
    }

    // Ensure updates from thymeline register updates in this component
    watch(tasks, async () => await fetchSubtask())

    /**
     * Function to refetch subtask by ID to reset subtask ref and reset thymelines on update
     */
    async function fetchSubtask() {
      const results = await useSubtasksStore().getSubtasks({
        filter_patient_ids: [route.params.patientId as string],
        filter_subtask_ids: [querySubtaskId.value],
      })
      subtask.value = results.data[0]
    }

    watch(querySubtaskId, async () => {
      if (querySubtaskId.value) {
        void fetchSubtask()
      }
    })
    onMounted(async () => {
      if (!subtask.value) {
        await fetchSubtask()
      }
      if (subtask.value) {
        if (!task.value) {
          await useTasksStore().getTasks({
            filter_member_ids: [route.params.patientId as string],
            filter_task_ids: subtask.value.taskIds,
          })
        }
        void useSubtasksStore().getSubtaskTemplatesById({
          filter_variant_ids: [subtask.value.subtaskVariantId],
        })
      }
    })

    const home = computed(() => ({
      label: pathway.value?.title,
      to: { path: route.path, query: { pathwayId: pathway.value?.pathwayId } },
    }))
    const items = computed(() => [
      {
        label: task.value?.title,
        to: {
          path: route.path,
          query: {
            pathwayId: pathway.value?.pathwayId,
            taskId: task.value?.taskId,
            subtaskId: subtask.value?.subtaskId,
          },
        },
      },
      { label: null, to: { path: '' } },
    ])

    return {
      fetchSubtask,
      closeSidebar,
      subtask,
      home,
      items,
      ButtonSize,
    }
  },
})
</script>
