import {
  useCallDispositionApi,
  refetchCommsThymeline,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { DirtyCallDisposition } from '@/legacy/types/communications/callDispositions'
import { NotificationType } from '@/legacy/types/notifications'
import { retrieveCommunicationById } from './communications'
import { getCurrentStaffId } from './sharedCommunicationParts'

/**
 *
 * Function to get a singular call disposition by comm ID using apiBuilder().retrieve().
 * Returns fetched call disposition.
 * @param id
 */
export async function getDisposition(id: string | null | undefined) {
  if (!id) {
    console.error('Cannot get call disposition without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot get call disposition without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    const res = await useCallDispositionApi().retrieve({
      ids: [id],
      metaOptions: { bubbleErrorThrow: true },
    })
    return res
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Error fetching call disposition for communication: ${id}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to create call disposition using apiBuilder().create().
 * Returns created call disposition.
 *
 * Fetches communication to attach communication parts.
 * Refetches thymeline to ensure created call disposition comm is adeded.
 * @param id
 * @param payload
 */
export async function createDisposition(
  id: string | null | undefined,
  payload?: Partial<DirtyCallDisposition>
) {
  if (!id) {
    console.error('Cannot create call disposition without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot create call disposition without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }
  const currentStaffId = getCurrentStaffId()
  if (!currentStaffId) {
    console.error('Cannot create call disposition without a staff ID.')
    useNotificationStore().setNotification({
      message:
        'Cannot create call disposition without a staff ID. Please try logging out and back in to Thymebox',
      type: NotificationType.DANGER,
    })
    return
  }
  try {
    const res = await useCallDispositionApi().create({
      body: {
        completedByStaffId: currentStaffId,
        isIdentityVerified: false,
        speakingWithPersonId: null,
        speakingWithType: null,
        ...(payload ?? {}),
        communicationId: id,
      },
      metaOptions: { bubbleErrorThrow: true },
    })

    // retrieve all communication parts for communication
    // to have call disposition part in comms store
    if (res) {
      await retrieveCommunicationById(res.communicationId)
    }

    refetchCommsThymeline()
    return res
  } catch (error) {
    console.error('Error creating call disposition: ', {
      completedByStaffId: currentStaffId,
      isIdentityVerified: false,
      speakingWithPersonId: null,
      speakingWithType: null,
      ...(payload ?? {}),
      communicationId: id,
    })
    useNotificationStore().setNotification({
      message: `Error creating call disposition for communication: ${id}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to update call disposition using apiBuilder().partialUpdate().
 * Returns updated call disposition.
 *
 * Fetches communication to attach communication parts.
 * Refetches thymeline to ensure created call disposition communication is updated.
 * @param id
 * @param payload
 */
export async function updateDisposition(
  id: string | null | undefined,
  payload: Partial<DirtyCallDisposition>
) {
  if (!id) {
    console.error('Cannot update call disposition without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot update without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    const res = await useCallDispositionApi().partialUpdate({
      ids: [id],
      body: payload,
      metaOptions: { bubbleErrorThrow: true },
    })

    if (res) {
      // retrieve all communication parts for communication
      // to have call disposition part in comms store
      await retrieveCommunicationById(res.communicationId)

      refetchCommsThymeline()
      return res
    }
  } catch (error) {
    console.error('Error updating call disposition: ', {
      id,
      payload,
    })
    useNotificationStore().setNotification({
      message: `Error fetching updating call disposition: ${id}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to delete call disposition using apiBuilder().delete().
 * Refetches thymeline to ensure deleted call disposition communication is removed.
 * Sets the communicationType to null in communications store.
 * @param id
 */
export async function deleteCallDisposition(id: string | null) {
  if (!id) {
    console.error('Cannot delete call disposition without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot delete call disposition without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    await useCallDispositionApi().delete({
      ids: [id],
      metaOptions: { bubbleErrorThrow: true },
    })
    refetchCommsThymeline()
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Failed to delete call disposition for communication: ${id}`,
      type: NotificationType.DANGER,
    })
    return
  }
}
