<template>
  <label v-if="label" class="font-bold">{{ label }}</label>
  <TDropdown
    v-model="value"
    v-bind="$attrs"
    :class="{ 'p-invalid': errorMessage }"
    :data-testid="testId"
  >
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
  </TDropdown>
  <small v-if="errorMessage" class="p-error text-red-500 text-sm">{{
    errorMessage
  }}</small>
</template>

<script lang="ts">
import { kebabCase } from 'lodash'
import { useField } from 'vee-validate'
import { defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'

export default defineComponent({
  components: {
    TDropdown,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Array, Object],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const { value, errorMessage } = useField(props.name, undefined, {
      syncVModel: true,
    })
    const testId = props.name ? `${kebabCase(props.name)}-dropdown` : undefined
    return { value, errorMessage, testId }
  },
})
</script>
