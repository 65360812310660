<template>
  <label
    v-if="label"
    v-tooltip.top="tooltip"
    :for="inputId"
    class="input-label"
    data-testid="input-label"
    >{{ label }}</label
  >
  <small v-if="help" :id="`${inputId}-help`" data-testid="input-help">{{
    help
  }}</small>
  <TIcon v-if="icon" :icon="icon" class="input-icon" data-testid="input-icon" />
  <InputText
    :id="inputId"
    v-model="inputValue"
    :name="inputId"
    :class="dynamicClasses"
    :invalid="isInvalid"
    :max-length="maxLength + 1"
    data-testid="input-actual"
    v-bind="$attrs"
    @update:model-value="updateValue"
    @blur="emitBlur"
    @keyup="$emit('keyup', $event)"
  />
  <p
    v-for="error in errors"
    :key="error"
    class="error-display"
    data-testid="input-error"
  >
    {{ error }}
  </p>
</template>

<script lang="ts">
import TIcon from '@thyme/nashville/src/components/icon/TIcon.vue'
import InputText from 'primevue/inputtext'
import { defineComponent } from 'vue'
import { setup } from './controller'
import { T_INPUT_TEXT_PROPS } from './types'

export default defineComponent({
  components: {
    TIcon,
    InputText,
  },
  props: T_INPUT_TEXT_PROPS,
  emits: ['blur', 'keyup', 'update:modelValue'],
  setup,
})
</script>
<style>
.second-border:focus {
  box-shadow: 0 0 0 2px #e7c5da;
}

input::placeholder {
  @apply text-nash-neutral700;
}

.error-display {
  @apply text-nash-brick600;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  margin-left: 0.25rem;
}

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
}

.input-label {
  @apply text-nash-purple600;
  /* @TailwindCSS block class applies margins around the display which we do not want for this instance */
  display: block;
}

.icon-spacer {
  @apply pl-10;
}
</style>
