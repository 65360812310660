<template>
  <div :data-cy="name && `${name}-thymeline-tr`" class="inline-flex w-full">
    <div class="font-medium max-w-xs pr-2">{{ outcomeCreatedAt }}</div>
    <a
      class="text-nash-purple500 hover:text-nash-purple500 space-x-4 max-w-6/12 w-full"
      @click="setOutcomeModalVisibility(true)"
    >
      <b>{{ category }}</b>
      <span class="text-nash-purple500">
        {{ separateAndTruncate([note, quotes], 75) }}</span
      >
    </a>
    <LegacyTButton
      name="outcomeTask"
      type="tertiary"
      class="border border-nash-purple500 px-2 py-1 rounded-xl whitespace-nowrap"
      @click.prevent="selectTask"
    >
      <span class="text-xs">{{ separateAndTruncate([taskTitle], 30) }}</span>
    </LegacyTButton>
    <PatientOutcomesModal
      v-if="outcomeModalOpen && possibleOutcomes && outcomes && note && quotes"
      :possible-outcomes="possibleOutcomes"
      :current-task-id="outcomeTask?.taskId"
      :current-outcomes="outcomes"
      :current-outcome-note="note"
      :current-outcome-quotes="quotes"
      @click.stop
      @hide-modal="setOutcomeModalVisibility(false)"
      @refetch-outcomes-thymeline="refetchOutcomesThymeline"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PatientOutcomesModal from '@/legacy/components/patient/PatientOutcomesModal.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime, separateAndTruncate } from '@/legacy/libs/format'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import { useTaskOutcomesApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { Outcome, OutcomeReference } from '@/legacy/types/outcomes'
import { Task } from '@/legacy/types/pathways/tasks'

export default defineComponent({
  components: {
    PatientOutcomesModal,
    LegacyTButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    taskOutcome: {
      type: Object as PropType<Outcome>,
      required: true,
    },
  },
  emits: ['refetchOutcomesThymeline'],
  setup(props, context) {
    const outcomeModalOpen = ref(false)
    const router = useRouter()
    const route = useRoute()
    const patientId = computed(() => `${route.params.patientId ?? ''}`)
    const outcomeTask = ref<Task | null>(null)
    const possibleOutcomes = ref<OutcomeReference[] | null>(null)
    const outcomes = ref<Outcome[] | null>(null)

    /**
     * Function to get all possible outcomes for task
     * @param taskTemplateId
     */
    async function getPossibleOutcomes(taskTemplateId: null | string = null) {
      const taskTemplate = await useTasksStore().getAllTaskTemplates({
        ...(taskTemplateId ? { filter_variant_ids: [taskTemplateId] } : {}),
      })
      possibleOutcomes.value = taskTemplate.data[0].possibleOutcomes
    }

    /**
     * Function to retrieve all task's outcomes
     */
    async function getTaskOutcomes() {
      const taskOutcomes = await useTaskOutcomesApi().list({
        params: {
          filter_task_ids: [props.taskOutcome.taskId],
          filter_member_ids: [route.params.patientId],
          parts: ['outcome_validation'],
        },
      })
      outcomes.value = taskOutcomes.data
    }

    /**
     * Helper function to retrieve an outcome's parent task to retrieve notes/quotes
     */
    async function getOutcomeTask() {
      const results = await useTasksStore().getTasks({
        filter_member_ids: [patientId.value],
        filter_task_ids: [props.taskOutcome.taskId],
      })
      outcomeTask.value = results?.data[0]
    }

    /**
     * Function to fetch all required data (outcome task, possible outcomes and task outcomes)
     */
    async function fetchAllData() {
      await getOutcomeTask()
      if (outcomeTask.value) {
        await getPossibleOutcomes(outcomeTask.value.taskVariantId)
      }
      void getTaskOutcomes()
    }

    onMounted(fetchAllData)

    // NOTE: Don't use toRefs for this, it will break reactivity
    // by only setting the INITIAL value and not tracking value changes
    const note = computed(() => outcomeTask.value?.outcomeNote)
    const quotes = computed(() => outcomeTask.value?.outcomeQuotes)
    const outcomeCreatedAt = computed(() =>
      formatDateTime(stringToDateTime(props.taskOutcome.createdAt))
    )
    const outcomeRefId = computed(() => props.taskOutcome.outcomeRefId)
    const taskTitle = computed(() => outcomeTask.value?.title)

    const category = computed(() => {
      const outcome = possibleOutcomes.value?.find(
        (possibleOutcome) => possibleOutcome.outcomeRefId === outcomeRefId.value
      )
      if (outcome) {
        return outcome.title
      }
      return '<Unknown Outcome>'
    })

    const setOutcomeModalVisibility = (isVisible: boolean) =>
      (outcomeModalOpen.value = isVisible)

    watch(props, fetchAllData)

    /**
     * Callback function to refetch outcomes thymeline post update
     */
    async function refetchOutcomesThymeline() {
      context.emit('refetchOutcomesThymeline')
    }

    const selectTask = () => {
      void router.push({
        query: {
          taskId: props.taskOutcome.taskId,
          pathwayId: outcomeTask.value?.pathwayIds[0],
        },
      })
    }

    return {
      possibleOutcomes,
      outcomes,
      refetchOutcomesThymeline,
      outcomeTask,
      taskTitle,
      category,
      outcomeCreatedAt,
      note,
      quotes,
      outcomeModalOpen,
      selectTask,
      setOutcomeModalVisibility,
      separateAndTruncate,
    }
  },
})
</script>
