<template>
  <div class="relative">
    <TMSecondaryButton
      class="add-comms-btn"
      :disabled="isCreating || !!queryCommId"
      label="+ Communication"
      name="add-communication"
      @click="createCommunication"
    />
    <div class="flex space-x-4">
      <TDropdown
        :options="freeTextOptions"
        :initial-value="freeTextFilterName"
        :show-clear="false"
        class="bg-nash-neutral000 h-1/4 mt-5"
        option-label="label"
        option-value="value"
        @update:model-value="setFreeTextFilterName"
      />
      <TSearchAndFilter
        search-placeholder="Search"
        class="mt-5 w-1/3"
        @update-search="updateSearch"
      />
    </div>
    <div class="space-y-4">
      <!-- In Progress thymeline -->
      <TMThymeline
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-InProgressThymeline`"
        title="In Progress"
        :rows="perPage"
        :columns="columns"
        :params="inProgressParams"
        :api-store="useInProgressThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="isOverdue(item.item)"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          name="communications"
        />
      </TMThymeline>

      <!-- Open thymeline -->
      <TMThymeline
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-OpenThymeline`"
        title="Open"
        :rows="perPage"
        :columns="columns"
        :params="openParams"
        :api-store="useOpenThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="isOverdue(item.item)"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          name="communications"
        />
      </TMThymeline>

      <!-- Blocked tasks thymeline -->
      <LegacyTButton
        type="tertiary"
        class="pt-2"
        icon-position="right"
        :icon="showBlocked ? 'chevronUp' : 'chevronDown'"
        @click="showBlocked = !showBlocked"
        >{{ showBlocked ? 'Hide Blocked' : 'Show Blocked' }}</LegacyTButton
      >
      <TMThymeline
        v-if="showBlocked"
        v-slot="{ item, getData }"
        class="tasks-thymeline"
        :name="`${patientId}-BlockedThymeline`"
        title="Blocked/On Hold"
        :rows="perPage"
        :columns="columns"
        :params="blockedParams"
        :api-store="useBlockedThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="false"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          name="communications"
        />
      </TMThymeline>
      <SubtaskReattemptModal
        v-if="selectedReattemptSubtask"
        :subtask="selectedReattemptSubtask"
        :is-open="showReattemptModal"
        @close="closeReattemptModal"
      />
      <SubtaskSubstatusModal
        v-if="
          showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
        "
        :subtask="selectedSubStatusSubtask"
        :status="subtaskStatus"
        :is-open="showSubStatusModal"
        @close="showSubStatusModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'

import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import CommunicationsRow from '@/legacy/components/patient/thymeline/CommunicationsRow/CommunicationsRow.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'

import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useOpenThymelineApi,
  useInProgressThymelineApi,
  useBlockedThymelineApi,
} from '@/legacy/store/modules/thymelines'

import {
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'
import {
  ThymelineItemType,
  ThymelineStatus,
} from '@/legacy/types/patients/thymelines'
import {
  columns,
  perPage,
  overloadSearchServiceList,
} from './lib/sharedThymelineParts'

// CONST
const DEBOUNCE_INTERVAL_MS = 300

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TMThymeline,
    SubtaskReattemptModal,
    LegacyTButton,
    SubtaskRow,
    CommunicationsRow,
    TMSecondaryButton,
    TSearchAndFilter,
    TDropdown,
  },
  setup() {
    overloadSearchServiceList(useOpenThymelineApi())
    overloadSearchServiceList(useInProgressThymelineApi())
    overloadSearchServiceList(useBlockedThymelineApi())

    const route = useRoute()

    const showBlocked = ref(false)

    const showReattemptModal = ref(false)
    const selectedReattemptSubtask = ref<Subtask | null>(null)
    const showSubStatusModal = ref(false)
    const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
    const selectedSubStatusSubtask = ref<Subtask | null>(null)
    const subtaskStatus = ref<SubtaskStatus | null>(null)

    const openParams: { [key: string]: any } = computed(() => {
      return {
        patient_id: route.params.patientId as string,
        filter_status: ThymelineStatus.OPEN,
        filter_free_text_title: '',
        filter_free_text_notes: '',
      }
    })

    const inProgressParams: { [key: string]: any } = computed(() => {
      return {
        patient_id: route.params.patientId as string,
        filter_status: ThymelineStatus.IN_PROGRESS,
        filter_free_text_title: '',
        filter_free_text_notes: '',
      }
    })

    const blockedParams: { [key: string]: any } = computed(() => {
      return {
        patient_id: route.params.patientId as string,
        filter_status: ThymelineStatus.BLOCKED,
        filter_free_text_title: '',
        filter_free_text_notes: '',
      }
    })

    /**
     * Trigger close reattempt modal
     */
    function closeReattemptModal() {
      showReattemptModal.value = false
    }
    /**
     * Trigger open reattempt modal
     * @param subtask
     */
    function openReattemptModal(subtask: Subtask) {
      showReattemptModal.value = true
      selectedReattemptSubtask.value = subtask
    }

    /**
     * Function to show/open SubStatusModal modal
     * @param subtask
     * @param status
     */
    function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
      showSubStatusModal.value = true
      subtaskStatus.value = status
      selectedSubStatusSubtask.value = subtask
    }

    /**
     * Validate if subtask is past due, compared to current datetime
     * @param root0 Subtask
     * @param root0.dueDatetime string
     */
    function isOverdue({ dueDatetime }: Subtask) {
      return stringToDateTime(dueDatetime) < DateTime.now()
    }

    /** Add Communication Button Code */

    const communicationsStore = useCommunicationsStore()

    const isCreating = computed(() => communicationsStore.isCreating)
    const queryCommId = computed(() => `${route.query.commId ?? ''}`)

    /**
     *
     */
    function createCommunication() {
      void communicationsStore.setIsCreating()
    }

    /** End Add Communication Button Code */

    /** Search Filter Code */

    const currentSearch = ref('')
    const freeTextFilterName = ref('filter_free_text_title')
    const freeTextOptions = [
      { label: 'Subtask Title', value: 'filter_free_text_title' },
      { label: 'Notes', value: 'filter_free_text_notes' },
    ]

    const updateSearch = (newSearch: string) =>
      (currentSearch.value = newSearch ?? '')

    const debouncedSearch = debounce(() => {
      thymeDispatch('thymeline-update')
    }, DEBOUNCE_INTERVAL_MS)

    /**
     *
     */
    function setFilterValues() {
      openParams.value[freeTextFilterName.value] = currentSearch.value
      inProgressParams.value[freeTextFilterName.value] = currentSearch.value
      blockedParams.value[freeTextFilterName.value] = currentSearch.value
    }

    const setFreeTextFilterName = (val: string) => {
      currentSearch.value = ''
      setFilterValues()
      freeTextFilterName.value = val
    }

    watch(currentSearch, async () => {
      setFilterValues()
      debouncedSearch()
    })

    /** End Search Filter Code */

    return {
      selectedReattemptSubtask,
      showReattemptModal,
      openReattemptModal,
      closeReattemptModal,
      showSurfacedStatusReason,
      subtaskStatus,
      showSubStatusModal,
      selectedSubStatusSubtask,
      openSubStatusModal,
      patientId: route.params.patientId as string,
      perPage,
      columns,
      showBlocked,
      isOverdue,

      ThymelineItemType,
      SubtaskRowDisplayType,

      useOpenThymelineApi,
      useInProgressThymelineApi,
      useBlockedThymelineApi,

      openParams,
      inProgressParams,
      blockedParams,

      isCreating,
      queryCommId,
      createCommunication,

      setFreeTextFilterName,
      updateSearch,
      currentSearch,
      freeTextOptions,
      freeTextFilterName,
    }
  },
})
</script>

<style lang="scss" scoped>
.add-comms-btn {
  position: absolute;
  top: -40px;
  right: 0;
}
</style>
