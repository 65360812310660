import { computed, Ref } from 'vue'

// Defined only for convenience and use in testing.
export type PatientTreatmentViewModel = {
  treatmentDisplay: Ref<string>
  surgeryDisplay: Ref<string>
  radiationDisplay: Ref<string>
}

/**
 *
 *
 */
export function setup(): PatientTreatmentViewModel {
  const treatmentDisplay = computed(() => {
    return '-'
  })

  const surgeryDisplay = computed(() => {
    return '-'
  })

  const radiationDisplay = computed(() => {
    return '-'
  })

  return {
    // display values
    treatmentDisplay,
    surgeryDisplay,
    radiationDisplay,
  }
}
