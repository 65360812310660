<template>
  <div class="overflow-visible px-5 card-style w-full">
    <div class="flex justify-end section-bottom-border">
      <div v-if="!showNewClinicalSummary" class="flex">
        <div>
          <div v-tooltip.left="'Edit care plan'">
            <TMSecondaryButton
              v-if="!isEditMode"
              icon="pencil"
              class="w-10 h-10 ml-3 p-4"
              @click="editMode"
            />
          </div>
        </div>
        <div v-tooltip.left="'Finish editing'">
          <TMSecondaryButton
            v-if="isEditMode"
            icon="check"
            class="w-9 h-10 ml-3 p-4"
            @click="save"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!showNewClinicalSummary"
      class="flex flex-row grid grid-cols-3 gap-4 section-bottom-border"
    >
      <div>
        <label>Onboarding Completed</label>
        <div>{{ onboardingCompleted }}</div>
      </div>
      <div>
        <label>Last GVP Conversation</label>
        <div v-if="lastGvp">
          <a :href="buildSubtaskUrl(route.path, lastGvp)">
            {{ formatSubtaskDate(lastGvp) }}
          </a>
        </div>
        <div v-else>N/A</div>
      </div>
      <div>
        <label>Last Case Conference</label>
        <div v-if="lastCaseConferences && lastCaseConferences.length > 0">
          <div
            v-for="ccSubtask in lastCaseConferences"
            :key="ccSubtask.subtaskId"
          >
            <a :href="buildSubtaskUrl(route.path, ccSubtask)">{{
              formatSubtaskDate(ccSubtask)
            }}</a>
          </div>
        </div>
        <div v-else>N/A</div>
        <TMTertiaryButton
          v-if="
            lastCaseConferences &&
            lastCaseConferences.length > 0 &&
            !isLoadedCaseConf
          "
          label="See all..."
          @click="loadMore"
        />
      </div>
    </div>
    <div
      v-if="showSuggestedPlaybooks && suggestedPathways.length"
      class="section-bottom-border"
    >
      <h5 class="text-nash-neutral600 mb-3 font-bold">Suggested Playbooks</h5>
      <div class="grid grid-cols-2 gap-4 mt-5">
        <PatientTreatmentPlans
          v-for="item in suggestedPathways"
          :key="item.pathwayId"
          :pathway="item"
          :allow-status-update="true"
        />
      </div>
    </div>
    <ManageGoalsModal
      :goals="goalsArray"
      :show-manage-goals="openGoalModel"
      :all-patient-pathways="goalPathways"
      @close="closeGoalsModal"
      @refetch-goals="refetchGoals"
    />
    <div>
      <div v-if="carePlanSummary">
        <CaseConference
          :mode="derivedMode"
          :care-plan-summary="carePlanSummary"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import maxBy from 'lodash/maxBy'
import uniq from 'lodash/uniq'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ManageGoalsModal from '@/legacy/components/patient/carePlans/modals/ManageGoalsModal/ManageGoalsModal.vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { thymeListen, thymeDispatch } from '@/legacy/libs/eventBus'
import {
  formatDateTime,
  formatName,
  formatSubtaskDate,
  buildSubtaskUrl,
  formatDateTimeWithTime,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { mapToLongFormGender } from '@/legacy/libs/patients'
import { useCarePlanSummaryApi } from '@/legacy/store/modules/carePlanSummaries'
import {
  useCarePlanUpdateApi,
  useCarePlanUpdateStore,
} from '@/legacy/store/modules/carePlanUpdates'
import { useDiagnosisRefApi } from '@/legacy/store/modules/diagnosesReferences'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useGoalRefApi } from '@/legacy/store/modules/goalReference'
import { useGoalApi, useGoalStore } from '@/legacy/store/modules/goals'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  isEvergreenPathway,
  usePathwayApi,
} from '@/legacy/store/modules/pathways'
import {
  useDiagnosesApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import {
  useLastCaseConferencesSubtaskApi,
  useLastGvpSubtaskApi,
} from '@/legacy/store/modules/subtasks'
import { Gender } from '@/legacy/types/entities/people'
import { NotificationType } from '@/legacy/types/notifications'
import { Goal, GoalStatus } from '@/legacy/types/pathways/goals'
import { Pathway, PathwayStatus } from '@/legacy/types/pathways/pathways'
import {
  Subtask,
  SubtaskStatus,
  caseConferenceSubtaskKeys,
  gvpSubtaskKeys,
} from '@/legacy/types/pathways/subtasks'
import {
  CarePlanSummary,
  CaseConferenceMode,
} from '@/legacy/types/patients/carePlanSummaries'
import { Diagnosis, Stage } from '@/legacy/types/patients/diagnoses'
import {
  IntentMap,
  StatusMap,
  programStatusOptions,
  treatmentIntentOptions,
  treatmentStatusOptions,
  Patient,
} from '@/legacy/types/patients/patients'
import CaseConference from './CaseConference.vue'

// CONST
const CASE_CONFERENCE_SUBTASKS_PAGE_LENGTH = 5

export default defineComponent({
  components: {
    PatientTreatmentPlans,
    TMSecondaryButton,
    TMTertiaryButton,
    ManageGoalsModal,
    CaseConference,
  },
  setup() {
    const { patient, person } = storeToRefs(usePatientStore())
    const { canEditPatientTreatmentStatus } = storeToRefs(useProfileStore())
    const {
      showSuggestedPlaybooks,
      showClinicalSummary: showNewClinicalSummary,
    } = storeToRefs(useFlagStore())
    const { data: goals } = storeToRefs(useGoalApi())
    const { data: pathways } = storeToRefs(usePathwayApi())
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())
    const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
    const { data: lastCaseConferences } = storeToRefs(
      useLastCaseConferencesSubtaskApi()
    )
    const { datum: carePlanUpdate } = storeToRefs(useCarePlanUpdateApi())

    const router = useRouter()

    const isEditMode = ref(false)

    const openGoalModel = ref(false)
    const route = useRoute()
    const carePlanSummary = ref<CarePlanSummary | null>(null)
    const lastGvp = ref<Subtask | null>(null)
    const isLoadedCaseConf = ref(false)

    onMounted(async () => {
      await useCarePlanUpdateStore().getCarePlanUpdate({
        filter_patient_ids: [route.params.patientId as string],
      })
      await useGoalRefApi().listAll({})
      await useGoalStore().getGoals({
        filter_member_ids: [route.params.patientId as string],
      })
      carePlanSummary.value = await fetchPatientCarePlanSummary()
      lastGvp.value = await fetchLastGvpSubtask(gvpSubtaskKeys)
      await fetchCaseConferenceSubtasks()
    })

    const derivedMode = computed(() =>
      isEditMode.value
        ? CaseConferenceMode.EDIT
        : CaseConferenceMode.INTERNAL_VIEW
    )
    const diagnosesArray = computed(() => Object.values(diagnoses.value ?? {}))
    const goalsArray = computed(() => Object.values(goals.value ?? {}))
    const pathwayArray = computed(() => Object.values(pathways.value ?? {}))
    const ongoingPathway = computed(() => {
      return pathwayArray.value.filter((pathway: Pathway) => {
        if (
          !pathway.goalIds.length &&
          pathway.status === PathwayStatus.ACTIVE
        ) {
          return pathway
        }
        return
      })
    })
    const suggestedPathways = computed(() => {
      return pathwayArray.value.filter((pathway: Pathway) => {
        return pathway.status === PathwayStatus.SUGGESTED
      })
    })

    const goalPathways = computed(() => {
      return pathwayArray.value.filter((pathway: Pathway) => {
        if (
          !isEvergreenPathway(pathway.title) &&
          pathway.status === PathwayStatus.ACTIVE
        ) {
          return pathway
        }
        return
      })
    })
    const sortedGoals = computed(() => {
      return goalsArray.value
        .filter((goal: Goal) => goal.status === GoalStatus.ACTIVE)
        .sort((a, b) => a.sortNumber - b.sortNumber)
    })

    const recentlyUpdated = computed(() => {
      const mostRecent = maxBy(goalsArray.value, 'updatedAt')
      if (mostRecent) {
        return formatDateTime(stringToDateTime(mostRecent.updatedAt))
      }
      return ''
    })

    const age = computed(() => {
      const dobDatetime = stringToDateTime(person.value?.dateOfBirth)

      if (dobDatetime) {
        return Math.floor(-dobDatetime.diffNow('years').years)
      }
      return 'n/a'
    })

    const primaryDiagnoses = computed(() => {
      return diagnosesArray.value.filter(
        (dx: Diagnosis) =>
          (dx.isPrimary && !dx.isInaccurate) ||
          (dx.isPrimary === null && !dx.isInaccurate)
      )
    })

    const secondaryDiagnoses = computed(() => {
      return diagnosesArray.value.filter(
        (dx: Diagnosis) => dx.isPrimary === false && !dx.isInaccurate
      )
    })

    const onboardingCompleted = computed(() => {
      return patient.value?.inCareTimestamp
        ? formatDateTime(
            stringToDateTime(patient.value?.inCareTimestamp.toString())
          )
        : 'N/A'
    })

    /**
     *
     * @param val
     */
    async function handleIntentInput(val: IntentMap) {
      await updatePatient({ treatmentIntent: val.key })
    }

    /**
     *
     * @param fieldPrefix
     * @param val
     */
    async function handleStatusInput(fieldPrefix: string, val: StatusMap) {
      const newValues = {
        [`${fieldPrefix}Status`]: val.status,
        [`${fieldPrefix}Substatus`]: val.substatus,
      }
      await updatePatient(newValues)
    }

    /**
     *
     * @param changes
     */
    async function updatePatient(changes: Partial<Patient>) {
      if (!patient.value) {
        return
      }

      try {
        await usePatientsApi().partialUpdate({
          ids: [patient.value.entityId],
          body: changes,
          metaOptions: { bubbleErrorThrow: true },
        })
        void usePatientStore().patientApiCall(patient.value.entityId)
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to update status.',
          type: NotificationType.DANGER,
        })
        return
      }
      useNotificationStore().setNotification({
        message: 'Successfully updated status.',
        type: NotificationType.SUCCESS,
      })
    }

    /**
     * Function for fetching patient's Care Plan Summary
     */
    async function fetchPatientCarePlanSummary() {
      const summary = await useCarePlanSummaryApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          page_length: 1,
        },
      })

      // Each patient only has one summary
      if (summary?.data[0]) {
        return summary?.data[0]
      }

      const newSummary = {
        patientId: route.params.patientId as string,
      }

      // refetch values for last updated section under care plans
      // when a a new summary is created
      thymeDispatch('care-plan-update')

      return await useCarePlanSummaryApi().create({
        body: newSummary,
      })
    }

    /**
     * Fetch most recent subtask for a given key or set of keys
     * @param subtaskKeys list of keys to filter by Subtask.subtaskKey
     */
    async function fetchLastGvpSubtask(subtaskKeys: string[]) {
      const subtasks = await useLastGvpSubtaskApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          filter_subtask_keys: subtaskKeys,
          filter_subtask_status: [SubtaskStatus.COMPLETED],
          sort_by: 'statusUpdatedAt,desc',
          page_length: 1,
        },
      })

      if (subtasks && subtasks.data.length > 0) {
        return subtasks.data[0]
      }
      return ''
    }

    /**
     * Fetch all Case Conference subtasks and hold in store
     * @param page_length num results per page, default to 1
     */
    async function fetchCaseConferenceSubtasks(page_length = 1) {
      await useLastCaseConferencesSubtaskApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          filter_subtask_keys: caseConferenceSubtaskKeys,
          filter_subtask_status: [SubtaskStatus.COMPLETED],
          sort_by: 'statusUpdatedAt,desc',
          page_length,
        },
      })
    }

    // Fetch additional Case Conference tasks if available
    const loadMore = async () => {
      isLoadedCaseConf.value = true
      await fetchCaseConferenceSubtasks(CASE_CONFERENCE_SUBTASKS_PAGE_LENGTH)
    }

    /**
     * allow edit mode for care plans section
     * by changing isEditMode to true
     */
    function editMode() {
      isEditMode.value = true
    }

    /**
     * open manageGoalsModal
     */
    function openModel() {
      openGoalModel.value = true
    }

    /**
     * revert back to read only version,
     * refetch carePlanSummary
     */
    async function save() {
      isEditMode.value = false

      // Refetch summary
      carePlanSummary.value = await fetchPatientCarePlanSummary()
    }

    /**
     * close ManageGoalsModal
     */
    function closeGoalsModal() {
      openGoalModel.value = false
    }

    /**
     * refetch goals after updating/creating
     */
    async function refetchGoals() {
      await useGoalStore().getGoals({
        filter_member_ids: [route.params.patientId as string],
      })
    }

    /**
     * get pathways associated with a goal
     * @param goal
     */
    function getGoalPathways(goal: Goal) {
      const filtered_pathways = goalPathways.value.filter(
        (pathway: Pathway) =>
          pathway.goalIds.length && pathway.goalIds.includes(goal.goalId)
      )
      return filtered_pathways ?? []
    }

    /**
     * switch route to care plans pdf print out page
     */
    async function showPdf() {
      await router.push({
        name: '#patientCarePlanReport',
        params: { patientId: route.params.patientId },
      })
    }

    const getDiagnosisName = (diagnosisRefId: string) =>
      (
        safeLookup(diagnosisRefId, diagnosisCatalog.value)?.description ?? ''
      ).split(': ')[0]

    /**
     * Create string to display patient name, age, diagnoses
     */
    function patientSummary() {
      const patientName = formatName(
        person.value?.firstName,
        person.value?.lastName
      )
      const gender = person.value?.gender ?? Gender.Unknown
      const patientGender = mapToLongFormGender(gender)
      const readablePrimaryDiagnoses = uniq(
        primaryDiagnoses.value.map((d) =>
          lookupEnum(Stage, d.stage)
            ? `Stage ${lookupEnum(Stage, d.stage)} ${getDiagnosisName(
                d.diagnosisRefId
              )}`
            : `${getDiagnosisName(d.diagnosisRefId)}`
        )
      ).join(', ')

      // Strings based on optional fields
      const ageString = age.value ? `${age.value} year old` : ''
      const genderString = ![Gender.Unknown, Gender.PreferNotToAnswer].includes(
        gender
      )
        ? ` ${patientGender}`
        : ''
      const primaryDiagnosesString = readablePrimaryDiagnoses
        ? ` with ${readablePrimaryDiagnoses}`
        : ''

      const readableSecondaryDiagnoses = uniq(
        secondaryDiagnoses.value.map((d) =>
          lookupEnum(Stage, d.stage)
            ? `Stage ${lookupEnum(Stage, d.stage)} ${getDiagnosisName(
                d.diagnosisRefId
              )}`
            : `${getDiagnosisName(d.diagnosisRefId)}`
        )
      ).join(', ')
      const secondaryDiagnosesString = readableSecondaryDiagnoses
        ? ` complicated by ${readableSecondaryDiagnoses}`
        : ''
      const summary = `${patientName} is a ${ageString}${genderString}${primaryDiagnosesString}${secondaryDiagnosesString}.`

      return summary ?? ''
    }

    /**
     *
     * @param staffId
     */
    function getStaffName(staffId: string | undefined | null) {
      if (staffId) {
        return getStaffNameFromId(staffId, '') as string
      }
      return 'N/A'
    }

    /**
     *
     * @param timeStamp
     */
    function getFormattedDate(timeStamp: string | undefined | null) {
      if (timeStamp) {
        const formattedTimeStamp = formatDateTimeWithTime(
          stringToDateTime(timeStamp)
        )
        const [datePart] = formattedTimeStamp.split(', ')
        return datePart
      }
      return ''
    }

    /**
     * refetch last updated values in care plan section
     */
    async function updateLastUpdated() {
      await useCarePlanUpdateStore().getCarePlanUpdate({
        filter_patient_ids: [route.params.patientId as string],
      })
    }

    thymeListen('care-plan-update', updateLastUpdated)

    return {
      programStatusOptions,
      goalsArray,
      canEditPatientTreatmentStatus,
      recentlyUpdated,
      goals,
      patient,
      person,
      treatmentStatusOptions,
      treatmentIntentOptions,
      goalPathways,
      pathwayArray,
      sortedGoals,
      ongoingPathway,
      isEditMode,
      isLoadedCaseConf,
      openGoalModel,
      carePlanSummary,
      lastGvp,
      lastCaseConferences,
      onboardingCompleted,
      route,
      carePlanUpdate,
      getStaffName,
      showPdf,
      suggestedPathways,
      getGoalPathways,
      handleIntentInput,
      handleStatusInput,
      save,
      openModel,
      editMode,
      closeGoalsModal,
      refetchGoals,
      patientSummary,
      loadMore,
      buildSubtaskUrl,
      formatSubtaskDate,
      getFormattedDate,
      CaseConferenceMode,
      derivedMode,
      showSuggestedPlaybooks,
      showNewClinicalSummary,
    }
  },
})
</script>
<style lang="scss">
@media screen {
  .section-bottom-border {
    @apply border-b border-nash-neutral300 pb-3 mb-2;
  }
}
</style>
