<template>
  <div>
    <div class="relative flex py-5 items-center">
      <div v-if="formattedDomain">
        <LegacyTChip
          class="flex-shrink mr-2 bg-nash-midnightBlue700 text-white"
          :label="formattedDomain"
        />
      </div>
      <div class="flex-grow border-t border-nash-neutral500"></div>
    </div>
    <div class="flex justify-between items-center">
      <div class="flex font-medium w-full text-xl">
        <span class="italic mr-1">Goal: </span>
        <span>
          {{
            goal.freeTextTitle ??
            goal.goalReference?.titleDisplay ??
            'Please select goal title'
          }}
        </span>
      </div>
      <div v-tooltip.left="'Edit goal'">
        <TMSecondaryButton
          icon="pencil"
          class="w-10 h-10 ml-3 mb-3"
          @click="toggleEditGoalModal"
        />
      </div>
    </div>
    <div v-if="goal.details" class="mb-4 w-full whitespace-pre-wrap">
      {{ goal.details }}
    </div>
    <div v-if="goal.timeline">
      <label>Goal Timeline: </label>
      <span class="mb-4">{{ goal.timeline }}</span>
    </div>
    <div v-if="goalPathways && goalPathways.length">
      <span class="text-nash-neutral600 font-bold">Playbook(s)</span>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <PatientTreatmentPlans
          v-for="pathway in goalPathways"
          :key="pathway.pathwayId"
          class="ml-0"
          :pathway="pathway"
        />
      </div>
    </div>
    <div class="mt-4">
      <div v-if="goalTasks && goalTasks.length">
        <span class="text-nash-neutral600 font-bold">Task(s)</span>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <TaskChip
            v-for="task in goalTasks"
            :key="task.taskId"
            class="ml-0"
            name="goal-task"
            :task="task"
          />
        </div>
      </div>
      <TMTertiaryButton
        v-if="completedTasks.length"
        label="Show Completed"
        class="pt-2 mb-3"
        icon-position="right"
        :icon="showCompletedTasks ? 'chevronUp' : 'chevronDown'"
        @click="showCompletedTasks = !showCompletedTasks"
      />
      <div v-if="showCompletedTasks" class="grid grid-cols-2 gap-4 mt-4">
        <TaskChip
          v-for="task in completedTasks"
          :key="task.taskId"
          name="completed-goal-task"
          :task="task"
        />
      </div>
    </div>
    <EditGoalModal
      v-if="showEditGoalModal"
      :is-open="showEditGoalModal"
      :current-goal="goal"
      :goal-pathways="goalPathways"
      :goal-tasks="goalTasks"
      @close="toggleEditGoalModal"
      @refetch-goal="refetchGoal"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { PropType, defineComponent } from 'vue'
import EditGoalModal from '@/legacy/components/patient/carePlans/modals/EditGoalModal/EditGoalModal.vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import TaskChip from '@/legacy/components/patient/pathways/taskChip/TaskChip.vue'
import LegacyTChip from '@/legacy/nashville/LegacyTChip.vue'
import { Goal } from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'
import setup from './controller'
export default defineComponent({
  components: {
    TMSecondaryButton,
    LegacyTChip,
    PatientTreatmentPlans,
    TaskChip,
    EditGoalModal,
    TMTertiaryButton,
  },
  props: {
    goal: {
      type: Object as PropType<Goal>,
      required: true,
    },
    goalPathways: {
      type: Array as PropType<Pathway[]>,
      required: true,
    },
    goalTasks: {
      type: Array as PropType<Task[]>,
      required: true,
    },
    completedTasks: {
      type: Array as PropType<Task[]>,
      default: () => [],
    },
  },
  setup,
})
</script>
@/components/patient/carePlans/modals/EditGoalModal/EditGoalModal.vue
