import { defineStore } from 'pinia'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { IdMap } from '@/legacy/types/api/store'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import { Domain } from '@/legacy/types/patients/domains'

// CONST
const DOMAINS_PAGE_LENGTH = 10

export const useDomainStore = defineStore('domain', {
  state: () => ({}),
  getters: {},
  actions: {
    setSuccess(action: string) {
      useNotificationStore().setNotification({
        message: `Success ${action} domain`,
        type: NotificationType.SUCCESS,
      })
    },
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} domain`,
        type: NotificationType.DANGER,
        error: `Error ${action} domain: ${error}`,
      })
    },
    async getDomains({
      filter_domain_ids = null,
      filter_patient_ids = null,
      page_length = DOMAINS_PAGE_LENGTH,
    }: {
      filter_domain_ids?: string[] | null
      filter_patient_ids?: string[] | null
      filter_domains?: string[] | null
      page_length?: number | null
    }) {
      const domainApi = useDomainApi()
      const data = await domainApi.list({
        params: {
          ...(filter_patient_ids ? { filter_patient_ids } : {}),
          ...(filter_domain_ids ? { filter_domain_ids } : {}),
          page_length,
        },
      })
      if (domainApi.error) {
        this.setError(domainApi.error, 'fetching')
      }
      return data
    },
    async upsertDomain(patientId: string, domains: string[]) {
      const response = await useDomainApi().upsert({
        body: {
          patientId: patientId,
          domains: domains,
        },
      })
      const error = useDomainApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE)
        return
      }
      if (response) {
        this.setSuccess(NotificationActions.UPDATE)
      }
    },
  },
})

export const useDomainApi = apiStore<Domain, IdMap<Domain>>(
  'domainApi',
  '/api/domains',
  {
    transformData: (d: { data: Domain[] }) =>
      idMapTransform({}, 'data', 'domainId', d.data),
  }
)
