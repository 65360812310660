<template>
  <div class="overflow-visible card-style pt-4 w-full mb-5">
    <div class="grid-container">
      <div class="grid-item">
        <label>Admission Date</label>
      </div>
      <div class="grid-item">
        {{ admissionDate }}
      </div>
      <div class="grid-item">
        <label>Discharge Date</label>
      </div>
      <div class="grid-item">
        {{ dischargeDate }}
      </div>
      <div class="grid-item">
        <label>Event Type</label>
      </div>
      <div class="grid-item">
        {{ eventType }}
      </div>
      <div class="grid-item">
        <label>Elective Admission</label>
      </div>
      <div class="grid-item">
        {{ electiveAdmission }}
      </div>
      <div class="grid-item">
        <label>Discharge Diagnosis</label>
      </div>
      <div class="grid-item">
        {{ dischargeDiagnosis }}
      </div>
      <div class="grid-item">
        <label>Discharge Location</label>
      </div>
      <div class="grid-item">
        {{ dischargeLocation }}
      </div>
      <div class="grid-item">
        <label>New DME on Discharge</label>
      </div>
      <div class="grid-item">
        {{ newDMEOnDischarge }}
      </div>
      <div class="grid-item">
        <label>Services on Discharge</label>
      </div>
      <div class="grid-item">
        {{ servicesOnDischarge }}
      </div>
      <div class="grid-item">
        <label>Readmission Risk</label>
      </div>
      <div class="grid-item">
        {{ readmissionRisk }}
      </div>
      <div class="grid-item">
        <label>>3 Admissions in 6 Months</label>
      </div>
      <div class="grid-item">
        {{ moreThanThreeAdmissionsInSixMonths }}
      </div>
      <div class="grid-item">
        <label>Summary of Events</label>
      </div>
      <div class="grid-item">
        {{ summaryOfEvents }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  setup,
})
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
