<template>
  <TModal
    :is-visible="isOpen"
    :title="`Schedule a call with ${memberName}`"
    class="text-nash-neutral900"
    @close="close"
  >
    <TOStaffAssignDropdown
      name="ScheduledCommunicationAssign"
      :model-value="
        dirtyCommunication.responsibleStaffId ??
        dirtyCommunication.responsibleRole ??
        ''
      "
      class="w-full"
      @update="updateAssigned"
    />
    <TDropdown
      :model-value="dirtyPlannedCall.calleeEntityId"
      :options="callOptionEntities"
      option-label="label"
      option-value="value"
      input-id="contact-input"
      class="w-full"
      placeholder="Member/Contact"
      @update:model-value="dirtyPlannedCall.calleeEntityId = $event"
    />
    <ScheduleDueDatetimeDropdowns
      :due-datetime="dirtyPlannedCall.dueDatetime"
      @update="updateDateTime"
    />
    <template #actions>
      <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
      <TMPrimaryButton
        label="Confirm"
        name="done"
        :disabled="!hasValidData"
        @click="save"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { PropType, defineComponent } from 'vue'
import ScheduleDueDatetimeDropdowns from '@/legacy/components/patient/communicationV2/dropdowns/ScheduleDueDatetimeDropdowns/ScheduleDueDatetimeDropdowns.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { BaseCommunicationPayload } from '@/legacy/types/communications/communications'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TDropdown,
    TOStaffAssignDropdown,
    ScheduleDueDatetimeDropdowns,
  },
  props: {
    memberName: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    existingPlannedCall: {
      type: Object as PropType<DirtyPlannedCall>,
      default: () => ({}),
    },
    existingCommunication: {
      type: Object as PropType<BaseCommunicationPayload>,
      default: () => ({}),
    },
  },
  emits: ['close', 'updateFromModal'],
  setup,
})
</script>
