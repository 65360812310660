import { camelCase, startCase } from 'lodash'
import { computed } from 'vue'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  /**
   * fetch last updated values in care plan section
   */
  async function toggleOffboardingSummaryEdit() {
    context.emit('toggleOffboardingSummaryEdit')
  }

  const reasonOffboardingDisplay = computed(() => {
    if (props.patientSocialSummary?.reasonOffboarding) {
      return startCase(camelCase(props.patientSocialSummary?.reasonOffboarding))
    }
    return '-'
  })
  const reengagementAttemptsDescriptionDisplay = computed(() => {
    if (props.patientSocialSummary?.reengagementAttemptsDescription?.length) {
      return props.patientSocialSummary?.reengagementAttemptsDescription
    }
    return '-'
  })
  const additionalDetailsDisplay = computed(() => {
    if (props.patientSocialSummary?.offboardingAdditionalDetails?.length) {
      return props.patientSocialSummary?.offboardingAdditionalDetails
    }
    return '-'
  })

  return {
    // display values
    reasonOffboardingDisplay,
    reengagementAttemptsDescriptionDisplay,
    additionalDetailsDisplay,
    // offboarding summary edit
    toggleOffboardingSummaryEdit,
  }
}
