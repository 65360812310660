// Enums ----------------
export enum Anticoagulation {
  WARFARIN = 'WARFARIN',
  APIXABAN = 'APIXABAN',
  DABIGATRAN = 'DABIGATRAN',
  RIVAROXABAN = 'RIVAROXABAN',
  EDOXABAN = 'EDOXABAN',
  CLOPIDOGREL = 'CLOPIDOGREL',
  PRASUGREL = 'PRASUGREL',
  TICAGRELOR = 'TICAGRELOR',
  ASPIRIN = 'ASPIRIN',
  NSAIDS = 'NSAIDS',
  OTHER = 'OTHER',
}

export enum SedatingMedications {
  BENZODIAZEPINES = 'BENZODIAZEPINES',
  OPIOIDS = 'OPIOIDS',
  ANTICHOLINERGICS = 'ANTICHOLINERGICS',
  ANTIPSYCHOTICS = 'ANTIPSYCHOTICS',
  OTHER = 'OTHER',
}
