import { ModalSize } from '@thyme/nashville/src/types/modals'
import { camelCase, includes, isEqual, startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useDomainApi } from '@/legacy/store/modules/domains'
import { useGoalRefApi } from '@/legacy/store/modules/goalReference'
import { useGoalApi, useGoalStore } from '@/legacy/store/modules/goals'
import {
  isEvergreenPathway,
  usePathwayApi,
  usePathwaysStore,
} from '@/legacy/store/modules/pathways'
import { useGoalTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { Goal, GoalStatus } from '@/legacy/types/pathways/goals'
import {
  Pathway,
  PathwayPayload,
  TaskStatus,
} from '@/legacy/types/pathways/pathways'
import { Task, TaskPayload, TaskType } from '@/legacy/types/pathways/tasks'

export type GoalExtension = {
  playbooks: string[]
  tasks: string[]
} & Partial<Goal>

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { data: goalRefs } = storeToRefs(useGoalRefApi())
  const { data: domains } = storeToRefs(useDomainApi())
  const { data: pathways } = storeToRefs(usePathwayApi())
  const { data: tasks } = storeToRefs(useGoalTaskApi())
  const { data: patientGoals } = storeToRefs(useGoalApi())
  const pathwaysArray = computed(() => Object.values(pathways.value ?? {}))
  const goalRefsArray = computed(() => Object.values(goalRefs.value ?? {}))
  const showCreateNewEvergreenTasksModal = ref(false)
  const goalTaskIds = computed(() =>
    (props.goalTasks ?? []).map((task: Task) => task.taskId)
  )
  const goalPathwayIds = computed(() =>
    (props.goalPathways ?? []).map((pathway: Pathway) => pathway.pathwayId)
  )

  const OTHER_OPTION = 'Other'
  const dirtyModel = ref<GoalExtension>({
    goalRefId: props.currentGoal.goalRefId,
    freeTextTitle: props.currentGoal.freeTextTitle,
    domainId: props.currentGoal.domainId,
    details: props.currentGoal.details,
    timeline: props.currentGoal.timeline,
    tasks: goalTaskIds.value,
    playbooks: goalPathwayIds.value,
  })

  const goalRefOtherOption = computed(() => {
    const goalRef = goalRefsArray.value.filter(
      (ref) => ref.titleDisplay.toLowerCase() === OTHER_OPTION.toLowerCase()
    )
    return goalRef.length ? goalRef[0] : undefined
  })

  const showOtherTitle = computed(
    () =>
      !!goalRefOtherOption.value &&
      dirtyModel.value.goalRefId === goalRefOtherOption.value.goalRefId
  )

  /**
   *
   * @param v
   */
  function updateTitle(v: string) {
    dirtyModel.value.goalRefId = v
    if (goalRefOtherOption.value && v !== goalRefOtherOption.value.goalRefId) {
      dirtyModel.value.freeTextTitle = ''
    }
    return
  }

  const evergreenPathway = computed(() =>
    pathwaysArray.value.find((p) => p.title.toLowerCase() === 'evergreen')
  )
  /**
   *
   * show/hide create new evergreen tasks modal
   */
  function toggleCreateNewEvergreenTasksModal() {
    showCreateNewEvergreenTasksModal.value = true
  }

  const goalTitleOptions = computed(() => {
    // get already existing active goal titles for current patient
    const goalsTitles = Object.values(patientGoals.value ?? {})
      .filter(
        (goal) =>
          goal.status === GoalStatus.ACTIVE &&
          goal.goalId !== props.currentGoal.goalId
      )
      .map((goal: Goal) => goal.goalRefId)
    // remove existing titles from options and alphabetize remaining options
    // keep 'Other' option
    return goalRefsArray.value
      .filter(
        (goalRef) =>
          !goalsTitles.includes(goalRef.goalRefId) ||
          goalRef === goalRefOtherOption.value
      )
      .sort((a, b) => {
        const aTitle = a.titleDisplay
        const bTitle = b.titleDisplay
        return aTitle < bTitle ? -1 : aTitle > bTitle ? 1 : 0
      })
  })

  const domainOptions = computed(() =>
    Object.values(domains.value ?? {}).map((domain) => ({
      label: startCase(camelCase(domain.domain)),
      value: domain.domainId,
    }))
  )

  const playbookOptions = computed(() =>
    pathwaysArray.value.filter(
      (pathway) =>
        !isEvergreenPathway(pathway.title) &&
        pathway.status === 'ACTIVE' &&
        (!pathway.goalIds.length ||
          includes(pathway.goalIds, props.currentGoal.goalId))
    )
  )
  const taskOptions = computed(() =>
    Object.values(tasks.value ?? {}).filter(
      (task) =>
        task.status === TaskStatus.ACTIVE &&
        (!task.goalIds.length ||
          includes(task.goalIds, props.currentGoal.goalId))
    )
  )

  onMounted(async () => {
    await useGoalRefApi().listAll({})
  })

  // array of pathway update payloads to make pathway updates on save
  const playbookUpdatePayloads = ref<
    {
      pathwayId: string
      payload: Partial<PathwayPayload>
    }[]
  >([])

  /**
   * update pathway goal map
   * @param newVals
   */
  function setPlaybooksObj(newVals: string[]) {
    // update:modelValue gets triggered twice because we reset initialValue
    if (isEqual(dirtyModel.value.playbooks, newVals)) {
      return
    }
    const newValPayloads = []

    if (newVals.length) {
      // If the selected values(newVals) is missing a goal pathway(goalPathwayIds)
      // that means that the pathway was unselected and should be updated to remove the goal map
      for (let i = 0; i < goalPathwayIds.value.length; i++) {
        if (!newVals.includes(goalPathwayIds.value[i])) {
          newValPayloads.push({
            pathwayId: goalPathwayIds.value[i],
            payload: { goalIds: [] },
          })
        }
      }

      // add goal pathway map for selected pathways
      for (let i = 0; i < newVals.length; i++) {
        newValPayloads.push({
          pathwayId: newVals[i],
          payload: { goalIds: [props.currentGoal.goalId] },
        })
      }
    }
    // if newVal is empty and there are current goal pathway maps
    // all pathways were unselected from the goal and mappings should be removed
    if (!newVals.length && props.goalPathways.length) {
      for (let i = 0; i < props.goalPathways.length; i++) {
        const pathway = props.goalPathways.find(
          (pathway: Pathway) => pathway.title === props.goalPathways[i].title
        )
        if (pathway && props.currentGoal) {
          newValPayloads.push({
            pathwayId: props.goalPathways[i].pathwayId,
            payload: { goalIds: [] },
          })
        }
      }
    }
    playbookUpdatePayloads.value = newValPayloads
    dirtyModel.value.playbooks = newVals
  }

  // array of task update payloads to make task updates on save
  const taskUpdatePayloads = ref<
    {
      taskId: string
      payload: Partial<TaskPayload>
    }[]
  >([])
  /**
   * update task goal map
   * @param newVals
   */
  function setTaskObj(newVals: string[]) {
    // update:modelValue gets triggered twice because we reset initialValue
    if (isEqual(dirtyModel.value.tasks, newVals)) {
      return
    }
    const newValPayloads = []

    if (newVals.length) {
      // If the selected values(newVals) is missing a goal task(goalTaskIds)
      // that means that the task was unselected and should be updated to remove the goal map
      for (let i = 0; i < goalTaskIds.value.length; i++) {
        if (!newVals.includes(goalTaskIds.value[i])) {
          newValPayloads.push({
            taskId: goalTaskIds.value[i],
            payload: { goalIds: [] },
          })
        }
      }

      // add goal task map for selected tasks
      for (let i = 0; i < newVals.length; i++) {
        newValPayloads.push({
          taskId: newVals[i],
          payload: { goalIds: [props.currentGoal.goalId] },
        })
      }
    }
    // if values var is empty and there are current goal task maps(props.goalTasks)
    // all tasks were unselected from the goal and mappings should be removed
    if (!newVals.length && props.goalTasks.length) {
      for (let i = 0; i < props.goalTasks.length; i++) {
        const task = props.goalTasks.find(
          (task: Task) => task.title === props.goalTasks[i].title
        )
        if (task && props.currentGoal) {
          newValPayloads.push({
            taskId: props.goalTasks[i].taskId,
            payload: { goalIds: [] },
          })
        }
      }
    }
    taskUpdatePayloads.value = newValPayloads
    dirtyModel.value.tasks = newVals
  }

  /**
   * api calls to update pathways goal and tasks goal maps
   */
  async function updateTasksOrPathways() {
    const playbookPromises: Promise<void>[] = []
    const taskPromises: Promise<void>[] = []
    if (playbookUpdatePayloads.value.length) {
      playbookUpdatePayloads.value.forEach((payload) =>
        playbookPromises.push(
          usePathwaysStore().updatePathway(payload.pathwayId, payload.payload)
        )
      )
    }
    if (taskUpdatePayloads.value.length) {
      taskUpdatePayloads.value.forEach((payload) =>
        taskPromises.push(
          useTasksStore().updateTask(payload.taskId, payload.payload)
        )
      )
    }
    await Promise.all(playbookPromises)
    await Promise.all(taskPromises)
  }

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   */
  async function fetchEvergreenTasksAndGoals() {
    await useTasksStore().getEvergreenTasks([props.currentGoal.memberId])
    context.emit('refetchGoal')
  }

  /**
   * takes created evergreen task ids and maps them to the current goal
   * will only refetch evergreen tasks if ff is off
   * @param taskIds
   */
  async function mapCreatedEvergreenTasksToGoal(taskIds: string[]) {
    const previouslySelected = dirtyModel.value.tasks
    //refetch evergreen tasks so that dropdown options include the newly created tasks
    await fetchEvergreenTasksAndGoals()
    // set task update payloads for selected tasks and created tasks
    setTaskObj([...taskIds, ...previouslySelected])
  }

  /**
   *
   */
  async function save() {
    await useGoalStore().updateGoal({
      goalId: props.currentGoal.goalId,
      ...{
        goalRefId: dirtyModel.value.goalRefId,
        freeTextTitle: dirtyModel.value.freeTextTitle,
        domainId: dirtyModel.value.domainId,
        details: dirtyModel.value.details,
        timeline: dirtyModel.value.timeline,
      },
    })
    await updateTasksOrPathways()
    await fetchEvergreenTasksAndGoals()
    close()
  }

  return {
    close,
    save,
    mapCreatedEvergreenTasksToGoal,
    ModalSize,
    goalTitleOptions,
    domainOptions,
    playbookOptions,
    taskOptions,
    dirtyModel,
    setPlaybooksObj,
    setTaskObj,
    showCreateNewEvergreenTasksModal,
    toggleCreateNewEvergreenTasksModal,
    evergreenPathway,
    showOtherTitle,
    goalRefOtherOption,
    updateTitle,
    OTHER_OPTION,
    TaskType,
    taskUpdatePayloads,
  }
}
