import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useGoalApi, useGoalStore } from '@/legacy/store/modules/goals'
import {
  isEvergreenPathway,
  usePathwayApi,
} from '@/legacy/store/modules/pathways'
import { useGoalTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { Goal, GoalStatus } from '@/legacy/types/pathways/goals'
import {
  Pathway,
  PathwayStatus,
  TaskStatus,
} from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'
/**
 *
 */
export default function () {
  const { data: goals } = storeToRefs(useGoalApi())
  const { data: pathways } = storeToRefs(usePathwayApi())
  const { data: tasks } = storeToRefs(useGoalTaskApi())
  const goalsArray = computed(() => Object.values(goals.value ?? {}))

  const pathwayArray = computed(() => Object.values(pathways.value ?? {}))
  const taskArray = computed(() => Object.values(tasks.value ?? {}))
  const route = useRoute()
  const showManageGoalsModal = ref(false)

  const sortedGoals = computed(() => {
    return goalsArray.value
      .filter((goal: Goal) => goal.status === GoalStatus.ACTIVE)
      .sort((a, b) => a.sortNumber - b.sortNumber)
  })
  // filter for active pathways that are not evergreen
  const goalPathways = computed(() => {
    return pathwayArray.value.filter((pathway: Pathway) => {
      if (
        !isEvergreenPathway(pathway.title) &&
        pathway.status === PathwayStatus.ACTIVE
      ) {
        return pathway
      }
      return
    })
  })

  // filter for active  evergreen tasks that have goalIds
  const goalTasks = computed(() => {
    return taskArray.value.filter((task: Task) => {
      if (task.goalIds.length && task.status === TaskStatus.ACTIVE) {
        return task
      }
      return
    })
  })

  /**
   * get pathways associated with given goal
   * @param goal
   */
  function getGoalPathways(goal: Goal) {
    const filtered_pathways = goalPathways.value.filter(
      (pathway: Pathway) =>
        pathway.goalIds.length && pathway.goalIds.includes(goal.goalId)
    )
    return filtered_pathways ?? []
  }

  /**
   * get tasks associated with given goal
   * @param goal
   */
  function getGoalTasks(goal: Goal) {
    const filtered_tasks = goalTasks.value.filter(
      (task: Task) => task.goalIds.length && task.goalIds.includes(goal.goalId)
    )
    return filtered_tasks ?? []
  }

  /**
   * get completed tasks associated with given goal
   * @param goal
   */
  function getCompletedGoalTasks(goal: Goal) {
    const completedGoalTasks = taskArray.value.filter((task: Task) => {
      if (task.goalIds.length && task.status === TaskStatus.COMPLETED) {
        return task
      }
      return
    })
    const filtered_tasks = completedGoalTasks.filter(
      (task: Task) => task.goalIds.length && task.goalIds.includes(goal.goalId)
    )
    return filtered_tasks ?? []
  }

  const ongoingPathways = computed(() => {
    return pathwayArray.value.filter((pathway: Pathway) => {
      if (!pathway.goalIds.length && pathway.status === PathwayStatus.ACTIVE) {
        return pathway
      }
      return null
    })
  })

  /**
   *
   */
  function toggleManageGoalsModel() {
    showManageGoalsModal.value = !showManageGoalsModal.value
  }

  /**
   * refetch goals after updating/creating
   */
  async function fetchGoals() {
    await useGoalStore().getGoals({
      filter_member_ids: [route.params.patientId as string],
    })
  }

  onMounted(async () => {
    await useTasksStore().getTasks({
      filter_member_ids: [route.params.patientId as string],
    })
    await useTasksStore().getEvergreenTasks([route.params.patientId as string])
    await fetchGoals()
  })

  return {
    sortedGoals,
    getGoalPathways,
    pathwayArray,
    // manage goals modal
    toggleManageGoalsModel,
    showManageGoalsModal,
    goalPathways,
    goalsArray,
    fetchGoals,
    getGoalTasks,
    getCompletedGoalTasks,
    // ongoing support
    ongoingPathways,
  }
}
