<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Fall Risk"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div class="">
          <TMDropdownField
            name="fallInTheLastTwelveMonths"
            label="Fall In The Last 12 Months"
            class="w-full my-2"
            :options="booleanOptions"
            option-label="label"
            option-value="value"
            :model-value="undefined"
          />
          <TMDropdownField
            name="fearOfFalling"
            class="w-full my-2"
            label="Fear Of Falling"
            :model-value="undefined"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
          <TMDropdownField
            name="peripheralNeuropathy"
            label="Peripheral Neuropathy"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
          <TMDropdownField
            name="sedatingMedications"
            label="Sedating Medications"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="sedatingMedicationsOptions"
          />
        </div>
        <div>
          <TMDropdownField
            name="unsteadyWithStandingOrWalking"
            label="Unsteady With Standing or Walking"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
          <TMDropdownField
            name="usesDMEToAssistWithAmbulation"
            label="Uses DME to Assist with Ambulation"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
          />
          <TMDropdownField
            name="anticoagulation"
            label="Anticoagulation"
            :model-value="undefined"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="anticoagulationOptions"
          />
        </div>
      </div>

      <label class="font-bold mb-2">Additional Context</label>
      <TTextarea name="additionalContext" :model-value="''" />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="fall-risk-cancel"
          @click="close"
        />
        <TMPrimaryButton name="fall-risk-save" label="Save" @click="close" />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editFallRiskProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TMSecondaryButton,
  },
  props: editFallRiskProps,
  emits: ['close'],
  setup,
})
</script>
