<template>
  <div class="w-full px-2 pt-2 border rounded-lg text-sm">
    <b>{{ startCase(subtask.title) }}</b>
    <div class="flex items-center text-nash-neutral700">
      <span class="flex items-center shrink-1">
        <span
          :class="{
            overdue: isOverdue,
            'text-nash-neutral700': !isOverdue,
          }"
        >
          {{ renderDatePretext(isOverdue, subtask.status) }}:
          {{ renderDateString(subtask) }}
        </span>
      </span>
      <div class="bullet mx-2"></div>
      <div class="flex shrink-1 text-nash-neutral700 items-center">
        {{
          subtask.estimatedMinutesToComplete
            ? `Est: ${subtask.estimatedMinutesToComplete} min`
            : 'Est: N/A'
        }}
      </div>
      <div class="bullet ml-2"></div>
      <TOStaffAssignDropdown
        name="pointer-subtask-responsible-staff"
        class="remove-border h-6"
        :model-value="currentStaff"
        :show-specialization-roles="true"
        :can-unassign="true"
        :pt="{
          trigger: { class: 'hidden' },
        }"
        :disabled="disabled"
        @change="updateRoleOrStaff($event.value)"
      />
      <div class="bullet"></div>
      <TMActionDropdown
        class="shrink-1 h-fit remove-border text-sm text-nash-neutral700"
        :initial-value="currentSubtaskStatus"
        :icon="currentIcon"
        icon-size="md"
        :icon-fill-type="currentIconFill"
        name="subtask-status"
        option-label="label"
        option-value="value"
        :options="statusOptions"
        :show-clear="false"
        :expanded="false"
        :disabled="disabled"
        @change="setStatus($event.value)"
      />
      <div class="bullet mr-2"></div>
      <div>
        <TIcon :icon="getPriorityIcon(subtask.priority)" color="neutral500" />
        {{ startCase(toLower(subtask.priority)) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { PropType, defineComponent } from 'vue'
import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import setup from './controller'

export default defineComponent({
  components: {
    TIcon,
    TMActionDropdown,
    TOStaffAssignDropdown,
  },
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['refetchCommsAndSubtasks'],
  setup,
})
</script>
<style lang="scss" scoped>
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
.remove-border {
  border-width: 0px !important;
  @apply hover:bg-nash-purple100;
}
.overdue {
  @apply text-nash-brick600 font-bold;
}
</style>
