<template>
  <div class="overflow-visible px-5 card-style w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row space-x-4 items-center">
            <h2
              v-tooltip.right="formattedCarePlanUpdateTooltip"
              class="py-2 print-h2"
            >
              Care Plan
            </h2>
            <div>
              <TChip
                v-if="hasSensitiveDx"
                label="Sensitive Diagnosis"
                icon="sensitive"
                icon-fill="nash-tigerlily600"
                icon-pos="left"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <div v-tooltip.left="'Print care plan'">
              <TMSecondaryButton
                icon="print"
                class="w-10 h-10 ml-3 p-4"
                @click="showPdf"
              />
            </div>
            <div v-tooltip.left="'Edit care plan'">
              <TMSecondaryButton
                icon="pencil"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleCarePlanEdit"
              />
            </div>
          </div>
        </div>
        <div>
          <h4>
            {{ patientSummary }}
          </h4>
        </div>
        <div class="mt-3 flex flex-row">
          <div class="flex flex-col w-3/12 space-y-3">
            <div>
              <label>Tier</label>
            </div>
            <div>
              <label>Domain(s)</label>
            </div>
            <div>
              <label>Treatment Status</label>
            </div>
            <div>
              <label>Treatment Intent</label>
            </div>
            <div>
              <label>Program Status</label>
            </div>
          </div>
          <div class="flex flex-col w-11/12 space-y-3">
            <div>
              {{ tierLevel }}
            </div>
            <div>
              {{ domainsDisplay }}
            </div>
            <div>
              {{ treatmentStatusDisplay }}
            </div>
            <div>
              {{ treatmentIntentDisplay }}
            </div>
            <div>
              {{ programStatusDisplay }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditCarePlan
    v-if="showEditModal"
    :is-open="showEditModal"
    @close="toggleCarePlanEdit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import EditCarePlan from '@/legacy/components/patient/carePlans/modals/EditCarePlan/EditCarePlan.vue'
import TChip from '@/legacy/nashville/chip/TChip.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TChip,
    TMSecondaryButton,
    EditCarePlan,
  },
  setup,
})
</script>
./controller./controller
