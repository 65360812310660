<template>
  <div v-if="filteredLabels" class="flex flex-row space-x-2">
    <div v-for="[id, label] in filteredLabels" :key="id">
      <TLabel :label="readableLabelRef(label.labelRefId)" />
    </div>
  </div>
</template>

<script lang="ts">
import values from 'lodash/values'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { safeLookup } from '@/legacy/libs/lookup'
import TLabel from '@/legacy/nashville/TLabel.vue'

import { usePatientLabelsApi } from '@/legacy/store/modules/labels'
import {
  useLabelRefApi,
  useLabelRefStore,
} from '@/legacy/store/modules/labelsReferences'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { Label as LabelType } from '@/legacy/types/entities/entities'
import { NotificationType } from '@/legacy/types/notifications'
import { InsuranceLabelName } from '@/legacy/types/patients/insurances'

export default defineComponent({
  components: { TLabel },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { data: labelCatalog } = storeToRefs(useLabelRefApi())
    const { data: labels } = storeToRefs(usePatientLabelsApi())

    /**
     *
     */
    async function getLabels() {
      try {
        await usePatientLabelsApi().list({
          params: { filter_entity_ids: [props.patientId] },
        })
      } catch (err) {
        console.log(err)
        if (err instanceof Error) {
          useNotificationStore().setNotification({
            message: 'Error fetching patient labels',
            type: NotificationType.DANGER,
          })
        }
        return
      }
    }
    onBeforeMount(async () => {
      await getLabels()
      if (values(labels.value ?? {}).length) {
        const labelRefIds = values(labels.value ?? {}).map(
          (l: LabelType) => l.labelRefId
        )
        void useLabelRefStore().fetchLabelRefs(labelRefIds)
      }
    })

    /**
     *
     * @param labelRefId
     */
    function readableLabelRef(labelRefId: string) {
      const ref = safeLookup(labelRefId, labelCatalog.value)
      if (ref) {
        return ref.description
      }
      return ''
    }

    const filteredLabels = computed(() => {
      const labelEntries = Object.entries(labels.value ?? {})
      if (labelEntries.length > 0) {
        return labelEntries.filter((label) => {
          const labelRefId = label[1].labelRefId
          return (
            readableLabelRef(labelRefId) !== InsuranceLabelName.clover_eligible
          )
        })
      }
      return null
    })
    return {
      filteredLabels,
      readableLabelRef,
    }
  },
})
</script>
