import { UUID } from '../global/base'

// TYPES --------------------
export type Domain = {
  domainId: UUID
  patientId: UUID
  domain: string
}

// ENUMS --------------------
export enum DomainsEnum {
  PLANNING_FOR_FUTURE_CARE = 'planning_for_future_care',
  MAINTAINING_PSYCHOLOGICAL_HEALTH = 'maintaining_psychological_health',
  CONTROLLING_MEDICAL_CONDITIONS = 'controlling_medical_conditions',
  SUPPORTING_PHYSICAL_FUNCTION = 'supporting_physical_function',
  ADDRESSING_BARRIERS_TO_CARE = 'addressing_barriers_to_care',
  MANAGING_SYMPTOMS = 'managing_symptoms',
  ESSENTIAL_SUPPORT = 'essential_support',
}
