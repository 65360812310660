import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type AcuityScore = {
  acuityScoreId: UUID
  patientId: UUID
  overallScore: number
  acuteContribution: number
  clinicalContribution: number
  demographicContribution: number
} & Timestamps

export type PinnedAcuityScore = {
  pinnedAcuityScoreId: UUID
  acuityScoreId: Nullable<UUID>
  patientId: UUID
  overallScore: number
  reason: Nullable<string>
  reasonNote: Nullable<string>
  acuteContribution: Nullable<number>
  clinicalContribution: Nullable<number>
  demographicContribution: Nullable<number>
} & Timestamps

// ENUMS --------------------

export enum AcuityLevel {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum TierLevel {
  COMPLEX = 'Complex',
  STANDARD = 'Standard',
  ESSENTIAL = 'Essential',
}

const _HIGH_ACUITY_THRESHOLD = 49
const _MEDIUM_ACUITY_THRESHOLD = 25
export enum AcuityThreshold {
  High = _HIGH_ACUITY_THRESHOLD,
  Medium = _MEDIUM_ACUITY_THRESHOLD,
}

// CONSTANTS --------------------
const HIGH_ACUITY_FILTER_VALUE = 100
export const acuityFilterOptions = [
  /* eslint-disable @typescript-eslint/restrict-plus-operands */
  [[AcuityThreshold.High + 1, HIGH_ACUITY_FILTER_VALUE], AcuityLevel.High],
  [[AcuityThreshold.Medium + 1, AcuityThreshold.High], AcuityLevel.Medium],
  [[0, AcuityThreshold.Medium], AcuityLevel.Low],
  /* eslint-enable @typescript-eslint/restrict-plus-operands */
]

// To replace acuity filter
export const tierFilterOptions = [
  /* eslint-disable @typescript-eslint/restrict-plus-operands */
  [[AcuityThreshold.High + 1, HIGH_ACUITY_FILTER_VALUE], TierLevel.COMPLEX],
  [[AcuityThreshold.Medium + 1, AcuityThreshold.High], TierLevel.STANDARD],
  [[0, AcuityThreshold.Medium], TierLevel.ESSENTIAL],
  /* eslint-enable @typescript-eslint/restrict-plus-operands */
]
