import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Label } from '@/legacy/types/entities/entities'

export const usePatientLabelsApi = apiStore<Label, IdMap<Label>>(
  'patientLabelsApi',
  '/api/patients/labels',
  {
    transformData: (d: { data: Label[] }) =>
      idMapTransform({}, 'data', 'labelId', d.data),
  }
)
