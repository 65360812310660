import {
  EntityRole,
  EntityRoleSelectType,
} from '@/legacy/types/entities/entities'

export const humanizeRole = (role: EntityRole | string | null) => {
  // NOTE: if names are changed here, they should also be changed in the
  // infrastructure repo (`terraform/modules/auth0/roles.tf`)
  // and thymeboxserver (`entities/client/enums/entity_roles.py`) so they
  // are the same everywhere
  switch (role) {
    case EntityRole.CLINICAL__CARE_PARTNER:
      return 'Care Partner'
    case EntityRole.CLINICAL__ADMIN_ASSISTANT:
      return 'Admin Assistant'
    case EntityRole.CLINICAL:
      return 'Care Team (general)'
    case EntityRole.CLINICAL__NURSE:
      return 'Nurse'
    case EntityRole.CLINICAL__ENROLLMENT_SPECIALIST:
      return 'Enrollment Specialist'
    case EntityRole.CLINICAL__ENROLLMENT_SPECIALIST_LEAD:
      return 'Enrollment Specialist Lead'
    case EntityRole.CLINICAL__CARE_POD_LEAD:
      return 'Care Pod Lead'
    case EntityRole.CLINICAL__MEDICAL_DIRECTOR:
      return 'Medical Director'
    case EntityRole.CLINICAL__TIERING_NP:
      return 'Tiering NP'
    case EntityRole.CLINICAL__LIAISON:
      return 'Clinical Liaison'
    case EntityRole.STAFF:
      return 'Staff'
    case EntityRole.SYSTEM:
      return 'System'
    case EntityRole.PATIENT:
      return 'Patient'
    case EntityRole.CONTACT:
      return 'Contact'
    case EntityRole.TECH_TEAM:
      return 'Tech Team'
    case EntityRole.ADMIN:
      return 'Explicit Admin'
    case EntityRole.CARE_TEAM_LEADERSHIP:
      return 'Care Team Leadership'
    case EntityRole.SPECIALIZATION__TRANSITIONS_OF_CARE_CP:
      return 'Transitions of Care CP'
    case EntityRole.SPECIALIZATION__TRANSITIONS_OF_CARE_RN:
      return 'Transitions of Care RN'
    case EntityRole.QUALITY_TRAINING_ENABLEMENT:
      return 'Quality, Training, and Enablement (QTE)'
    case EntityRole.CONTENT_MANAGER:
      return 'Content Manager'
    case EntityRole.SPECIALIZATION__ONBOARDING_RN:
      return 'Onboarding RN'
    case EntityRole.SPECIALIZATION__ONBOARDING_CP:
      return 'Onboarding CP'
    case EntityRole.SPECIALIZATION_MEDICAL_RECORDS_AA:
      return 'Medical Records AA'
    case EntityRole.SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_RN:
      return 'Serious Illness Conversation RN'
    case EntityRole.SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_CP:
      return 'Serious Illness Conversation CP'
    case EntityRole.SPECIALIZATION__TRIAGE_RN:
      return 'Triage RN'
    case EntityRole.SPECIALIZATION__BEHAVIORAL_HEALTH_LEAD:
      return 'Behavioral Health Lead'
    case EntityRole.SPECIALIZATION__BEHAVIORAL_HEALTH_CP:
      return 'Behavioral Health Care Partner'
    case EntityRole.SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_CP:
      return 'Advanced Social Needs Care Partner'
    case EntityRole.SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_SW:
      return 'Advanced Social Needs Social Worker'
    case EntityRole.SPECIALIZATION__SITE_OF_SERVICE_SHIFT_RN:
      return 'Site of Service RN'
    case EntityRole.SPECIALIZATION__SITE_OF_SERVICE_SHIFT_CP:
      return 'Site of Service CP'
    case EntityRole.TCOP_PARTNERSHIP_OPS:
      return 'TCOP Partnership Ops'
    default:
      return '<Unknown>'
  }
}
const sortedRoles = Object.values(EntityRole).sort((a, b) =>
  humanizeRole(a).localeCompare(humanizeRole(b))
)

export const staffRoles = sortedRoles.filter(
  (r) => r !== EntityRole.CONTACT && r !== EntityRole.PATIENT
)

export const adminSubtaskResponsibleRoles = sortedRoles.filter(
  (r) => r !== EntityRole.CONTACT
)

export const subtaskOwnerRoles = staffRoles.filter(
  // Filter to only the sub-care team roles.
  // Any other roles can be assigned to by the system (and displayed),
  // but not manually assigned to
  (r) => r.startsWith('clinical__')
)
const _getRoleOptions = (
  selectType: EntityRoleSelectType,
  roles: EntityRole[]
) => {
  if (selectType === EntityRoleSelectType.Radio) {
    return roles.map((r) => [humanizeRole(r), r])
  }
  if (selectType === EntityRoleSelectType.PVDropdown) {
    return roles.map((r) => ({ label: humanizeRole(r), value: r }))
  }
  return roles.map((r) => [r, humanizeRole(r)])
}

export const staffRoleOptions = (selectType: EntityRoleSelectType) => {
  return _getRoleOptions(selectType, staffRoles)
}

export const subtaskOwnerRoleOptions = (selectType: EntityRoleSelectType) => {
  return _getRoleOptions(selectType, subtaskOwnerRoles)
}

export const adminSubtaskResponsibleRoleOptions = (
  selectType: EntityRoleSelectType
) => {
  return _getRoleOptions(selectType, adminSubtaskResponsibleRoles)
}

// NOTE: when adding a role to additionalAssignableRoles, also remove it from unassignableRoles
// used for assigning tasks to roles that are not part of the main care team
export const additionalAssignableRoles = [
  {
    value: EntityRole.SPECIALIZATION__SITE_OF_SERVICE_SHIFT_CP,
    label: 'Site of Service CP',
  },
  {
    value: EntityRole.SPECIALIZATION__TRANSITIONS_OF_CARE_RN,
    label: 'Transitions of Care RN',
  },
  {
    value: EntityRole.SPECIALIZATION__TRANSITIONS_OF_CARE_CP,
    label: 'Transitions of Care CP',
  },
  { value: EntityRole.SPECIALIZATION__ONBOARDING_RN, label: 'Onboarding RN' },
  { value: EntityRole.SPECIALIZATION__ONBOARDING_CP, label: 'Onboarding CP' },
  {
    value: EntityRole.SPECIALIZATION_MEDICAL_RECORDS_AA,
    label: 'Medical Records AA',
  },
  { value: EntityRole.TCOP_PARTNERSHIP_OPS, label: 'TCOP Partnership Ops' },
  { value: EntityRole.CLINICAL__LIAISON, label: 'Clinical Liaison' },
  {
    value: EntityRole.SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_RN,
    label: 'Serious Illness Conversation RN',
  },
  {
    value: EntityRole.SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_CP,
    label: 'Serious Illness Conversation CP',
  },
]
// NOTE: these roles should be removed from unnasignableRoles as they become assignable in the future
// used for filtering out roles that should not be assigned to tasks
export const unassignableRoles = [
  EntityRole.SPECIALIZATION__TRIAGE_RN,
  EntityRole.SPECIALIZATION__BEHAVIORAL_HEALTH_LEAD,
  EntityRole.SPECIALIZATION__BEHAVIORAL_HEALTH_CP,
  EntityRole.SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_CP,
  EntityRole.SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_SW,
  EntityRole.SPECIALIZATION__SITE_OF_SERVICE_SHIFT_RN,
]
