import { uniqueId } from 'lodash'
import omit from 'lodash/omit'
import { defineStore, storeToRefs } from 'pinia'

import { thymeDispatch } from '@/legacy/libs/eventBus'
import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'

import { usePathwaysStore } from '@/legacy/store/modules/pathways'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useTasksStore } from '@/legacy/store/modules/tasks'
import { SaveState } from '@/legacy/types/api/api'
import { DataType } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'

import { EntityRole } from '@/legacy/types/entities/entities'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import { TemplateStatus } from '@/legacy/types/pathways/pathways'
import {
  BulkEditSubtaskResponse,
  GetSubtasksParams,
  MAX_NUM_SUBTASKS,
  QueueStyle,
  Subtask,
  SubtaskOutreach,
  SubtaskPayload,
  SubtaskState,
  SubtaskSuggestionParams,
  SubtaskTemplate,
  SubtaskTemplateBase,
  SubtaskTemplateDataState,
} from '@/legacy/types/pathways/subtasks'
import { Task, CreateTaskInfo } from '@/legacy/types/pathways/tasks'
import { RemindersSubtaskStatus, Stack } from '@/legacy/types/reminders'

// CONST
const SUBTASK_TEMPLATES_PAGE_LENGTH = 10
const STACKING_LIMIT = 5

const transform = (data: Subtask[]): Partial<SubtaskState> => {
  const subtaskApi = useSubtaskApi()
  const currentSubtasksMapping = subtaskApi.data ?? {}
  const newDataMap = toIdMap(data, 'subtaskId')
  const newData = Object.assign({}, currentSubtasksMapping, newDataMap)
  return {
    ...idMapTransform({}, 'data', 'subtaskId', Object.values(newData)),
  }
}

const transformSuggestions = (data: Subtask[]): Partial<SubtaskState> => {
  return {
    ...(data ? { data: toIdMap(data, 'subtaskId') } : {}),
  }
}

const transformSubtaskStacks = (
  existingData: IdMap<Subtask[]>,
  newData: Subtask[],
  total: number,
  isPodLeadView = false
): Partial<any> => {
  // Since this endpoint is a listAll(),
  // transform data to be an id map of
  // patient ID <> list of subtask stacks while
  // maintaining order for each subtask per patient
  // for every page call.
  newData.forEach((subtask: Subtask) => {
    const memberId = subtask.memberId

    if (memberId) {
      if (!existingData[memberId]) {
        existingData[memberId] = [subtask]
      } else if (
        !existingData[memberId].find(
          (s: Subtask) => s.subtaskId === subtask.subtaskId
        )
      ) {
        if (
          isPodLeadView ||
          (!isPodLeadView && existingData[memberId].length < STACKING_LIMIT)
        ) {
          existingData[memberId].push(subtask)
        }
      }
    }
  })

  return {
    queryMetadata: { total },
    data: existingData,
  }
}

/**
 *
 * @param data
 * @param total
 * @param isPodLeadView
 */
export const transformAssignedSubtaskStacks = (
  data: Subtask[],
  total: number,
  isPodLeadView = false
): Partial<any> => {
  return transformSubtaskStacks({}, data, total, isPodLeadView)
}

/**
 *
 * @param data
 * @param total
 */
export const transformUnassignedSubtaskStacks = (
  data: Subtask[],
  total: number
): Partial<any> => {
  return transformSubtaskStacks({}, data, total)
}

/**
 *
 * @param subtaskTemplateState
 * @param data
 */
function transformSubtaskTemplateById(
  subtaskTemplateState: Partial<SubtaskTemplateDataState>,
  data: SubtaskTemplate[] | undefined
): Partial<SubtaskTemplateDataState> {
  const existingData = subtaskTemplateState.data ?? {}
  const newData = data ? { data: toIdMap(data, 'variantId') } : {}
  return {
    ...omit(subtaskTemplateState, 'data'),
    // Merge data instead of overwriting it
    ...Object.assign({}, existingData, newData),
  }
}

export const transformSubtaskTemplates = (
  data: SubtaskTemplate[],
  total: number
): Partial<SubtaskTemplateDataState> => {
  const subtaskTemplateApi = useSubtaskTemplateApi()
  const currentTemplatesMapping = subtaskTemplateApi.data ?? {}
  const newDataMap = toIdMap(data, 'variantId')
  const newData = Object.assign({}, currentTemplatesMapping, newDataMap)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'variantId', Object.values(newData)),
  }
}

export const transformSubtaskTemplateBases = (
  data: SubtaskTemplateBase[],
  total: number
): Partial<SubtaskTemplateDataState> => {
  const subtaskTemplatBaseApi = useSubtaskTemplateBaseApi()
  const currentTemplateBasesMapping = subtaskTemplatBaseApi.data ?? {}
  const newDataMap = toIdMap(data, 'baseTemplateId')
  const newData = Object.assign({}, currentTemplateBasesMapping, newDataMap)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'baseTemplateId', Object.values(newData)),
  }
}

export const useSubtasksStore = defineStore('subtasks', {
  // cloneDeep due to object ref issue: https://github.com/vuejs/pinia/issues/484
  state: () => ({}),
  actions: {
    setError(error: string | Error, action: string, showToast: boolean) {
      if (showToast) {
        useNotificationStore().setNotification({
          message: `Error ${action} subtask(s)`,
          type: NotificationType.DANGER,
          error: `Error ${action} subtask: ${error}`,
        })
      }
    },

    async setSuccess(action: string, showToast: boolean) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        await Promise.all([
          usePathwaysStore().getAllPathways(patientId.value),
          useTasksStore().getTasks({ filter_member_ids: [patientId.value] }),
        ])
        // trigger new thymelines to update (they now watch this and call for getData)
        thymeDispatch('thymeline-update')
        if (showToast) {
          useNotificationStore().setNotification({
            message: `Success ${action} subtask(s)`,
            type: NotificationType.SUCCESS,
          })
        }
      }
    },
    async updateSubtask(
      subtaskId: string,
      payload: Partial<SubtaskPayload>,
      headers = {},
      saveState: SaveState | undefined = undefined
    ) {
      const data = await useSubtaskApi().partialUpdate({
        body: payload,
        ids: subtaskId,
        headers,
        metaOptions: { saveState: saveState },
      })

      const error = useSubtaskApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE, !saveState)
        return
      }

      if (data) {
        await this.setSuccess(NotificationActions.UPDATE, !saveState)
      }
      return data
    },
    async getSubtasks({
      filter_patient_ids = null,
      filter_subtask_ids = null,
      page_length = MAX_NUM_SUBTASKS,
      page_number = 1,
      filter_free_text = '',
      filter_subtask_status = null,
    }: GetSubtasksParams) {
      const data = await useSubtaskApi().list({
        params: {
          ...(filter_subtask_ids ? { filter_subtask_ids } : {}),
          ...(filter_free_text ? { filter_free_text } : {}),
          ...(filter_subtask_status ? { filter_subtask_status } : {}),
          ...(page_length ? { page_length } : {}),
          ...(page_number ? { page_number } : {}),
          ...(filter_patient_ids ? { filter_patient_ids } : {}),
          parts: ['communication_maps', 'autogenerated_notes'],
        },
      })

      if (useSubtaskApi().error) {
        this.setError(
          useSubtaskApi().error ?? 'unknown error',
          'fetching',
          true
        )
      } else {
        return data
      }
    },
    async getSubtaskSuggestions({
      memberId,
      communicationId,
      staffId,
      pageLength,
    }: SubtaskSuggestionParams) {
      const subtaskSuggestionsApi = useSubtaskSuggestionsApi()
      await subtaskSuggestionsApi.list({
        params: {
          member_id: memberId,
          communication_id: communicationId,
          staff_id: staffId,
          page_length: pageLength,
          page_number: 1,
        },
      })
      if (subtaskSuggestionsApi.error) {
        this.setError(subtaskSuggestionsApi.error, 'fetching', true)
      }
    },

    async createSubtasks(
      taskId: string,
      subtaskTemplateCreatePayload: Array<CreateTaskInfo>
    ) {
      const data: Subtask[] = []
      const subtaskApi = useSubtaskApi()
      useSubtaskApi().updateData(null, DataType.SINGULAR)
      for (let i = 0; i < subtaskTemplateCreatePayload.length; i++) {
        const res = await subtaskApi.create({
          body: {
            subtaskVariantId: subtaskTemplateCreatePayload[i].variantId,
            dueDatetime: subtaskTemplateCreatePayload[i].dueDatetime,
            priority: subtaskTemplateCreatePayload[i].priority,
            ...(subtaskTemplateCreatePayload[i].customSubject
              ? { customSubject: subtaskTemplateCreatePayload[i].customSubject }
              : {}),
            taskId,
          },
        })
        if (res) {
          data.push(res)
        }
        if (subtaskApi.error) {
          this.setError(subtaskApi.error, NotificationActions.CREATE, true)
          return
        }
      }
      if (data.length) {
        await this.setSuccess(NotificationActions.CREATE, true)
      }
    },

    async getSubtaskTemplates(params: { [key: string]: any }) {
      const subtaskTemplateApi = useSubtaskTemplateApi()

      await subtaskTemplateApi.list({
        params: {
          filter_template_statuses: TemplateStatus.PUBLISHED,
          sort_by: ['title,asc'],
          ...params,
        },
      })
      if (subtaskTemplateApi.error) {
        this.setError(
          subtaskTemplateApi.error,
          'fetching subtask template',
          true
        )
      }
    },

    async getSubtaskTemplateBases({
      page_length = SUBTASK_TEMPLATES_PAGE_LENGTH,
      filter_free_text = null,
      page_number = 1,
    }: {
      page_length: number
      page_number: number
      filter_free_text: string | null
    }) {
      const subtaskTemplateBaseApi = useSubtaskTemplateBaseApi()
      await subtaskTemplateBaseApi.list({
        params: {
          ...(filter_free_text ? { filter_free_text } : {}),
          page_length,
          page_number,
        },
      })
      if (subtaskTemplateBaseApi.error) {
        this.setError(
          subtaskTemplateBaseApi.error,
          'fetching subtask template',
          true
        )
      }
    },

    async getSubtaskTemplatesById({
      filter_variant_ids,
    }: {
      filter_variant_ids: string[]
    }) {
      const subtaskTemplatesByIdApi = useSubtaskTemplatesByIdApi()
      const data = await subtaskTemplatesByIdApi.list({
        params: {
          filter_variant_ids: filter_variant_ids,
        },
      })
      if (subtaskTemplatesByIdApi.error) {
        this.setError(
          subtaskTemplatesByIdApi.error,
          `fetching subtask template ${filter_variant_ids}`,
          true
        )
      }
      return data
    },
  },
})

export const useSubtaskApi = apiStore<Subtask, IdMap<Subtask>>(
  'subtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => transform(d.data),
  }
)

export const useCarePlanSubtaskApi = apiStore<Subtask, IdMap<Subtask>>(
  'carePlansubtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => transform(d.data),
  }
)

export const useIncompleteSubtaskApi = apiStore<Subtask, IdMap<Subtask>>(
  'incompleteSubtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => transform(d.data),
  }
)

export const useLastCaseConferencesSubtaskApi = apiStore<Subtask>(
  'caseConferenceSubtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => d,
  }
)

export const useLastGvpSubtaskApi = apiStore<Subtask>(
  'gvpSubtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => d,
  }
)

export const useTaskAccordionApi = apiStore<Subtask, IdMap<Subtask>>(
  'taskAccordionApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) => transform(d.data),
  }
)

export const useSubtaskTemplateApi = apiStore<
  SubtaskTemplate,
  IdMap<SubtaskTemplate>
>('subtaskTemplateApi', '/api/subtask_templates', {
  transformData: (d: {
    data: SubtaskTemplate[]
    queryMetadata: { total: number }
  }) => transformSubtaskTemplates(d.data, d.queryMetadata.total),
  params: {
    parts: ['staff_completable_forms'],
  },
})

export const useSubtaskTemplatesByIdApi = apiStore<
  SubtaskTemplate,
  IdMap<SubtaskTemplate>
>('subtaskTemplatesByIdApi', '/api/subtask_templates', {
  transformData: (d: {
    data: SubtaskTemplate[]
    queryMetadata: { total: number }
  }) => transformSubtaskTemplateById({}, d.data),
  params: {
    parts: ['staff_completable_forms'],
  },
})

// Specific api for grabbing subtask suggestions
export const useSubtaskSuggestionsApi = apiStore<Subtask, IdMap<Subtask>>(
  'subtaskSuggestionsApi',
  '/api/subtasks/suggestions',
  {
    transformData: (d: { data: Subtask[] }) => transformSuggestions(d.data),
  }
)

// Specific api for grabbing tasks related to a subtask
// -- not to conflict with the taskApi which grabs tasks for a pathway
export const useSubtaskTaskApi = apiStore<Task>(
  'subtaskTaskApi',
  '/api/tasks',
  {
    transformData: (d: { data: Task[] }) => d,
  }
)

export const useSubtaskTemplateBaseApi = apiStore<
  SubtaskTemplateBase,
  IdMap<SubtaskTemplateBase>
>('subtaskTemplateBaseApi', '/api/subtask_templates/bases', {
  transformData: (d: {
    data: SubtaskTemplateBase[]
    queryMetadata: { total: number }
  }) => transformSubtaskTemplateBases(d.data, d.queryMetadata.total),
})

export const useSubtaskOutreachApi = apiStore<SubtaskOutreach>(
  'subtaskOutreachApi',
  '/api/subtasks/outreach/generate',
  {}
)

// stacks ----
export const useAssignedSubtaskStacksApi = apiStore<Subtask, IdMap<Subtask[]>>(
  'assignedSubtaskStacksApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[]; queryMetadata: { total: number } }) =>
      transformAssignedSubtaskStacks(d.data, d.queryMetadata.total),
  }
)

export const usePodLeadAssignedSubtaskStacksApi = apiStore<
  Subtask,
  IdMap<Subtask[]>
>('assignedPodLeadSubtaskStacksApi', '/api/subtasks', {
  transformData: (d: { data: Subtask[]; queryMetadata: { total: number } }) =>
    transformAssignedSubtaskStacks(d.data, d.queryMetadata.total, true),
})

export const useUnassignedSubtaskStacksApi = apiStore<
  Subtask,
  IdMap<Subtask[]>
>('unassignedSubtaskStacksApi', '/api/subtasks', {
  transformData: (d: { data: Subtask[]; queryMetadata: { total: number } }) =>
    transformUnassignedSubtaskStacks(d.data, d.queryMetadata.total),
})

// bulk edit ----
export const useBulkEditSubtasksApi = apiStore<BulkEditSubtaskResponse>(
  'bulkEditSubtasksApi',
  '/api/subtasks/bulk_edit',
  {}
)

// Enrollment Queue
export const useUnassignedEnrollmentQueueApiWithScheduledCalls =
  apiStore<Stack>(
    'unassignedEnrollmentQueueApi',
    '/api/subtasks/enrollment_queue',
    {
      transformData: (d: { data: Stack[] }) => d,
      params: {
        filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
        show_scheduled_calls_first: true,
        filter_queue_style: [QueueStyle.ENROLLMENT],
        filter_roles: [EntityRole.CLINICAL__ENROLLMENT_SPECIALIST],
      },
    }
  )

export const useAssignedToMeEnrollmentQueueApiWithScheduledCalls =
  apiStore<Stack>(
    'assignedToMeEnrollmentQueueApi',
    '/api/subtasks/enrollment_queue',
    {
      transformData: (d: { data: Stack[] }) => d,
      params: {
        filter_subtask_status: [...Object.values(RemindersSubtaskStatus)],
        show_scheduled_calls_first: false,
      },
    }
  )

export const useAssignedScheduledCallsSubtaskStacksApi = apiStore<
  Subtask,
  IdMap<Subtask>
>('assignedScheduledCallsSubtaskStacksApi', '/api/subtasks', {
  transformData: (d: { data: Subtask[] }) =>
    d.data ? { data: toIdMap(d.data, 'subtaskId') } : {},
})

export const useUnassignedScheduledCallsSubtaskStacksApi = apiStore<
  Subtask,
  IdMap<Subtask>
>('unassignedScheduledCallsSubtaskStacksApi', '/api/subtasks', {
  transformData: (d: { data: Subtask[] }) =>
    d.data ? { data: toIdMap(d.data, 'subtaskId') } : {},
})

// For care plans taskChip
// to grab subtasks for every goal task
export const createUniqueSubtaskApiStore = (uniqueStr: string) =>
  apiStore<Subtask>(`subtaskApi${uniqueId(uniqueStr)}`, '/api/subtasks', {
    transformData: (d: { data: Subtask[] }) => d,
  })
