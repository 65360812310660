<template>
  <div
    v-if="dataHasLoaded"
    class="bg-nash-neutral000 p-5"
    data-cy="thymeline-container"
  >
    <h3>Thymeline</h3>
    <div v-if="Object.keys(tabMap).length > 1" class="flex flex-row gap-x-4">
      <div v-for="tab in Object.keys(tabMap)" :key="tab">
        <div
          class="tab cursor-pointer flex"
          :class="{ 'tab--active': tab === activeTab }"
          data-cy="thymeline-category"
          @click="updateActiveTab(tab)"
        >
          {{ tabName(tab) }}
          <TBadge
            v-if="showTextingBadge(tab) && openPointerSubtasksNum"
            class="ml-1"
            name="textingBadge"
            :value="openPointerSubtasksNum"
          />
        </div>
      </div>
    </div>
    <component :is="activeTabComponent" />
  </div>
  <TSpinner v-else name="patient-thymeline" :partial-page="true" />
</template>

<script lang="ts">
import TBadge from '@nashville/badge/TBadge.vue'
import { defineComponent } from 'vue'
import DocumentsThymeline from '@/legacy/components/patient/thymeline/DocumentsThymeline.vue'
import HistoryThymeline from '@/legacy/components/patient/thymeline/HistoryThymeline.vue'
import OpenThymeline from '@/legacy/components/patient/thymeline/OpenThymeline.vue'
import OutcomesThymeline from '@/legacy/components/patient/thymeline/OutcomesThymeline.vue'
import SymptomsThymeline from '@/legacy/components/patient/thymeline/symptoms/SymptomsThymeline.vue'
import TextingThymeline from '@/legacy/components/patient/thymeline/texting/TextingThymeline.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TextingThymeline,
    OutcomesThymeline,
    DocumentsThymeline,
    OpenThymeline,
    HistoryThymeline,
    SymptomsThymeline,
    TBadge,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.tab {
  @apply h-6 text-center;
  border-bottom: 2px solid transparent;
  &--active {
    @apply border-b-2 font-bold;
    border-bottom-color: #4a4a4a;
  }
  &:hover {
    background-color: rgba(#ccc, 0.2);
  }
}
</style>
