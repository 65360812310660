import { ModalSize } from '@thyme/nashville/src/types/modals'
import { camelCase, startCase } from 'lodash'
import { ExtractPropTypes } from 'vue'
import { booleanOptions } from '../../shared/types'
import {
  ADLsInability,
  DME,
  ECOGStatus,
  IADLsInability,
  ProceduralSupport,
} from '../shared/types'
import { editPhysicalFunctionAssessmentProps } from './types'

type PropsType = ExtractPropTypes<typeof editPhysicalFunctionAssessmentProps>

/**
 * Set up the EditPhysicalFunctionAssessment component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const IADLsInabilityOptions = Object.values(IADLsInability)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const ADLsInabilityOptions = Object.values(ADLsInability)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const DMEOptions = Object.values(DME)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const proceduralSupportOptions = Object.values(ProceduralSupport)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const ECOGStatusOptions = Object.values(ECOGStatus)
    .map((val) => {
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  return {
    close,
    ModalSize,
    // dropdown options
    booleanOptions,
    IADLsInabilityOptions,
    ECOGStatusOptions,
    proceduralSupportOptions,
    DMEOptions,
    ADLsInabilityOptions,
  }
}
