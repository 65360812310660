import {
  ColumnOptions,
  Filter,
  FilterMetaType,
  FilterType,
} from '@thyme/nashville/src/types/tables'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import {
  programStatusFilter,
  treatmentStatusFilter,
  alignedPracticeFilter,
  languagesFilter,
  setupPayerFilter,
  lobFilter,
  tierFilters,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { arraysToDropdownOptions } from '@/legacy/libs/dropdowns'
import {
  formatNameFromEntity,
  formatProgramStatus,
  formatTreatmentStatus,
} from '@/legacy/libs/format'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { Entity } from '@/legacy/types/entities/entities'
import { Patient } from '@/legacy/types/patients/patients'

/**
 * Setup the PatientTable component
 */
export default function () {
  const router = useRouter()
  const table = ref<{
    getData: () => object
  } | null>(null)
  const getData = () => table.value?.getData()
  const { data: carePods } = storeToRefs(useAdminCarePodApi())
  const contractingEntities = useContractingEntities()
  // CLEANUP MT-3032
  const { enablePatientContracts } = storeToRefs(useFlagStore())
  const freeTextFilterName = ref<string>('freeTextName')

  onBeforeMount(async () => {
    await useAdminCarePodApi().listAll({
      params: { parts: 'staff' },
    })
  })

  const columns: ColumnOptions[] = [
    {
      field: 'name',
      header: 'Name',
      sortable: true,
      style: { 'flex-wrap': 'wrap' },
      linkFn: (_: any, row: Entity) => ({
        path: `/patient/${row.entityId}/`,
      }),
    },
    {
      field: 'preferredLanguage',
      header: 'Language',
      display: (_: any, row: Patient) =>
        startCase(row.person?.preferredLanguage) || '',
    },
    {
      field: 'memberId',
      header: 'ID',
      sortable: true,
      display: (_: any, row: Patient) =>
        (row.insurances?.length && row.insurances[0].memberId) ||
        'No Insurance',
    },
    {
      field: 'dateOfBirth',
      header: 'Date Of Birth',
      sortable: true,
      display: (_: any, row: Patient) => row.person?.dateOfBirth ?? '',
    },
    {
      field: 'programStatus',
      header: 'Program Status',
      sortable: true,
      display: (programStatus: Patient['programStatus'], row: Patient) =>
        formatProgramStatus(programStatus, row.programSubstatus),
    },
    {
      field: 'latestAcuityScore',
      header: 'Tier',
      display: (
        latestAcuityScore: Patient['latestAcuityScore'],
        row: Patient
      ) => formatTierScore(row.pinnedAcuityScore?.overallScore),
    },
    {
      field: 'responsible',
      header: 'Responsible',
      sortable: true,
      display: (_: any, row: Patient) => row.carePod?.name ?? '<Unknown>',
    },
    {
      field: 'treatmentStatus',
      header: 'Treatment Status',
      sortable: true,
      display: (treatmentStatus: Patient['treatmentStatus'], row: Patient) =>
        formatTreatmentStatus(treatmentStatus, row.treatmentSubstatus),
    },
  ]

  const params = computed(() => ({
    parts: [
      'care_pod',
      'insurances',
      'latest_acuity_score',
      'pinned_acuity_score',
    ],
  }))

  const nonAcuityFilters = {
    languages: languagesFilter,
    payerIds: setupPayerFilter(),
    lobs: lobFilter,
  }

  const memberCarePodIdsFilter = {
    value: null,
    matchMode: undefined,
    modalOptions: {
      label: 'Responsible',
      type: FilterType.Multiselect,
      optionsFn: () =>
        carePods.value
          ? carePods.value.map(({ carePodId, name }) => ({
              value: carePodId,
              label: name,
            }))
          : [],
    },
  }

  const hasUpcomingRemindersFilter = {
    value: [],
    matchMode: undefined,
    valToParamFn: (val: any) => {
      if (val?.length !== 1) {
        return null
      }
      return val[0]
    },
    modalOptions: {
      label: 'Has Upcoming Tasks',
      type: FilterType.Multiselect,
      options: arraysToDropdownOptions([
        ['true', 'Yes'],
        ['false', 'No'],
      ]),
    },
  }

  /**
   * Names and ids for all available contracting entities,
   * fetched from the contracting entities API
   */
  const getContractingEntitiesOptions = () =>
    contractingEntities.value?.map((contractingEntity) => ({
      value: contractingEntity?.contractingEntityId,
      label: contractingEntity?.displayName,
    }))

  const getContractingEntityIdsFilter = (): Filter => {
    return {
      value: [],
      matchMode: undefined,
      modalOptions: {
        label: 'Contracting Entity',
        type: FilterType.Multiselect,
        optionsFn: getContractingEntitiesOptions,
      },
    }
  }

  const getFilters = (): FilterMetaType => {
    return {
      ...tierFilters,
      ...nonAcuityFilters,
      // CLEANUP MT-3032
      ...(enablePatientContracts.value
        ? { contractingEntityIds: getContractingEntityIdsFilter() }
        : {}),
      programStatus: programStatusFilter,
      treatmentStatus: treatmentStatusFilter,
      alignedPractice: alignedPracticeFilter,
      memberCarePodIds: memberCarePodIdsFilter,
      hasUpcomingReminders: hasUpcomingRemindersFilter,
      freeTextName: { value: null, matchMode: undefined },
      freeTextInsurance: { value: null, matchMode: undefined },
      freeTextDob: { value: null, matchMode: undefined },
      freeTextPhoneNumber: { value: null, matchMode: undefined },
    }
  }

  const filters = computed(() => getFilters())

  const freeTextOptions = [
    { label: 'Name', value: 'freeTextName' },
    { label: 'Insurance ID', value: 'freeTextInsurance' },
    { label: 'Date Of Birth', value: 'freeTextDob' },
    { label: 'Phone Number', value: 'freeTextPhoneNumber' },
  ]

  /**
   * navigate to member profile page when row click event is emitted
   * @param event
   * @param event.data
   * @param event.originalEvent
   */
  async function rowClick(event: { data: Patient; originalEvent: Event }) {
    await router.push({
      name: '#patientProfile',
      params: { patientId: event.data.entityId },
    })
  }

  const setFreeTextFilterName = (val: string) => {
    freeTextFilterName.value = val
  }

  return {
    table,
    columns,
    filters,
    params,
    getData,
    usePatientsApi,
    rowClick,
    formatNameFromEntity,
    startCase,
    setFreeTextFilterName,
    freeTextOptions,
    freeTextFilterName,
  }
}
