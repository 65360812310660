<template>
  <div class="overflow-visible card-style pt-4 w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <div class="grid-item max-w-44">
            <label>Unable to Perform the Following IADLs</label>
          </div>
          <div class="grid-item">
            {{ unableToPerformTheFollowingIADLsDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Unable to Perform the Following ADLs</label>
          </div>
          <div class="grid-item">
            {{ unableToPerformTheFollowingADLsDisplay }}
          </div>
          <div class="grid-item">
            <label>Homebound</label>
          </div>
          <div class="grid-item">
            {{ homeboundDisplay }}
          </div>
          <div class="grid-item">
            <label>DME</label>
          </div>
          <div class="grid-item">
            {{ dmeDisplay }}
          </div>
          <div class="grid-item">
            <label>Procedural Support</label>
          </div>
          <div class="grid-item">
            {{ proceduralSupportDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Walks 2 Blocks or 1 Flight of Stairs</label>
          </div>
          <div class="grid-item">
            {{ walksTwoBlocksOrOneFlightOfStairsDisplay }}
          </div>
          <div class="grid-item">
            <label>Additional Information</label>
          </div>
          <div class="grid-item">
            {{ additionalInformationDisplay }}
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Edit physical function assessment'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="togglePhysicalFunctionAssessmentEdit"
        />
      </div>
    </div>
  </div>
  <EditPhysicalFunctionAssessment
    v-if="showEditModal"
    :patient-id="patientId"
    @close="togglePhysicalFunctionAssessmentEdit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import EditPhysicalFunctionAssessment from './EditPhysicalFunctionAssessment/EditPhysicalFunctionAssessment.vue'

export default defineComponent({
  components: {
    EditPhysicalFunctionAssessment,
    TMSecondaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
