<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Goal"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5">
      <div class="flex flex-col space-y-3">
        <label>Goal Title</label>
        <TMFilterDropdown
          class="w-full"
          name="goal-title"
          :initial-value="dirtyModel.goalRefId"
          :options="goalTitleOptions"
          option-label="titleDisplay"
          option-value="goalRefId"
          @update:model-value="((v:string) => updateTitle(v))"
        />
        <label v-if="showOtherTitle">Other</label>
        <TInputText
          v-if="showOtherTitle"
          v-model="dirtyModel.freeTextTitle"
          name="goal-title-free-text"
          @update:model-value="dirtyModel.freeTextTitle"
        />
        <label>Associated Domain</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="goal-domain"
          :initial-value="dirtyModel.domainId ?? ''"
          :options="domainOptions"
          option-label="label"
          option-value="value"
          @update:model-value="((v:string) => dirtyModel.domainId = v)"
        />
        <label>Assessment</label>
        <TTextarea
          v-model="dirtyModel.details"
          name="goal-detail"
          @update:model-value="dirtyModel.details"
        />
        <label>Goal Timeline</label>
        <TInputText
          v-model="dirtyModel.timeline"
          name="goal-timeline"
          @update:model-value="dirtyModel.timeline"
        />
        <label>Associated Playbook(s)</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="goal-playbooks"
          :initial-value="dirtyModel.playbooks"
          :multiple="true"
          :options="playbookOptions"
          option-label="title"
          option-value="pathwayId"
          @update:model-value="setPlaybooksObj"
        />
        <label>Associated Task(s)</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="goal-tasks"
          :multiple="true"
          :initial-value="dirtyModel.tasks"
          :options="taskOptions"
          option-label="title"
          option-value="taskId"
          @update:model-value="setTaskObj"
        />
      </div>
    </div>

    <template #actions>
      <div class="flex justify-between w-full">
        <TMSecondaryButton
          label="Create New Evergreen Tasks"
          @click="toggleCreateNewEvergreenTasksModal"
        />
        <div class="w-1/3 flex flex-row justify-end space-x-4">
          <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
          <TMPrimaryButton label="Save" name="save" @click="save" />
        </div>
      </div>
    </template>
    <AddTaskModal
      :type="TaskType.Task"
      title="Create New Evergreen Tasks"
      :is-visible="showCreateNewEvergreenTasksModal"
      :pathway-id="evergreenPathway?.pathwayId"
      :goal-id="currentGoal.goalId"
      @close="showCreateNewEvergreenTasksModal = false"
      @map-tasks-to-goal="mapCreatedEvergreenTasksToGoal"
    />
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { PropType, defineComponent } from 'vue'
import AddTaskModal from '@/legacy/components/patient/pathways/AddTaskModal.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TMFilterDropdown from '@/legacy/nashville/dropdown/TMFilterDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { Goal } from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TDropdown,
    TMFilterDropdown,
    TTextarea,
    TInputText,
    AddTaskModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    currentGoal: {
      type: Object as PropType<Goal>,
      required: true,
    },
    goalPathways: {
      type: Array as PropType<Pathway[]>,
      default: () => [],
    },
    goalTasks: {
      type: Array as PropType<Task[]>,
      default: () => [],
    },
  },
  emits: ['close', 'refetchGoal'],
  setup,
})
</script>
<style lang="scss"></style>
