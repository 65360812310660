import apiStore, { transformListTo } from '@/legacy/store/modules/apiBuilder'
import { Communication } from '@/legacy/types/communications/communications'
import { Entity } from '@/legacy/types/entities/entities'
import { Outcome } from '@/legacy/types/outcomes'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import { ThymelineItem } from '@/legacy/types/patients/thymelines'
import { TransitionOfCare } from '@/legacy/types/patients/transitionsOfCare'

// Outcomes Thymeline ---------------------
export const useOutcomesThymelineApi = apiStore<Outcome>(
  'outcomesThymelineApi',
  '/api/tasks/outcomes',
  {
    transformData: (d: { data: Outcome[] }) => d,
    params: {
      parts: ['outcome_validation'],
    },
  }
)

// Communications History Thymelines ---------------------
// USED IN SIDEBAR
export const useIncompleteCommsHistoryThymelineApi = apiStore<Communication>(
  'incompleteCommunicationHistoryThymelineApi',
  '/api/communications',
  {
    transformData: (d: { data: Communication[] }) => d,
  }
)

export const useCompletedCommsHistoryThymelineApi = apiStore<Communication>(
  'completedCommunicationHistoryThymelineApi',
  '/api/communications',
  {
    transformData: (d: { data: Communication[] }) => d,
  }
)

// USE FOR PATIENT THYMELINE ONLY
// Overrides List functionality

export const useOpenThymelineApi = apiStore<ThymelineItem, string[]>(
  'openThymelineApi',
  '/api/thymeline',
  {
    transformData: transformListTo<ThymelineItem>('itemId'),
  }
)

export const useInProgressThymelineApi = apiStore<ThymelineItem, string[]>(
  'inProgressThymelineApi',
  '/api/thymeline',
  {
    transformData: transformListTo<ThymelineItem>('itemId'),
  }
)

export const useBlockedThymelineApi = apiStore<ThymelineItem, string[]>(
  'blockedThymelineApi',
  '/api/thymeline',
  {
    transformData: transformListTo<ThymelineItem>('itemId'),
  }
)

export const useCompletedThymelineApi = apiStore<ThymelineItem, string[]>(
  'completedThymelineApi',
  '/api/thymeline',
  {
    transformData: transformListTo<ThymelineItem>('itemId'),
  }
)

export const useCancelledThymelineApi = apiStore<ThymelineItem, string[]>(
  'cancelledThymelineApi',
  '/api/thymeline',
  {
    transformData: transformListTo<ThymelineItem>('itemId'),
  }
)

export const useThymelineSubtaskApi = apiStore<Subtask>(
  'thymelineSubtaskApi',
  '/api/subtasks'
)

export const useThymelineCommunicationApi = apiStore<Communication>(
  'thymelineCommunicationApi',
  '/api/communications'
)

export const useThymelineTransitionOfCareApi = apiStore<TransitionOfCare>(
  'thymelineTransitionOfCareApi',
  '/api/transitions_of_care'
)

export const useThymelineEntityApi = apiStore<Entity>(
  'thymelineEntityApi',
  '/api/entities'
)
