<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Details"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5">
      <div class="flex flex-col space-y-3">
        <label>Tier</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="tier-level"
          :model-value="dirtyTier"
          :options="tierOptions"
          option-label="label"
          option-value="value"
          :disabled="!canEditPatientAcuityScore"
          @update:model-value="(v) => (dirtyTier = v)"
        />
        <label>Domain</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="patient-domains"
          :options="domainDropdownOptions"
          :multiple="true"
          :model-value="dirtyDomains"
          option-label="label"
          option-value="value"
          :disabled="!canEditDomains"
          :selection-limit="3"
          @update:model-value="(v) => (dirtyDomains = v)"
        />
        <label>Treatment Status</label>
        <StatusDropdown
          name="patient-care-plan-treatment"
          class="border-none"
          :model-value="`${dirtyTreatmentStatus.treatmentStatus}__${dirtyTreatmentStatus.treatmentSubstatus}`"
          :status-options="treatmentStatusOptions"
          @update:model-value="handleStatusInput('treatment', $event)"
        />
        <label>Treatment Intent</label>
        <StatusDropdown
          name="patient-care-plan-treatment-intent"
          class="border-none"
          :model-value="dirtyTreatmentIntent.treatmentIntent ?? ''"
          :status-options="treatmentIntentOptions"
          @update:model-value="handleIntentInput"
        />
        <label>Program status</label>
        <StatusDropdown
          name="patient-care-plan-program"
          class="border-none"
          :model-value="`${dirtyProgramStatus.programStatus}__${dirtyProgramStatus.programSubstatus}`"
          :status-options="programStatusOptions"
          @update:model-value="handleStatusInput('program', $event)"
        />
      </div>
    </div>
    <template #actions>
      <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
      <TMPrimaryButton label="Save" name="save" @click="save" />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import StatusDropdown from '@/legacy/components/patient/StatusDropdown.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TDropdown,
    StatusDropdown,
    TMPrimaryButton,
    TMSecondaryButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup,
})
</script>

<style lang="scss" scoped></style>
