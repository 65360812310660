import { reduce } from 'lodash'
import { toIdMap } from '@/legacy/libs/store'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { logAndShowErrorMsg } from '@/legacy/store/modules/notification'
import {
  useThymelineCommunicationApi,
  useThymelineEntityApi,
  useThymelineSubtaskApi,
  useThymelineTransitionOfCareApi,
} from '@/legacy/store/modules/thymelines'
import { DataType } from '@/legacy/types/api/apiBuilder'
import {
  Communication,
  ALL_COMMUNICATION_PARTS,
} from '@/legacy/types/communications/communications'
import { UUID } from '@/legacy/types/global/base'
import {
  ThymelineItem,
  ThymelineItemObjectTypes,
  ThymelineItemType,
} from '@/legacy/types/patients/thymelines'

export const perPage = 10
export const columns = [
  {
    field: 'thymeline',
    header: 'Thymeline',
  },
]

// Outcomes Thymelines
/**
 * Function that returns params object for the outcomes thymeline
 * @param patientId
 */
export function outcomesParams(patientId: string) {
  return {
    filter_member_ids: [patientId],
    parts: ['outcome_validation'],
    sort_by: 'createdAt,desc',
  }
}

// Communications Thymelines
/**
 * Function that returns params object for general comms thymeline
 * @param patientId
 */
export function commsSharedParams(patientId: string) {
  return {
    filter_patient_ids: [patientId],
    parts: ALL_COMMUNICATION_PARTS,
  }
}

// Communications History Thymelines
/**
 * Function that returns params object for completed comms history thymeline
 * @param patientId
 * @param filteredIds
 */
export function completedCommsHistoryParams(
  patientId: string,
  filteredIds: string[]
) {
  return {
    ...commsSharedParams(patientId),
    filter_is_completed: true,
    filter_communication_ids: filteredIds,
  }
}

/**
 * Function that returns params object for incomplete comms history thymeline
 * @param patientId
 * @param filteredIds
 */
export function incompleteHistoryCommsParams(
  patientId: string,
  filteredIds: string[]
) {
  return {
    ...commsSharedParams(patientId),
    filter_is_completed: false,
    filter_communication_ids: filteredIds,
  }
}

/**
 * Create a dictionary of item ids sorted by item type
 * @param items unsorted ThymelineItems
 * returns { [key in ThymelineItemType]: UUID[] } item ids sorted by item type
 */
export function getThymelineItemIds(items: ThymelineItem[]) {
  return reduce(
    items,
    (acc, { itemType, itemId }: ThymelineItem) => {
      if (acc[itemType]) {
        acc[itemType].push(itemId)
      }
      return acc
    },
    {
      [ThymelineItemType.TRANSITION_OF_CARE]: [],
      [ThymelineItemType.SUBTASK]: [],
      [ThymelineItemType.COMMUNICATION]: [],
    } as { [key in ThymelineItemType]: string[] }
  )
}

/**
 * Get Entities for Listed communications.
 * Save to Communications Store in communicationPersons
 * @param communications
 */
export async function getCommunicationEntities(
  communications: Communication[]
) {
  const speakingWithIds = communications.reduce(
    (acc: string[], cur: Communication) => {
      cur.callDisposition?.speakingWithPersonId &&
      !acc.includes(cur.callDisposition?.speakingWithPersonId)
        ? acc.push(cur.callDisposition.speakingWithPersonId)
        : cur.plannedCall?.calleeEntityId &&
          !acc.includes(cur.plannedCall?.calleeEntityId)
        ? acc.push(cur.plannedCall.calleeEntityId)
        : null
      return acc
    },
    []
  )
  if (speakingWithIds.length) {
    const speakingWithEntities = await useThymelineEntityApi().list({
      params: {
        filter_entity_ids: speakingWithIds,
        parts: ['person'],
      },
    })
    useCommunicationsStore().updateCommunicationPersons(
      speakingWithEntities.data
    )
  }
}

/**
 * Override list call for thymeline store.
 * First calls original list function to return all item ids and types
 * Then calls supplemental apis to return items
 * Then combines all into a singular list, stored in data
 * @param originalStore
 * @param originalStore.list
 * @param originalStore.setSuccess
 * @param originalStore.setError
 * @param originalStore.isOverloaded
 */
export function overloadSearchServiceList(originalStore: {
  list: (...args: any) => any
  setSuccess: (...args: any) => any
  setError: (...args: any) => any
  isOverloaded?: boolean
}) {
  // IF THE STORE IS ALREADY OVERLOADED, DON'T REDO THIS
  // CAN CAUSE SPIRALING DDOSING
  if (originalStore.isOverloaded) {
    return
  }
  // store original function so it can be called as super later
  const _superThymelineList = originalStore.list
  const _superThymelineUpdate = originalStore.setSuccess
  originalStore.isOverloaded = true

  // Overwrite this function so we only save the data once
  // (directly at the bottom rather than through the built-in list call implicitly)
  // triggering proper reactivity from Vue
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  originalStore.setSuccess = function () {}

  originalStore.list = async function (...args): Promise<any[]> {
    let thymelineItems
    let thymelineItemIds
    // Get list of thymeline items
    try {
      thymelineItems = await _superThymelineList(...args)
      thymelineItemIds = getThymelineItemIds(thymelineItems.data)
    } catch (e) {
      originalStore.setError(e)
      logAndShowErrorMsg(e, 'Failed to fetch thymeline items.')
      return thymelineItems
    }

    // Get Subtasks
    let subtaskItems
    if (thymelineItemIds[ThymelineItemType.SUBTASK].length) {
      try {
        subtaskItems = await useThymelineSubtaskApi().list({
          params: {
            filter_subtask_ids: thymelineItemIds[ThymelineItemType.SUBTASK],
          },
        })
      } catch (err) {
        logAndShowErrorMsg(err, 'Failed to fetch thymeline subtask details.')
      }
    }

    // Get Communications
    let communicationItems
    if (thymelineItemIds[ThymelineItemType.COMMUNICATION].length) {
      try {
        communicationItems = await useThymelineCommunicationApi().list({
          params: {
            filter_communication_ids:
              thymelineItemIds[ThymelineItemType.COMMUNICATION],
            parts: ALL_COMMUNICATION_PARTS,
          },
        })
        // Get entities for people
        if (communicationItems?.data?.length) {
          void getCommunicationEntities(communicationItems.data)
        }
      } catch (err) {
        logAndShowErrorMsg(
          err,
          'Failed to fetch thymeline communication details.'
        )
      }
    }

    // Get Transitions Of Care
    let tocItems
    if (thymelineItemIds[ThymelineItemType.TRANSITION_OF_CARE].length) {
      try {
        tocItems = await useThymelineTransitionOfCareApi().list({
          params: {
            filter_transition_of_care_ids:
              thymelineItemIds[ThymelineItemType.TRANSITION_OF_CARE],
          },
        })
      } catch (err) {
        logAndShowErrorMsg(err, 'Failed to fetch thymeline TOC details.')
      }
    }

    // Consolidate items back into singular list
    const itemIdMap: {
      [key: UUID]: ThymelineItemObjectTypes
    } = {
      ...toIdMap(tocItems?.data ?? [], 'transitionOfCareId'),
      ...toIdMap(communicationItems?.data ?? [], 'communicationId'),
      ...toIdMap(subtaskItems?.data ?? [], 'subtaskId'),
    }
    thymelineItems.data.forEach(
      (ti: ThymelineItem) => (ti.item = itemIdMap[ti.itemId])
    )
    _superThymelineUpdate(thymelineItems, DataType.PLURAL)
    return thymelineItems
  }
}
