<template>
  <div>
    <div v-if="isSubtaskSidebar">
      <h5
        v-if="currentSubtask.customSubject && !isEditingCustomSubject"
        class="my-4 cursor-pointer"
        @click="isEditingCustomSubject = true"
      >
        {{ currentSubtask.customSubject }}
      </h5>
      <div
        v-if="currentSubtask.customSubject && isEditingCustomSubject"
        class="my-4"
      >
        <TInputText
          label="Subject"
          class="max-w-sm"
          :max-length="MAX_CHAR_SUBTASK_SUBJECT_LINE"
          name="subtask-custom-subject-line"
          :model-value="subjectLine ?? ''"
          @update:model-value="setSubjectLine"
          @blur="submitCustomSubject"
        />
      </div>

      <SubtaskRow
        class="mb-2"
        :subtask-task="currentTask ?? undefined"
        :subtask="currentSubtask"
        :is-overdue="isOverdue"
        :display-type="SubtaskRowDisplayType.SUBTASK_DETAIL"
        @set-status="setSubtaskTasksIfCompleted"
        @open-reattempt-modal="openReattemptModal"
        @open-sub-status-modal="openSubStatusModal"
        @callback-fn="callbackFn"
      />
      <SubtaskDescription
        v-if="currentSubtask.description"
        :description="currentSubtask.description"
      />
      <SubtaskStatusReason :subtask="currentSubtask" />
      <div v-for="task in subtaskTasks" :key="task.taskId">
        <div v-if="showOutcomes" class="flex justify-between items-center my-2">
          <span
            ><b>{{ task.title }}</b> Task Complete</span
          >
          <LegacyTButton
            value="Outcomes"
            icon="heroicons:chart-bar-square-solid"
            icon-fill="info"
            icon-position="left"
            type="whiteAndGray"
            :size="ButtonSize.MD"
            class="rounded-full border"
            @click.stop="outcomesModalTask = task"
          />
        </div>
      </div>
      <PatientOutcomesModal
        v-if="!!outcomesModalTask && outcomes && possibleOutcomes"
        :current-outcome-note="outcomesModalTask.outcomeNote ?? ''"
        :current-outcome-quotes="outcomesModalTask.outcomeQuotes ?? ''"
        :current-outcomes="outcomes"
        :current-task-id="outcomesModalTask.taskId"
        :possible-outcomes="possibleOutcomes"
        @click.stop
        @hide-modal="outcomesModalTask = null"
      />
    </div>
    <div v-if="!isSubtaskSidebar">
      <h3
        v-if="currentSubtask.customSubject && !isEditingCustomSubject"
        class="my-4 cursor-pointer"
      >
        {{ currentSubtask.customSubject }}
      </h3>
      <div
        v-if="currentSubtask.customSubject && isEditingCustomSubject"
        class="my-4"
      >
        <TInputText
          label="Subject"
          class="max-w-sm"
          :max-length="MAX_CHAR_SUBTASK_SUBJECT_LINE"
          name="subtask-custom-subject-line"
          :model-value="subjectLine ?? ''"
          @update:model-value="setSubjectLine"
          @blur="submitCustomSubject"
        />
      </div>
      <SubtaskRow
        class="mb-2"
        :subtask-task="currentTask ?? undefined"
        :subtask="currentSubtask"
        :is-overdue="isOverdue"
        :display-type="SubtaskRowDisplayType.SUBTASK_DETAIL"
        @open-reattempt-modal="openReattemptModal"
        @open-sub-status-modal="openSubStatusModal"
        @callback-fn="callbackFn"
      />
      <SubtaskDescription
        v-if="currentSubtask.description"
        :description="currentSubtask.description"
      />
    </div>
    <TMAutosaveInput
      :disabled="isClosedInError"
      :data="currentSubtask.notes"
      :api-call="submitNotes"
      :warn-unsaved-changes="true"
      :save-state="subtaskSaveState"
      placeholder="Enter notes here"
      :type="InputType.TEXTAREA"
    />
    <SubtaskOutreach
      v-if="subtaskTemplateById && isSubtaskSidebar"
      :subtask="subtask"
      :subtask-template="subtaskTemplateById"
      :disabled="!!renderOutreachTooltip"
      :tooltip="renderOutreachTooltip"
    />
    <div v-if="!!hyperlinks?.length" class="mb-3">
      <label class="font-bold">Hyperlinks</label>
      <ul class="list-disc list-inside">
        <li v-for="(link, index) in hyperlinks" :key="link + index">
          <a :href="link" target="_blank" rel="noreferrer noopener">
            {{ linkDisplay(link) }}
          </a>
        </li>
      </ul>
    </div>

    <SubtaskReattemptHistory
      v-if="!!subtask.hideDatetime"
      :subtask="currentSubtask"
    />

    <CommunicationHistory :linked-communication-ids="linkedCommunicationIds" />

    <SubtaskFormsort
      v-if="subtaskTemplateById"
      :key="subtask.subtaskId"
      :subtask="currentSubtask"
      :subtask-template="subtaskTemplateById"
    />
    <SubtaskReattemptModal
      :subtask="subtask"
      :is-open="showReattemptModal"
      @close="closeReattemptModal"
      @callback-fn="callbackFn"
    />
    <SubtaskSubstatusModal
      v-if="showSurfacedStatusReason && subtaskStatus"
      :subtask="subtask"
      :status="subtaskStatus"
      :is-open="showSubStatusModal"
      @close="showSubStatusModal = false"
      @callback-fn="callbackFn"
    />
    <SubtaskAutogeneratedNotes :subtask="currentSubtask" />
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { InputType } from '@thyme/nashville/src/types/inputs'
import capitalize from 'lodash/capitalize'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import CommunicationHistory from '@/legacy/components/patient/activity/CommunicationHistory.vue'
import SubtaskAutogeneratedNotes from '@/legacy/components/patient/pathways/SubtaskAutogeneratedNotes.vue'
import SubtaskFormsort from '@/legacy/components/patient/pathways/SubtaskFormsort.vue'
import SubtaskOutreach from '@/legacy/components/patient/pathways/SubtaskOutreach.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import SubtaskStatusReason from '@/legacy/components/patient/pathways/SubtaskStatusReason/SubtaskStatusReason.vue'
import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import { normalize } from '@/legacy/libs/store'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  useSubtasksStore,
  useSubtaskTaskApi,
} from '@/legacy/store/modules/subtasks'
import {
  useTasksStore,
  useTaskApi,
  useTaskOutcomesApi,
} from '@/legacy/store/modules/tasks'

import { newSaveState, SaveState } from '@/legacy/types/api/api'
import { Outcome, OutcomeReference } from '@/legacy/types/outcomes'
import { TaskStatus } from '@/legacy/types/pathways/pathways'
import {
  MAX_CHAR_SUBTASK_SUBJECT_LINE,
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
  SubtaskTemplate,
} from '@/legacy/types/pathways/subtasks'
import { Task } from '@/legacy/types/pathways/tasks'
import PatientOutcomesModal from '../PatientOutcomesModal.vue'
import {
  isAssignedToSelf,
  isSubtaskOverdue,
  isClosedSubtask,
} from './lib/subtask'
import SubtaskDescription from './SubtaskDescription.vue'
import SubtaskReattemptHistory from './SubtaskReattemptHistory.vue'
import SubtaskReattemptModal from './SubtaskReattemptModal.vue'
export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TInputText,
    SubtaskAutogeneratedNotes,
    SubtaskFormsort,
    CommunicationHistory,
    SubtaskRow,
    TMAutosaveInput,
    LegacyTButton,
    PatientOutcomesModal,
    SubtaskReattemptModal,
    SubtaskOutreach,
    SubtaskDescription,
    SubtaskReattemptHistory,
    SubtaskStatusReason,
  },
  props: {
    isSubtaskSidebar: { type: Boolean, required: false, default: true },
    subtask: { type: Object as PropType<Subtask>, required: true },
  },
  emits: ['callbackFn'],
  setup(props, context) {
    const GITBOOK_PREFIX =
      'https://app.gitbook.com/o/-MbN892xoO-71tzzZ9Yf/s/-MbJgxdUrqECGo3c61ko/'

    const subtaskSaveState = ref<SaveState>(newSaveState())
    const { patientId } = storeToRefs(usePatientStore())

    const { data: tasks } = storeToRefs(useTaskApi())
    const { selfEntity } = storeToRefs(useProfileStore())

    const route = useRoute()

    const showReattemptModal = ref(false)
    const showSubStatusModal = ref(false)
    const isEditingCustomSubject = ref(false)
    const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
    const subtaskStatus = ref<SubtaskStatus | null>(null)
    const currentTask = computed(() =>
      tasks.value ? tasks.value[currentSubtask.value.taskIds[0]] : null
    )
    const currentSubtask = computed(() => props.subtask)
    const hyperlinks = computed(
      () =>
        currentSubtask.value.hyperlinks?.filter((hyperlink) => !!hyperlink) ??
        []
    )
    const isClosedInError = computed(
      () => currentSubtask.value?.status === SubtaskStatus.CLOSED_IN_ERROR
    )

    const subtaskTemplateById = ref<SubtaskTemplate | null>(null)

    const isOverdue = computed(() => {
      if (currentSubtask.value) {
        return (
          isSubtaskOverdue(currentSubtask.value) &&
          !isClosedSubtask(currentSubtask.value)
        )
      }
      return false
    })

    const assignedToSelf = computed(() =>
      isAssignedToSelf(currentSubtask.value, selfEntity.value)
    )

    const renderOutreachTooltip = computed(() => {
      if (!assignedToSelf.value) {
        return 'You must be the assignee of the subtask to use this functionality'
      }
      if (isClosedSubtask(props.subtask)) {
        return 'Texts cannot be sent for closed subtasks'
      }
      return ''
    })

    const subjectLine = ref<string | null>(currentSubtask.value.customSubject)

    /**
     * Callback function passed from parent components:
     * - SubtaskSidebar.vue:
     * - fetchSubtask() = refetch subtask and thymeline
     * - SubtaskSidebarData.vue
     * - refetchPostUpdate() = refetch linked subtasks and thymelines
     * @param subtaskIds
     */
    function callbackFn(subtaskIds: string[]) {
      context.emit('callbackFn', subtaskIds)
    }

    /**
     *
     * @param notes
     */
    async function submitNotes(notes: string) {
      await useSubtasksStore().updateSubtask(
        currentSubtask.value.subtaskId,
        {
          notes: notes,
          ...(currentSubtask.value?.status === SubtaskStatus.OPEN_ASSIGNED
            ? { status: SubtaskStatus.IN_PROGRESS }
            : currentSubtask.value?.status === SubtaskStatus.OPEN_UNASSIGNED
            ? {
                status: SubtaskStatus.IN_PROGRESS,
                responsibleStaffId: selfEntity.value?.entityId,
              }
            : {}),
        },
        {},
        subtaskSaveState.value
      )
    }

    /**
     * Set customSubject line text
     * @param val
     */
    function setSubjectLine(val: string) {
      subjectLine.value = val
      if (subjectLine.value.length === 0) {
        subjectLine.value = null
      }
    }

    /**
     * Function to set subjectLine ref
     *
     */
    async function submitCustomSubject() {
      isEditingCustomSubject.value = false
      await useSubtasksStore().updateSubtask(currentSubtask.value.subtaskId, {
        customSubject: subjectLine.value,
        ...(currentSubtask.value?.status === SubtaskStatus.OPEN_ASSIGNED
          ? { status: SubtaskStatus.IN_PROGRESS }
          : currentSubtask.value?.status === SubtaskStatus.OPEN_UNASSIGNED
          ? {
              status: SubtaskStatus.IN_PROGRESS,
              responsibleStaffId: selfEntity.value?.entityId,
            }
          : {}),
      })
    }

    /**
     *
     * @param hyperlink
     */
    function linkDisplay(hyperlink: string) {
      let display = null
      if (hyperlink.startsWith(GITBOOK_PREFIX)) {
        const page_title = hyperlink
          .substring(hyperlink.lastIndexOf('/') + 1)
          .split('-')
          .map((word) => capitalize(word))
          .join(' ')
        display = `GitBook: ${page_title}`
      }
      return display ?? hyperlink
    }

    /**
     * Function to set subtask's template
     */
    async function fetchSubtaskTemplate() {
      if (!subtaskTemplateById.value) {
        const results = await useSubtasksStore().getSubtaskTemplatesById({
          filter_variant_ids: [currentSubtask.value?.subtaskVariantId],
        })

        if (results?.data.length) {
          subtaskTemplateById.value = results.data[0]
        }
      }
    }

    watch(currentSubtask, async () => await fetchSubtaskTemplate())
    watch(
      currentSubtask,
      async () =>
        await setSubtaskTasksIfCompleted({
          status: currentSubtask.value.status,
        })
    )

    onMounted(async () => {
      await fetchSubtaskTemplate()
      await setSubtaskTasksIfCompleted({ status: currentSubtask.value.status })
    })

    const linkedCommunicationIds = computed(() =>
      currentSubtask.value?.communicationMaps
        ? normalize(currentSubtask.value?.communicationMaps, 'communicationId')
        : []
    )

    const outcomesModalTask = ref<Task | null>(null)
    const subtaskTasks = ref<Task[] | null>(null)
    const possibleOutcomes = ref<OutcomeReference[] | null>(null)
    const outcomes = ref<Outcome[] | null>(null)

    /**
     * Function to hide/close reattempt modal
     */
    function closeReattemptModal() {
      showReattemptModal.value = false
    }
    /**
     * Function to show/open reattempt modal
     */
    function openReattemptModal() {
      showReattemptModal.value = true
    }
    /**
     * Function to show/open SubStatusModal modal. Subtask is unused
     * but is needed to properly handle openSubStatusModal
     * @param _subtask
     * @param status
     */
    function openSubStatusModal(_subtask: Subtask, status: SubtaskStatus) {
      showSubStatusModal.value = true
      subtaskStatus.value = status
    }

    /**
     * Function to get possible outcomes for subtask's parent task
     * @param taskTemplateId
     */
    async function getPossibleOutcomes(taskTemplateId: null | string = null) {
      const fetched = await useTasksStore().getAllTaskTemplates({
        ...(taskTemplateId ? { filter_variant_ids: [taskTemplateId] } : {}),
      })
      const taskTemplate = fetched.data[0]
      if (taskTemplate) {
        return taskTemplate.possibleOutcomes
      }
      return null
    }

    /**
     * Function to retrieve all task's outcomes
     */
    async function getTaskOutcomes() {
      const taskOutcomes = await useTaskOutcomesApi().list({
        params: {
          filter_task_ids: props.subtask.taskIds,
          filter_member_ids: [route.params.patientId],
          parts: ['outcome_validation'],
        },
      })
      return taskOutcomes.data
    }

    /**
     * Function to retrieve all subtasks's parent tasks
     */
    async function getSubtaskTasks() {
      const subtaskTasks = await useSubtaskTaskApi().list({
        params: {
          filter_task_ids: props.subtask.taskIds,
          filter_member_ids: [route.params.patientId],
        },
      })
      return subtaskTasks.data
    }

    /**
     * Function to trigger open the outcomes modal if all subtasks of parent tasks are completed
     * @param root0
     * @param root0.status
     * @param root0.openModal
     */
    async function setSubtaskTasksIfCompleted({
      status,
      openModal = false,
    }: {
      status: string
      openModal?: boolean
    }) {
      if (status === SubtaskStatus.COMPLETED) {
        subtaskTasks.value = await getSubtaskTasks()
        if (subtaskTasks.value?.length) {
          if (subtaskTasks.value[0]) {
            // IMPROVEME: The code here is assuming a subtask will only ever have 1 parent task
            const task = subtaskTasks.value[0]
            possibleOutcomes.value = await getPossibleOutcomes(
              task.taskVariantId
            )
          }
        }
        if (possibleOutcomes.value) {
          outcomes.value = await getTaskOutcomes()
        }
        if (openModal) {
          return currentTask.value?.subtasks?.every(
            (subtask) => subtask.status === SubtaskStatus.COMPLETED
          ) && possibleOutcomes.value?.length
            ? (outcomesModalTask.value = currentTask.value)
            : null
        }
      }
    }

    const showOutcomes = computed(
      () =>
        !!possibleOutcomes.value?.length ??
        subtaskTasks.value?.every(
          (task) => task.status !== TaskStatus.COMPLETED
        )
    )

    return {
      InputType,
      submitCustomSubject,
      isEditingCustomSubject,
      setSubjectLine,
      MAX_CHAR_SUBTASK_SUBJECT_LINE,
      subjectLine,
      possibleOutcomes,
      outcomes,
      callbackFn,
      SubtaskRowDisplayType,
      useSubtasksStore,
      openReattemptModal,
      closeReattemptModal,
      showReattemptModal,
      openSubStatusModal,
      showSubStatusModal,
      showSurfacedStatusReason,
      subtaskStatus,
      currentTask,
      hyperlinks,
      currentSubtask,
      isClosedInError,
      isOverdue,
      linkDisplay,
      linkedCommunicationIds,
      patientId,
      submitNotes,
      subtaskTemplateById,
      outcomesModalTask,
      subtaskTasks,
      setSubtaskTasksIfCompleted,
      showOutcomes,
      assignedToSelf,
      isClosedSubtask,
      renderOutreachTooltip,
      subtaskSaveState,
      ButtonSize,
    }
  },
})
</script>
