<template>
  <div class="overflow-visible card-style pt-4 w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <div class="grid-item">
            <label>Fall in the last 12 months</label>
          </div>
          <div class="grid-item">
            {{ fallInTheLastTwelveMonthsDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Unsteady With Standing or Walking</label>
          </div>
          <div class="grid-item">
            {{ unsteadyWithStandingOrWalkingDisplay }}
          </div>
          <div class="grid-item">
            <label>Fear of Falling</label>
          </div>
          <div class="grid-item">
            {{ fearOfFallingDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Uses DME to Assist with Ambulation</label>
          </div>
          <div class="grid-item">
            {{ usesDMEToAssistWithAmbulationDisplay }}
          </div>
          <div class="grid-item">
            <label>Peripheral Neuropathy</label>
          </div>
          <div class="grid-item">
            {{ peripheralNeuropathyDisplay }}
          </div>
          <div class="grid-item">
            <label>Anticoagulation</label>
          </div>
          <div class="grid-item">
            {{ anticoagulationDisplay }}
          </div>
          <div class="grid-item">
            <label>Sedating Medications</label>
          </div>
          <div class="grid-item">
            {{ sedatingMedicationsDisplay }}
          </div>
          <div class="grid-item">
            <label>Additional Information</label>
          </div>
          <div class="grid-item">
            {{ additionalInformationDisplay }}
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Edit fall risk'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="toggleFallRiskEdit"
        />
      </div>
    </div>
  </div>
  <EditFallRisk
    v-if="showEditModal"
    :patient-id="patientId"
    @close="toggleFallRiskEdit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import EditFallRisk from './EditFallRisk/EditFallRisk.vue'

export default defineComponent({
  components: {
    EditFallRisk,
    TMSecondaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
