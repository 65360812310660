<template>
  <div class="relative p-3">
    <div class="flex flex-col p-3">
      <h1
        class="flex justify-between text-nash-purple600"
        data-cy="patient-name"
      >
        <span>{{
          formatNameWithPreferredName(
            person?.firstName,
            person?.lastName,
            person?.preferredName
          )
        }}</span>
        <span class="items-center flex">
          <TMTertiaryButton
            name="refresh"
            icon="refresh"
            size="sm"
            class="-mt-1"
            inline
            @click="refresh"
          />
        </span>
      </h1>
    </div>
    <TSpinner v-if="isLoading" name="patient-sidebar" :partial-page="true" />

    <div v-else-if="person && patient" class="container">
      <PatientDetails />
      <MedicalDetails :patient="patient" />
      <PatientContact />
      <OtherContacts />
      <PatientConsents :patient="patient" />
      <PatientInsurances />
    </div>
    <div v-else>Error loading patient details.</div>
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

import MedicalDetails from '@/legacy/components/patient/sidebar/MedicalDetails.vue'
import OtherContacts from '@/legacy/components/patient/sidebar/OtherContacts.vue'
import PatientConsents from '@/legacy/components/patient/sidebar/PatientConsents.vue'
import PatientContact from '@/legacy/components/patient/sidebar/PatientContact.vue'

import { formatNameWithPreferredName } from '@/legacy/libs/format'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { programStatusOptions } from '@/legacy/types/patients/patients'
import PatientDetails from '@/pages/PatientProfile/PatientSidebar/PatientDetails/PatientDetails.vue'
import PatientInsurances from '@/pages/PatientProfile/PatientSidebar/PatientInsurances/PatientInsurances.vue'

export default defineComponent({
  components: {
    TSpinner,
    MedicalDetails,
    PatientDetails,
    PatientContact,
    OtherContacts,
    PatientInsurances,
    TMTertiaryButton,
    PatientConsents,
  },
  setup() {
    const { patient, person, isLoading } = storeToRefs(usePatientStore())

    /**
     *
     */
    async function refresh() {
      if (patient.value) {
        await usePatientStore().fetchPatient(patient.value.entityId)
      }
    }

    return {
      formatNameWithPreferredName,
      isLoading,
      person,
      patient,
      refresh,
      programStatusOptions,
    }
  },
})
</script>
