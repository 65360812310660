<template>
  <Calendar
    placeholder="mm/dd/yyyy"
    show-icon
    :disabled-dates="holidays"
    :min-date="minDateToday"
    :select-other-months="true"
    :disabled-days="unselectableDaysOfWeek"
    :model-value="dateValue ?? undefined"
    v-bind="$attrs"
    @update:model-value="setDateValue"
  />
</template>

<script lang="ts">
import Calendar from 'primevue/calendar'
import { defineComponent } from 'vue'
import setup from './controller'

export default defineComponent({
  components: { Calendar },
  props: {
    dateValue: {
      type: Date,
      default: null,
    },
    minDateToday: {
      type: Date,
      default: () => new Date(),
    },
    unselectableDaysOfWeek: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup,
})
</script>
