<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    :size="ModalSize.SM"
    class="text-nash-neutral900"
    @close="close"
  >
    <div>
      <div class="flex justify-between">
        <h2>Reattempt Subtask</h2>
        <LegacyTButton
          name="close-reattempt-modal"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
      <div class="flex flex-col w-full space-y-5 pt-5">
        <div class="flex w-full">
          <div class="border py-2 px-3 w-full rounded">
            <SubtaskRow
              :id="`subtask-${subtask.subtaskId}`"
              :key="subtask.subtaskId"
              :display-type="SubtaskRowDisplayType.STATUS_UPDATE_MODAL"
              :subtask="subtask"
            />
          </div>
        </div>
        <div v-if="showDatePicker" class="flex flex-col">
          <p class="text-neutral-800 pb-2">
            {{ displayMsg }}
          </p>
          <div class="flex flex-col">
            <TCalendar
              v-model="reattemptDate"
              :min-date="minDateTomorrow"
              :max-date="maxReattemptDays"
              :unselectable-days-of-week="unselectableDaysOfWeek"
              :placeholder="datepickerPlaceholder"
              :disabled="!showDatePicker"
              @update:model-value="(v) => setReattemptDate(v)"
            />
          </div>
        </div>
        <TTextarea
          v-model="reattemptReason"
          :placeholder="reattemptReasonPlaceholder"
          name="reattempt-note"
        />
      </div>
    </div>
    <template #actions>
      <LegacyTButton
        type="blackAndWhite"
        value="Cancel"
        name="cancel"
        @click="close"
      />
      <LegacyTButton
        value="Confirm"
        name="confirm"
        :disabled="!confirmEnabled"
        @click="confirm"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { defineComponent, computed, PropType, ref } from 'vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import { maxDateWithWeekends } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { formatDateToCST } from '@/legacy/libs/format'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useReattemptStore } from '@/legacy/store/modules/reattempts'
import { EntityRole } from '@/legacy/types/entities/entities'
import {
  minDateTomorrow,
  unselectableDaysOfWeek,
  MAX_HIGH_PRIORITY_REATTEMPT_BUSINESS_DAYS,
  MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
  MAX_ENROLLMENT_REATTEMPT_DAYS,
} from '@/legacy/types/global/dates'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Subtask,
  SubtaskPriority,
  SubtaskRowDisplayType,
} from '@/legacy/types/pathways/subtasks'
import {
  datepickerPlaceholder,
  reattemptReasonPlaceholder,
  subtaskPriorityOptionsV2,
} from './lib/subtask'

export default defineComponent({
  components: {
    TTextarea,
    SubtaskRow,
    LegacyTButton,
    TModal,
    TCalendar,
  },
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'callbackFn'],
  setup(props, context) {
    const { canExtendReattempt } = storeToRefs(useProfileStore())

    /**
     * Function to close modal
     */
    function close() {
      context.emit('close')
      reattemptReason.value = ''
      reattemptDate.value = null
    }

    const reattemptReason = ref('')
    const reattemptDate = ref<Date | null>(null)

    const setReattemptDate = (selectedDate: Date) => {
      if (!selectedDate) {
        return null
      }
      const dateTime = formatDateToCST(selectedDate) as string
      reattemptDate.value = new Date(dateTime)
    }

    const isHighPriority = computed(() => {
      const highPriorities = Object.keys(SubtaskPriority).filter(
        (key) => key === 'HIGH' || key === 'URGENT'
      )
      return highPriorities.includes(props.subtask.priority)
    })

    const displayMsg = computed(() => {
      if (canExtendReattempt.value) {
        return 'Choose a reattempt date for the subtask above. Please note, you can only select a date that falls within the next 90 days.'
      }
      if (isHighPriority.value) {
        return 'Choose a reattempt date for the subtask above. Please note, you can only select a date that falls within the next 10 business days.'
      } else {
        return 'Choose a reattempt date for the subtask above. Please note, you can only select a date that falls within the next 60 days.'
      }
    })

    /**
     * Disable confirm until user has filled out a reattempt reason,
     * unless an Enrollment Specialist is responsible for the subtask
     */
    const confirmEnabled = computed(() => {
      if (
        props.subtask.responsibleRole ===
        EntityRole.CLINICAL__ENROLLMENT_SPECIALIST
      ) {
        return true
      } else {
        return !!reattemptReason.value
      }
    })

    /**
     * Only show the date picker if the subtask doesn't have the
     * disableDueDate flag set
     */
    const showDatePicker = computed(() => !props.subtask.disableDueDate)

    /**
     * Return the selected date from datepicker (or null if none selected)
     * unless the date picker is hidden, in which case return
     * tomorrow midnight in CT
     */
    const maybeSelectedOrDefaultReattemptDateTime = computed(() => {
      if (props.subtask.disableDueDate) {
        return new Date(formatDateToCST(minDateTomorrow) as string)
      }
      return reattemptDate.value ? reattemptDate.value : null
    })

    const maxReattemptDays = computed(() => {
      if (canExtendReattempt.value) {
        //max 90 business days including weekends
        return maxDateWithWeekends(
          minDateTomorrow,
          MAX_ENROLLMENT_REATTEMPT_DAYS,
          true
        )
      } else {
        if (isHighPriority.value) {
          //max 10 business days not including weekends
          return maxDateWithWeekends(
            minDateTomorrow,
            MAX_HIGH_PRIORITY_REATTEMPT_BUSINESS_DAYS,
            false
          )
        } else {
          //max 60 business days including weekends
          return maxDateWithWeekends(
            minDateTomorrow,
            MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
            true
          )
        }
      }
    })

    const _showErrorToast = (msg: string) => {
      useNotificationStore().setNotification({
        message: msg,
        type: NotificationType.DANGER,
      })
    }

    const ERR_MSG_NO_SUBTASK_MEMBER =
      'Could not reattempt, no member associated with this subtask'
    const ERR_MSG_NULL_DATETIME =
      'Could not reattempt, reattempt date is required to be set'
    const ERR_MSG_UNKNOWN_ERROR =
      'Could not reattempt, an unknown error occurred.' // maybe something better here?

    /**
     * Call TBS to create the reattempt when the user clicks `Confirm` in the modal
     */
    async function confirm() {
      if (
        props.subtask.memberId &&
        maybeSelectedOrDefaultReattemptDateTime.value
      ) {
        await useReattemptStore().createReattempt({
          reattemptReason: reattemptReason.value,
          reattemptDatetime: maybeSelectedOrDefaultReattemptDateTime.value,
          subtaskId: props.subtask.subtaskId,
          memberId: props.subtask.memberId,
        })

        thymeDispatch('thymeline-update')

        context.emit('callbackFn', [props.subtask.subtaskId])
        close()
      } else if (!props.subtask.memberId) {
        _showErrorToast(ERR_MSG_NO_SUBTASK_MEMBER)
      } else if (!maybeSelectedOrDefaultReattemptDateTime.value) {
        _showErrorToast(ERR_MSG_NULL_DATETIME)
      } else {
        _showErrorToast(ERR_MSG_UNKNOWN_ERROR)
      }
    }

    return {
      SubtaskRowDisplayType,
      reattemptDate,
      displayMsg,
      setReattemptDate,
      isHighPriority,
      subtaskPriorityOptionsV2,
      maxReattemptDays,
      reattemptReasonPlaceholder,
      datepickerPlaceholder,
      reattemptReason,
      unselectableDaysOfWeek,
      minDateTomorrow,
      showDatePicker,
      confirmEnabled,
      confirm,
      close,
      ModalSize,
      ButtonSize,
    }
  },
})
</script>
