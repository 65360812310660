<template>
  <div v-if="pathway">
    <div class="bg-nash-neutral000 p-5 h-full overflow-y-auto relative">
      <h2 class="flex items-center gap-2">
        <TIcon icon="pathway" color="nash-midnightBlue700" class="h-7 w-7" />
        <span v-if="isSuggestion">[Suggested]</span>{{ pathway.title }}
        {{ pathway.variantDisplay ? `(${pathway.variantDisplay})` : '' }}
      </h2>
      <LegacyTButton
        name="close-pathway-sidebar"
        icon="close"
        inline
        type="blackAndWhite"
        class="text-nash-neutral600 absolute top-4 right-2"
        :size="ButtonSize.MD"
        @click="closeSidebar"
      />

      <div><TMarkdown :source="pathway.description" /></div>
      <div v-if="!isSuggestion">
        <div class="relative h-12">
          <ProgressBar
            class="absolute inset-x-0 bottom-0"
            :completed="completedTasks"
            :total="pathway.taskIds?.length"
            :label="label"
            :status="status"
            :show-percentage="!isEvergreen"
            :is-full-width="true"
          />
        </div>
        <TMTertiaryButton
          label="+ Add Task"
          padding="sm"
          @click="showTaskModal = true"
        />
        <TaskAccordion
          v-for="task in sortedTasks"
          :key="task?.taskId"
          :task="task"
          @refetch-tasks="getTasksForPathway"
        />
        <TMTertiaryButton
          v-if="
            !isLoading &&
            pathway.taskIds &&
            pathwayTasks.length < pathway.taskIds?.length
          "
          :disabled="pathwayTasks.length >= pathway.taskIds?.length"
          label="Load more"
          class="my-3"
          padding="sm"
          @click="loadMore"
        />
        <div v-if="isLoading">
          <TMTertiaryButton label="Loading" class="my-3" />
        </div>
        <AddTaskModal
          :is-visible="showTaskModal"
          type="tasks"
          @close="showTaskModal = false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ProgressBar from '@/legacy/components/patient/pathways/ProgressBar.vue'
import { compareNullableDatetimes, stringToDateTime } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import {
  usePathwayApi,
  isEvergreenPathway,
} from '@/legacy/store/modules/pathways'
import { useTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'

import {
  PathwayStatus,
  taskStatusLabels,
  closedTaskStatuses,
  TaskStatus,
  suggestionPathwayStatuses,
} from '@/legacy/types/pathways/pathways'
import { MAX_NUM_TASKS, Task } from '@/legacy/types/pathways/tasks'

import AddTaskModal from './AddTaskModal.vue'
import TaskAccordion from './TaskAccordion.vue'

export default defineComponent({
  components: {
    TMTertiaryButton,
    TaskAccordion,
    TIcon,
    LegacyTButton,
    AddTaskModal,
    ProgressBar,
    TMarkdown,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const showTaskModal = ref(false)

    const { data: pathways } = storeToRefs(usePathwayApi())
    const { datum: updatedTask } = storeToRefs(useTaskApi())

    const { isLoading } = storeToRefs(useTaskApi())

    const pathwayTasks = ref<Task[]>([])
    const pageNumber = ref(1)
    const queryTotalTasksForPathway = ref(0)

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      const results = await getTasksForPathway(pageNumber.value)
      pathwayTasks.value = [...pathwayTasks.value, ...results.data]
      queryTotalTasksForPathway.value = results.queryMetadata.total
    }

    /**
     * Function to fetch tasks for pathway prop
     * @param pageNum
     */
    async function getTasksForPathway(pageNum = 1) {
      const taskIds = pathway.value?.taskIds
      if (taskIds?.length) {
        return await useTasksStore().getTasks({
          filter_member_ids: [route.params.patientId as string],
          filter_task_ids: taskIds,
          page_length: MAX_NUM_TASKS,
          page_number: pageNum,
        })
      } else {
        return { data: [], queryMetadata: { total: 0 } }
      }
    }

    const queryTaskId = computed(() => route.query.taskId)
    watch(queryTaskId, async () => {
      if (queryTaskId.value) {
        let taskMatch = pathwayTasks.value.find(
          (task) => task.taskId === queryTaskId.value
        )
        while (
          !taskMatch &&
          queryTotalTasksForPathway.value > pathwayTasks.value.length
        ) {
          await loadMore()
          taskMatch = pathwayTasks.value.find(
            (task) => task.taskId === queryTaskId.value
          )
        }
      }
    })

    onMounted(async () => {
      const results = await getTasksForPathway()
      pathwayTasks.value = [...pathwayTasks.value, ...results.data]
      queryTotalTasksForPathway.value = results.queryMetadata.total

      if (queryTaskId.value) {
        let taskMatch = pathwayTasks.value.find(
          (task) => task.taskId === queryTaskId.value
        )
        while (
          !taskMatch &&
          queryTotalTasksForPathway.value > pathwayTasks.value.length
        ) {
          await loadMore()
          taskMatch = pathwayTasks.value.find(
            (task) => task.taskId === queryTaskId.value
          )
        }
      }
    })

    const queryPathwayId = computed(() => route.query.pathwayId)
    const pathway = computed(() =>
      pathways.value ? pathways.value[`${queryPathwayId.value}`] : null
    )

    // Reset pathwayTasks data task updates
    watch(updatedTask, async () => {
      const results = await getTasksForPathway(pageNumber.value)
      pathwayTasks.value = uniqBy(
        [...results.data, ...pathwayTasks.value],
        'taskId'
      )
      queryTotalTasksForPathway.value = results.queryMetadata.total
    })

    // Reset pathwayTasks data on create
    watch(pathway, async () => {
      const switchingPathway = !(
        pathwayTasks.value[0]?.pathwayIds[0] === `${queryPathwayId.value}`
      )
      const results = await getTasksForPathway()
      if (switchingPathway) {
        pathwayTasks.value = [...results.data]
        pageNumber.value = 1
      } else {
        pathwayTasks.value = uniqBy(
          [...results.data, ...pathwayTasks.value],
          'taskId'
        )
      }
      queryTotalTasksForPathway.value = results.queryMetadata.total
    })

    const isEvergreen = computed(() =>
      isEvergreenPathway(pathway.value?.title ?? '')
    )

    const isSuggestion = computed(() =>
      suggestionPathwayStatuses.includes(pathway.value?.status as PathwayStatus)
    )

    const status = computed(() => {
      if (pathway.value && isEvergreen.value) {
        return 'lock'
      }
      if (
        pathway.value?.status === PathwayStatus.ON_HOLD ||
        pathway.value?.status === PathwayStatus.CANCELLED
      ) {
        return 'hold'
      }
      if (pathway.value?.status === PathwayStatus.ACTIVE) {
        return 'go'
      }
      if (pathway.value?.status === PathwayStatus.COMPLETED) {
        return 'completed'
      }
      return ''
    })

    const completedTasks = computed(
      () =>
        filter(
          pathwayTasks.value,
          (pathwayTask) => pathwayTask?.status === TaskStatus.COMPLETED
        ).length
    )

    /**
     * 3 tier sort
     * 1 - task status index
     * 2 - due datetime
     * 3 - status updated at
     */
    const sortedTasks = computed(() => {
      /**
       *
       * @param t
       */
      function isClosedTask(t: Task) {
        const status = lookupEnum(TaskStatus, t.status)
        return closedTaskStatuses.indexOf(status) >= 0
      }
      const sortedTasks = [...(pathwayTasks.value as Task[])].sort(
        (a: Task, b: Task) => {
          const statusSort =
            taskStatusLabels.findIndex(({ value }) => a.status === value) -
            taskStatusLabels.findIndex(({ value }) => b.status === value)
          if (statusSort) {
            return statusSort
          }
          if (!isClosedTask(a)) {
            const dueDatetimeSort = compareNullableDatetimes(
              stringToDateTime(a.dueDatetime),
              stringToDateTime(b.dueDatetime)
            )
            if (dueDatetimeSort) {
              return dueDatetimeSort
            }
            return 0
          }
          return compareNullableDatetimes(
            stringToDateTime(b.statusUpdatedAt),
            stringToDateTime(a.statusUpdatedAt)
          )
        }
      )
      return sortedTasks
    })

    const closeSidebar = () => {
      void router.push({ query: { pathwayId: undefined } })
    }

    const label = 'Tasks'

    return {
      ButtonSize,
      getTasksForPathway,
      MAX_NUM_TASKS,
      isLoading,
      loadMore,
      isEvergreen,
      status,
      label,
      pathway,
      showTaskModal,
      closeSidebar,
      completedTasks,
      sortedTasks,
      pathwayTasks,
      isSuggestion,
    }
  },
})
</script>
