import { requiredInject } from '@thyme/libs/src/vue/inject'
import { computed, ref, Ref } from 'vue'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

// Defined only for convenience and use in testing.
export type FallRiskViewModel = {
  fallInTheLastTwelveMonthsDisplay: Ref<string>
  unsteadyWithStandingOrWalkingDisplay: Ref<string>
  fearOfFallingDisplay: Ref<string>
  usesDMEToAssistWithAmbulationDisplay: Ref<string>
  peripheralNeuropathyDisplay: Ref<string>
  anticoagulationDisplay: Ref<string>
  sedatingMedicationsDisplay: Ref<string>
  additionalInformationDisplay: Ref<string>
  showEditModal: Ref<boolean>
  toggleFallRiskEdit: () => void
  patientId: Ref<string>
}

/**
 *
 *
 */
export function setup(): FallRiskViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)

  const fallInTheLastTwelveMonthsDisplay = computed(() => {
    return '-'
  })

  const unsteadyWithStandingOrWalkingDisplay = computed(() => {
    return '-'
  })

  const fearOfFallingDisplay = computed(() => {
    return '-'
  })

  const usesDMEToAssistWithAmbulationDisplay = computed(() => {
    return '-'
  })

  const peripheralNeuropathyDisplay = computed(() => {
    return '-'
  })

  const anticoagulationDisplay = computed(() => {
    return '-'
  })

  const sedatingMedicationsDisplay = computed(() => {
    return '-'
  })

  const additionalInformationDisplay = computed(() => {
    return '-'
  })

  /**
   * open/close edit modal
   */
  function toggleFallRiskEdit() {
    showEditModal.value = !showEditModal.value
  }

  return {
    patientId,
    // display values
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
    // fall risk edit modal
    toggleFallRiskEdit,
    showEditModal,
  }
}
