import { defineStore, storeToRefs } from 'pinia'
import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePathwaysStore } from '@/legacy/store/modules/pathways'

import { usePatientStore } from '@/legacy/store/modules/patient'
import { DataType } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import { Outcome, OutcomePayload } from '@/legacy/types/outcomes'
import { TemplateStatus } from '@/legacy/types/pathways/pathways'
import {
  TaskIds,
  ApiTask,
  MapTaskSubtask,
  Task,
  CreateTaskInfo,
  TaskPayload,
  TaskState,
  TaskTemplate,
  TaskTemplateDataState,
  MAX_NUM_TASKS,
  GetTasksParams,
  CreateTasksAndLinkCommunicationResponse,
  CreateTasksAndLinkCommunicationRequest,
} from '@/legacy/types/pathways/tasks'
import { useSubtaskApi } from './subtasks'
// CONST
const TASK_TEMPLATES_PAGE_LENGTH = 10

const transformTasks = (data: ApiTask[]): Partial<TaskState> => {
  let convertedData = data
  if (data?.length) {
    convertedData = data.map((task: ApiTask) => ({
      ...task,
      subtaskIds: task.subtaskMaps?.map(
        (subtaskMap: MapTaskSubtask) => subtaskMap.subtaskId
      ),
    }))
  }

  const taskApi = useTaskApi()
  const currentTasksMapping = taskApi.data ?? {}
  const newDataMap = toIdMap(convertedData, 'taskId')
  const newData = Object.assign({}, currentTasksMapping, newDataMap)

  return {
    ...idMapTransform({}, 'data', 'taskId', Object.values(newData)),
  }
}

const transformGoalTasks = (data: ApiTask[]): Partial<TaskState> => {
  let convertedData = data
  if (data?.length) {
    convertedData = data.map((task: ApiTask) => ({
      ...task,
      subtaskIds: task.subtaskMaps?.map(
        (subtaskMap: MapTaskSubtask) => subtaskMap.subtaskId
      ),
    }))
  }

  const newDataMap = toIdMap(convertedData, 'taskId')
  const newData = Object.assign({}, newDataMap)

  return {
    ...idMapTransform({}, 'data', 'taskId', Object.values(newData)),
  }
}

/**
 *
 * @param data
 * @param total
 */
function transformTaskTemplates(
  data: TaskTemplate[],
  total: number
): Partial<TaskTemplateDataState> {
  const taskTemplateApi = useTaskTemplateApi()
  const currentTemplatesMapping = taskTemplateApi.data ?? {}
  const newDataMap = toIdMap(data, 'variantId')
  const newData = Object.assign({}, currentTemplatesMapping, newDataMap)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'variantId', Object.values(newData)),
  }
}

export const useTasksStore = defineStore('tasks', {
  // cloneDeep due to object ref issue: https://github.com/vuejs/pinia/issues/484
  state: () => ({}),
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} task(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} pathway: ${error}`,
      })
    },

    async setSuccess(action: string, taskIds: string[] | null = null) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        await Promise.all([
          usePathwaysStore().getAllPathways(patientId.value),
          this.getTasks({
            filter_member_ids: [patientId.value],
            ...(taskIds ? { filter_task_ids: taskIds } : {}),
          }),
        ])
        useNotificationStore().setNotification({
          message: `Success ${action} task(s)`,
          type: NotificationType.SUCCESS,
        })
      }
    },
    async getTasks({
      filter_member_ids = null,
      filter_task_ids = null,
      page_length = MAX_NUM_TASKS,
      page_number = 1,
      filter_free_text = '',
    }: GetTasksParams) {
      const taskApi = useTaskApi()
      const results = await taskApi.list({
        params: {
          ...(filter_task_ids ? { filter_task_ids } : {}),
          ...(filter_member_ids ? { filter_member_ids } : {}),
          ...(page_length ? { page_length } : {}),
          ...(page_number ? { page_number } : {}),
          ...(filter_free_text ? { filter_free_text } : {}),
          sort_by: [
            'status,asc',
            'isCompleted,asc',
            'dueDatetime,asc',
            'statusUpdatedAt,desc',
            'title,asc',
          ],
          parts: ['subtask_maps'],
        },
      })
      if (taskApi.error) {
        this.setError(taskApi.error, 'fetching')
      }
      return results
    },
    async updateTaskOutcomes(payload: OutcomePayload) {
      const taskApi = useTaskApi()
      await taskApi.update({
        ids: TaskIds.Outcomes,
        body: payload,
      })
      if (taskApi.error) {
        this.setError(taskApi.error, 'updating outcomes for')
      }
    },

    async updateTask(taskId: string, payload: Partial<TaskPayload>) {
      const data = await useTaskApi().partialUpdate({
        body: payload,
        ids: taskId,
      })

      const error = useTaskApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE)
        return
      }

      if (data) {
        await this.setSuccess(NotificationActions.UPDATE, [taskId])
      }
    },
    async createTasks(
      pathwayId: string,
      taskTemplateCreatePayload: Array<CreateTaskInfo>
    ) {
      const data: Task[] = []
      const taskApi = useTaskApi()
      useSubtaskApi().updateData(null, DataType.SINGULAR)
      for (let i = 0; i < taskTemplateCreatePayload.length; i++) {
        const res = await taskApi.create({
          body: {
            pathwayId,
            taskVariantId: taskTemplateCreatePayload[i].variantId,
            dueDatetime: taskTemplateCreatePayload[i].dueDatetime,
            priority: taskTemplateCreatePayload[i].priority,
            ...(taskTemplateCreatePayload[i].customSubject
              ? { customSubject: taskTemplateCreatePayload[i].customSubject }
              : {}),
          },
        })
        if (res) {
          data.push(res)
        }
        if (taskApi.error) {
          this.setError(taskApi.error, NotificationActions.CREATE)
          return
        }
      }
      if (data.length) {
        const taskIds = data.map((task) => task.taskId)
        await this.setSuccess(NotificationActions.CREATE, taskIds)
        return data
      }
    },
    async getAllTaskTemplates({
      filter_free_text = null,
      page_length = TASK_TEMPLATES_PAGE_LENGTH,
      page_number = 1,
      filter_variant_ids = null,
    }: {
      filter_free_text?: string | null
      filter_variant_ids?: string[] | null
      page_length?: number
      page_number?: number
    }) {
      const taskTemplateApi = useTaskTemplateApi()
      const data = await taskTemplateApi.list({
        params: {
          ...(filter_free_text ? { filter_free_text } : {}),
          ...(filter_variant_ids ? { filter_variant_ids } : {}),
          page_length,
          page_number,
          filter_template_statuses: TemplateStatus.PUBLISHED,
          parts: ['possible_outcomes', 'subtask_templates'],
        },
      })
      if (taskTemplateApi.error) {
        this.setError(taskTemplateApi.error, 'fetching task template')
      }

      return data
    },
    async getTaskTemplates(params: { [key: string]: any }) {
      const taskTemplateApi = useTaskTemplateApi()
      const parts = ['possible_outcomes', 'subtask_template_maps']

      await taskTemplateApi.list({
        params: {
          filter_template_statuses: TemplateStatus.PUBLISHED,
          parts: parts,
          sort_by: ['title,asc'],
          ...params,
        },
      })
      if (taskTemplateApi.error) {
        this.setError(taskTemplateApi.error, 'fetching task templates')
      }
    },
    async getEvergreenTasks(patientIds: string[]) {
      await useGoalTaskApi().listAll({
        params: {
          filter_member_ids: patientIds,
          filter_is_evergreen_task: true,
          sort_by: ['title,asc'],
          parts: ['subtask_maps'],
        },
      })
    },
  },
})

export const useTaskApi = apiStore<ApiTask, IdMap<Task>>(
  'taskApi',
  '/api/tasks',
  {
    transformData: (d: { data: ApiTask[] }) => transformTasks(d.data),
  }
)

export const useGoalTaskApi = apiStore<ApiTask, IdMap<Task>>(
  'goalTaskApi',
  '/api/tasks',
  {
    transformData: (d: { data: ApiTask[] }) => transformGoalTasks(d.data),
  }
)

export const useTaskOutcomesApi = apiStore<Outcome>(
  'taskOutcomesApi',
  '/api/tasks/outcomes',
  {
    transformData: (d: { data: Outcome[] }) => d,
    params: {
      parts: ['outcome_validation'],
    },
  }
)

export const useTaskTemplateApi = apiStore<TaskTemplate, IdMap<TaskTemplate>>(
  'taskTemplateApi',
  '/api/task_templates',
  {
    transformData: (d: {
      data: TaskTemplate[]
      queryMetadata: { total: number }
    }) => transformTaskTemplates(d.data, d.queryMetadata.total),
  }
)

export const useCreateTasksAndLinkCommunicationApi = apiStore<
  CreateTasksAndLinkCommunicationRequest,
  CreateTasksAndLinkCommunicationResponse
>(
  'createTasksAndLinkCommunicationsApi',
  '/api/tasks/create_and_link_communication'
)
