<template>
  <TMChipButton
    v-tooltip="`calling from ${getPhoneNumber}`"
    :name="`${name}-phonenumber`"
    icon="heroicons:phone-arrow-up-right"
    icon-fill="nash-purple500"
    @click="call"
  />
</template>

<script lang="ts">
import TMChipButton from '@nashville/button/TMChipButton.vue'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { dial } from '@/legacy/libs/call'
import { formatPhone } from '@/legacy/libs/format'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useOutboundPhoneNumberApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { AddressType } from '@/legacy/types/entities/addresses'

// ENUMS --------------------
export enum OutboundEntityType {
  PATIENT = 'patient',
}

export default defineComponent({
  components: { TMChipButton },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<OutboundEntityType>,
      default: OutboundEntityType.PATIENT,
    },
  },
  emits: ['call'],
  setup(props, context) {
    const {
      datum: outboundNumber,
      isLoaded,
      isLoading,
    } = storeToRefs(useOutboundPhoneNumberApi())
    const route = useRoute()
    const { useTcopGeorouting } = storeToRefs(useFlagStore())

    /**
     * Get filters for outbound phone number api call
     * For patient entity type
     */
    function getPatientInformationForOutbound() {
      const { addresses, entity, patient } = storeToRefs(usePatientStore())
      const entityId = entity.value?.entityId
      let state = null
      let zipCode = null
      if (addresses.value && Object.values(addresses.value).length) {
        // Only allow addresses labeled as "Home" to be valid for outbound phone number consideration
        const homeAddresses = Object.values(addresses.value).filter(
          ({ type }) =>
            type === AddressType.Home || type === AddressType.HomeAndMailing
        )
        if (homeAddresses.length) {
          state = homeAddresses[0].state
          zipCode = homeAddresses[0].zip
        }
      }
      let alignedPractice = null
      if (useTcopGeorouting.value) {
        alignedPractice = patient.value?.alignedPractice
      }
      return {
        entity_id: entityId,
        ...(state ? { state } : {}),
        ...(zipCode ? { zip_code: zipCode } : {}),
        ...(alignedPractice ? { aligned_practice: alignedPractice } : {}),
      }
    }

    /**
     * Function to retrieve outbound phone number from api if not already called
     */
    async function getOutboundPhoneNumber() {
      let params
      if (props.type === OutboundEntityType.PATIENT) {
        params = getPatientInformationForOutbound()
      } else {
        throw new Error(`Entity Type "${props.type}" not allowed for call`)
      }
      await useOutboundPhoneNumberApi().retrieve({
        ids: ['lookup'],
        params,
      })
    }

    /**
     * Check if the phone number has already been loaded for this entity
     */
    function phoneLoadedForEntity() {
      if (props.type === OutboundEntityType.PATIENT) {
        const { entity } = storeToRefs(usePatientStore())
        const entityId = entity.value?.entityId
        return isLoaded.value && entityId === route.params.patientId
      }
      return false
    }

    /**
     * dial phone number including outbound caller id
     */
    async function call() {
      if (!props.phoneNumber) {
        console.error('phoneNumber not loaded yet, cannot dial')
        return
      }
      if (!phoneLoadedForEntity()) {
        await getOutboundPhoneNumber()
      }
      await dial({
        phoneNumber: props.phoneNumber,
        outboundNumber: outboundNumber.value?.phoneNumber,
      })
      context.emit('call')
    }

    const getPhoneNumber = computed(() => {
      if (!isLoading.value && !phoneLoadedForEntity()) {
        void getOutboundPhoneNumber()
      }
      if (isLoading.value) {
        return '...loading'
      }
      return (
        formatPhone(outboundNumber.value?.phoneNumber?.phoneNumber ?? '') ||
        'zoom default'
      )
    })

    return {
      call,
      getPhoneNumber,
    }
  },
})
</script>
