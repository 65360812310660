<template>
  <div class="overflow-visible card-style pt-4 w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <div class="grid-item">
            <label>Treatment</label>
          </div>
          <div class="grid-item">
            {{ treatmentDisplay }}
          </div>
          <div class="grid-item max-w-44">
            <label>Surgery</label>
          </div>
          <div class="grid-item">
            {{ surgeryDisplay }}
          </div>
          <div class="grid-item">
            <label>Radiation</label>
          </div>
          <div class="grid-item">
            {{ radiationDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
