import { object, string, InferType } from 'yup'

export const schema = object({
  ECOGStatus: string().nullable(),
  unableToPerformTheFollowingADLs: string().nullable(),
  DME: string().nullable(),
  walksTwoBlocksOrOneFlightOfStairs: string().nullable(),
  unableToPerformTheFollowingIADLs: string().nullable(),
  homebound: string().nullable(),
  proceduralSupport: string().nullable(),
  additionalInformation: string().nullable(),
})

export type EditPhysicalFunctionAssessmentForm = InferType<typeof schema>

export const editPhysicalFunctionAssessmentProps = {
  patientId: {
    type: String,
    required: true,
  },
} as const
