<template>
  <div class="py-12 bg-nash-neutral000">
    <TTable
      ref="table"
      data-key="stackId"
      title="Unassigned | Enrollment"
      name="UnassignedEnrollmentQueue"
      :rows="10"
      :columns="columns"
      :params="params"
      :rows-per-page="[10, 25]"
      :api-store="useUnassignedEnrollmentQueueApiWithScheduledCalls"
      :show-header-container="false"
      :filters="filters"
      :free-text-filter="true"
      :free-text-options="freeTextOptions"
      free-text-filter-name="freeTextName"
      free-text-placeholder="Search..."
      :selected-items="selectedSubtaskPatients"
      @data-loaded="getSubtaskAndCommDetailsForPatientStack"
      @update-selected-items="updateSelectedItems"
    >
      <template #filterOptions>
        <TDropdown
          :options="freeTextOptions"
          initial-value="freeTextName"
          :show-clear="false"
          class="bg-nash-neutral000"
          option-label="label"
          option-value="value"
        />
      </template>
      <template #startColumns>
        <Column
          v-if="hasBulkEdit"
          selection-mode="multiple"
          header-style="width: 1%"
        ></Column>
      </template>
      <template #multiSelectActions>
        <TOTableButton
          v-if="hasBulkEdit && selectedSubtaskPatients.length"
          name="pod-lead-queue-unassigned-table-multi-select"
          icon="heroicons:square-2-stack"
          size="sm"
          label="Bulk Edit"
          @click="toggleBulkEditModal"
        />
      </template>
      <template #column-patient="slotProps">
        <router-link
          :to="slotProps.column.linkFn(slotProps.column, slotProps.row)"
          data-cy="patients-table-name"
        >
          {{ slotProps.column.display(slotProps.row.patient) }}
        </router-link>
      </template>
      <template #column-preferredContactTime="slotProps">
        <div v-if="slotProps.row?.communicationId">
          <a :href="buildCommunicationUrl(slotProps.row)">{{
            slotProps.column.display(null, slotProps.row)
          }}</a>
        </div>
        <div v-else>
          {{ slotProps.column.display(null, slotProps.row) }}
        </div>
      </template>
      <template #column-subtasks="slotProps">
        <div class="space-y-4">
          <div
            v-if="
              !slotProps.column.displayData(slotProps.row) &&
              slotProps.row?.communicationId
            "
          >
            <i>No subtasks associated with this scheduled call.</i>
          </div>
          <div
            v-for="subtask in slotProps.column.displayData(slotProps.row)"
            :key="subtask.subtaskId"
          >
            <SubtaskRow
              class="w-full"
              :disable-status-dropdown="true"
              :is-overdue="isSubtaskOverdue(subtask)"
              :subtask="subtask"
              :display-type="SubtaskRowDisplayType.ENROLLMENT"
              @callback-fn="onSubtaskStatusChanged"
            />
          </div>
        </div>
      </template>
      <template #column-assign="slotProps">
        <div>
          <TMSecondaryButton
            label="Assign All"
            @click="
              () => assignToMe(slotProps.column.displayData(slotProps.row))
            "
          />
        </div>
      </template>
    </TTable>
    <BulkEditModal
      v-if="selectedSubtaskPatients.length"
      :is-open="showBulkEditModal"
      :selected-subtasks="selectedSubtasks"
      :disable-reattempt="true"
      :disable-priority="true"
      @close="toggleBulkEditModal"
      @callback-fn="getData"
      @clear-selected-subtasks="resetSelectedSubtasks"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import Column from 'primevue/column'
import { defineComponent } from 'vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import BulkEditModal from '@/legacy/components/reminders/modals/BulkEditModal.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TOTableButton from '@/legacy/nashville/table/TOTableButton.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'

import setup from './controller'

export default defineComponent({
  components: {
    BulkEditModal,
    TOTableButton,
    Column,
    TDropdown,
    TMSecondaryButton,
    SubtaskRow,
    TTable,
  },
  props: {
    hasBulkEdit: {
      type: Boolean,
      default: false,
    },
    isEnrollmentLeadQueue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['assign', 'subtaskStatusChanged'],
  setup,
})
</script>

<style lang="scss" scoped>
.chip {
  @apply flex gap-1 px-2 mx-2 rounded-full border border-solid cursor-default;
}

.language-chip {
  @apply border-nash-purple200;
}
</style>
